import React from "react";
import ContentLoader from "react-content-loader";

const DesktopMenuLoaders = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={70}
    backgroundColor="#0000"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="118" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="236" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="351" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="472" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="590" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="708" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="826" y="35" rx="3" ry="3" width="88" height="15" />
    <rect x="944" y="35" rx="3" ry="3" width="88" height="15" />
  </ContentLoader>
);

export default DesktopMenuLoaders;
