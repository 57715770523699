import React from "react";
import { useQuery } from "react-apollo";
import ReactPlayer from "react-player";
import { Spinner } from "reactstrap";

import { Container } from "../../components/Container/Container";
import { useRouter } from "../../lib/hooks/useRouter";
import { getCourseById } from "../../queries/home";
import { CoursesResponse } from "../HowTo/HowTo";

import "./HowToSingle.scss";

interface Course {
  course: CoursesResponse;
}

export const HowToSingle = () => {
  const { query } = useRouter();

  const { data, loading, error } = useQuery<Course, any>(getCourseById, {
    variables: {
      id: query.helpId,
    },
  });

  const course = data?.course;

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <Spinner color="#000" size="lg" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <span>Diçka shkoj keq, ju lutemi provoni përsëri!</span>
      </div>
    );
  }

  return (
    <div className="HowToSingle">
      <Container>
        <div className="HowToSingle__wrapper">
          <div className="HowToSingle__wrapper_video">
            {course?.courseVideo?.video ? (
              <ReactPlayer
                width="100%"
                height={455}
                stopOnUnmount
                // loop
                // playing
                url={course?.courseVideo?.video}
                volume={0}
                muted
                playsinline
              />
            ) : (
              <img alt="" src={course?.featuredImage?.node?.mediaItemUrl} />
            )}
          </div>
          <div className="HowToSingle__wrapper__content">
            <h1>{course?.title}</h1>
            <div className="HowToSingle__wrapper__content_card">
              <h3>Pëshkrimi</h3>
              <p>{course?.content}</p>
              <ul>
                <li>Përshkrimi njē</li>
                <li>Përshkrimi dy</li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
