import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import cs from "classnames";
import { useQuery } from "react-apollo";
import { ListOfProducts } from "../../components/ListOfProducts/ListOfProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { Layout } from "../../components/Shop/ui/Layout";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { ShopSidebar } from "../../components/Shop/ShopSidebar/ShopSidebar";
import { ShopContextProvider } from "../../lib/context/ShopContext/ShopContextProvider";
import { useOrderFieldsFromSlug } from "../../lib/hooks/useOrderFieldsFromSlug";
import { useInitialSearchParams } from "../../lib/hooks/useInitialSearchParams";
import * as shopQueries from "../../queries/shop";
import * as productsQueries from "../../queries/products";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { Container } from "../../components/Container/Container";
import { Button } from "../../components/shared/Button/Button";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import { ShopBySubCategories } from "../../components/ShopBySubCategories/ShopBySubCategories";
import { ShopByCategories } from "../../components/ShopByCategories/ShopByCategories";
import { ShopBySubSubCategories } from "../../components/ShopBySubSubCategories/ShopBySubSubCategories";
import { ShopBySubSubSubCategories } from "../../components/ShopBySubSubSubCategories/ShopBySubSubSubCategories";
import { useShopFilterData } from "../../lib/hooks/useShopFilterData";
import { Spinner } from "reactstrap";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import "./ShopByCategory.scss";

const postsLimit = 15;
const defaultField = "DATE";
const defaultOrder = "ASC";

export interface Props {
  parentCategory?: string;
  childCategory?: string;
  childChildCategory?: string;
  childChildChildCategory?: string;
}

export const ShopByCategory = (props: RouteComponentProps<Props>) => {
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const menuSidebar = useMenuSidebar();
  const windowWidth = useWindowWidth();
  const shopFilterData = useShopFilterData();
  const isMobile = windowWidth <= 1024;
  const orderFields = useOrderFieldsFromSlug();
  const initialSearchParams = useInitialSearchParams();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [, /*isLoadingPrevious*/ setIsLoadingPrevious] = useState(false);
  const [priceState, setPriceState] = useState({ min: 0, max: 1500 });
  const { data: dataNewProducts } = useQuery(shopQueries.NEW_PRODUCTS);

  const {
    childCategory,
    parentCategory,
    childChildCategory,
    childChildChildCategory,
  } = props.match.params;

  // const getProducts = localStorage.getItem("PROFITISHOP_USER_FAV_PRODUCT");
  const getMostWatchedCategories = localStorage.getItem(
    "PROFITISHOP_USER_FAV_CATEGORY"
  );

  // const getProductsJSON = getProducts ? JSON.parse(getProducts) : [];
  const getMostWatchedCategoriesJSON = getMostWatchedCategories
    ? JSON.parse(getMostWatchedCategories)
    : [];

  const sortedCategories = getMostWatchedCategoriesJSON.sort(
    (a: { count: number }, b: { count: number }) => b.count - a.count
  );

  const highestCountProducts = sortedCategories.filter(
    (item: { count: number }) => item.count === sortedCategories[0].count
  );

  // const sortedProducts = getProductsJSON.sort(
  //   (a: { count: number }, b: { count: number }) => b.count - a.count
  // );

  // const highestCountProducts = sortedProducts.filter(
  //   (item: { count: number }) => item.count === sortedProducts[0].count
  // );

  // const productIds = highestCountProducts.map(
  //   (item: { productId: number }) => item.productId
  // );

  const hasOnSale = !!searchParams["oferta"];
  const hasMosWatched = !!searchParams["meteshikuarat"];

  const brand = searchParams["brand"];

  const date = dataNewProducts?.themeOptions?.themeOptions?.newProducts?.split(
    "/"
  );

  const { loading, data, error, fetchMore, refetch } = useQuery<
    productsQueries.GetProductsResponse,
    productsQueries.GetProductsVariables
  >(productsQueries.getProducts, {
    fetchPolicy: "cache-first",
    variables: {
      before: "",
      after:
        initialSearchParams && initialSearchParams.page
          ? (initialSearchParams.page as string)
          : "",
      limit: postsLimit,
      category:
        highestCountProducts[0] && hasMosWatched
          ? highestCountProducts[0]?.categorySlug
          : childChildChildCategory ||
            childChildCategory ||
            childCategory ||
            parentCategory ||
            "",
      field: orderFields.field,
      order: orderFields.order,
      taxonomyFilter: shopHandlers.prepareTaxonomyFilters(searchParams),
      onSale: hasOnSale,
      ids: [],
      day: date ? parseInt(date[0]) : undefined,
      month: date ? parseInt(date[1]) : undefined,
      year: date ? parseInt(date[2]) : undefined,
      brand: brand as string,
    },
  });

  const increaseCountOfCategory = (categorySlug: string) => {
    if (!categorySlug) {
      return;
    }

    const currentCategory = localStorage.getItem(
      "PROFITISHOP_USER_FAV_CATEGORY"
    );

    const currentCategoryJSON = currentCategory
      ? JSON.parse(currentCategory)
      : null;

    const currentCategoryJSONFilteredIndex = currentCategoryJSON?.findIndex(
      (item: { categorySlug: string; count: number }) => {
        return item.categorySlug === categorySlug;
      }
    );

    if (!currentCategoryJSON) {
      const newCategory = {
        categorySlug,
        count: 1,
      };

      // Add the new product to the array and update the array in local storage
      localStorage.setItem(
        "PROFITISHOP_USER_FAV_CATEGORY",
        JSON.stringify([newCategory])
      );
      return;
    }

    if (currentCategoryJSONFilteredIndex !== -1) {
      // Product already exists in the array
      const currentCategoryJSONFiltered =
        currentCategoryJSON[currentCategoryJSONFilteredIndex];
      currentCategoryJSONFiltered.count += 1;

      // Update the array in local storage
      localStorage.setItem(
        "PROFITISHOP_USER_FAV_CATEGORY",
        JSON.stringify(currentCategoryJSON)
      );
    } else {
      // Product does not exist in the array
      const newCategory = {
        productId: categorySlug,
        count: 1,
      };

      // Add the new product to the array and update the array in local storage
      localStorage.setItem(
        "PROFITISHOP_USER_FAV_CATEGORY",
        JSON.stringify([...currentCategoryJSON, newCategory])
      );
    }
  };

  useEffect(() => {
    if (!parentCategory) {
      return;
    }

    increaseCountOfCategory(parentCategory);
    // eslint-disable-next-line
  }, [data?.products?.nodes, parentCategory]);

  const handlePriceChange = (value: number[]) => {
    setPriceState({
      min: value[0],
      max: value[1],
    });
  };

  const handlePriceAfterChange = (value: number[]) => {
    refetch({
      limit: postsLimit,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.products?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        // if (pageInfo.endCursor) {
        //   shopHandlers.updateSingleQueryParameter(
        //     "page",
        //     previousResult?.products?.pageInfo?.endCursor,
        //     () => {}
        //   );
        // }

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              endCursor: pageInfo?.endCursor,
              hasNextPage: pageInfo?.hasNextPage,
            },
          },
        };
      },
    });
  };

  const handleLoadPrevious = () => {
    setIsLoadingPrevious(true);
    fetchMore({
      variables: {
        last: postsLimit,
        first: undefined,
        limit: undefined,
        after: undefined,
        before: data?.products?.pageInfo?.startCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        setIsLoadingPrevious(false);
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...newNodes, ...previousResult.products.nodes],
            pageInfo: {
              ...previousResult?.products?.pageInfo,
              startCursor: pageInfo?.startCursor,
              hasPreviousPage: pageInfo?.hasPreviousPage,
            },
          },
        };
      },
    });
  };

  const handleOrderChange = (value: string) => {
    shopHandlers.updateSingleQueryParameter("order", value, (field, order) => {
      refetch({
        limit: postsLimit,
        field: field || defaultField,
        order: order || defaultOrder,
      });
    });
  };

  const handleCategoryChange = (categorySlug: string) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);

    refetch({
      limit: postsLimit,
      category: categorySlug,
      taxonomyFilter: taxonomyFilters,
    });
  };

  const handleAttributeChange = (data: any) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);

    refetch({
      limit: postsLimit,
      taxonomyFilter: taxonomyFilters,
    });
  };

  const products = data?.products?.nodes;
  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const hasPreviousPage = !!data?.products?.pageInfo?.hasPreviousPage;
  const noProductsAvailable = !products || Number(products.length) === 0;

  const path = [
    { name: parentCategory?.replaceAll("-", " "), slug: parentCategory },
    {
      name: childCategory?.replaceAll("-", " "),
      slug: `${parentCategory}/${childCategory}`,
    },
    {
      name: childChildCategory?.replaceAll("-", " "),
      slug: `${parentCategory}/${childCategory}/${childChildCategory}`,
    },
    {
      name: childChildChildCategory?.replaceAll("-", " "),
      slug: `${parentCategory}/${childCategory}/${childChildCategory}/${childChildChildCategory}`,
    },
  ];

  return (
    <ShopContextProvider
      data={{
        data: {
          shopType: "category",
          shopLinkPrefix: "shop",
          category: parentCategory,
          subCategory: childCategory,
          subSubCategory: childChildCategory,
          subSubSubCategory: childChildChildCategory,
          order: `${orderFields.field}-${orderFields.order}`,
        },
        handlers: {
          handleCategoryChange,
          handleAttributeChange,
          handleOrderChange,
        },
      }}
    >
      {loading ? (
        <div className="ShopByCategory__banner-loading">
          <Spinner size="lg" color="#000" />
        </div>
      ) : (
        <>
          {(parentCategory || childCategory) &&
          !childChildCategory &&
          !isMobile ? (
            <div className="ShopByCategory__banner">
              <img
                src={shopFilterData?.categories[0]?.image?.mediaItemUrl}
                alt="img"
              />
            </div>
          ) : null}
          <BreadCrumb path={path} shopType="shop" />
          {!noProductsAvailable && (
            <div>
              <Container>
                <div className="ShopByCategory__top">
                  <span>
                    {childChildChildCategory
                      ? childChildChildCategory.replaceAll("-", " ")
                      : childChildCategory
                      ? childChildCategory.replaceAll("-", " ")
                      : childCategory
                      ? childCategory.replaceAll("-", " ")
                      : parentCategory?.replaceAll("-", " ")}
                  </span>
                  <div className="ShopByCategory__top__subcategories">
                    {!parentCategory ? (
                      <ShopByCategories />
                    ) : parentCategory && !childCategory ? (
                      <ShopBySubCategories />
                    ) : parentCategory &&
                      childCategory &&
                      !childChildCategory ? (
                      <ShopBySubSubCategories />
                    ) : parentCategory &&
                      childCategory &&
                      childChildCategory &&
                      !childChildChildCategory ? (
                      <ShopBySubSubSubCategories />
                    ) : (
                      <div />
                    )}
                    {(childChildCategory?.length ||
                      childChildChildCategory) && (
                      <Button
                        className="ShopByCategory__top_btn"
                        loading={false}
                        color="secondary"
                        onClick={() => menuSidebar.openSidebar("cart")}
                        rightIcon="filter"
                      >
                        Filtro & Rendit
                      </Button>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          )}
        </>
      )}
      {(childChildCategory?.length || childChildChildCategory?.length) && (
        <Layout
          className={cs(
            "ShopByCategory",
            (noProductsAvailable || !products?.length) &&
              "ShopLayout--no-products"
          )}
          sidebar={
            <ShopSidebar
              className="ShopByCategory__sidebar"
              handleChange={(value: number[]) => handlePriceChange(value)}
              handleAfterChange={(value: number[]) =>
                handlePriceAfterChange(value)
              }
              priceState={priceState}
            />
          }
          content={
            <ApolloErrorGuard error={error}>
              <ListOfProducts
                big
                className="ShopByCategory__products"
                loadingPlaceholderNumber={postsLimit}
                loading={loading}
                error={error?.message}
                products={products}
                isLoading={isLoadingMore}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                pageInfo={hasNextPage}
                onLoadPrevious={handleLoadPrevious}
                onLoadMore={handleLoadMore}
              />
            </ApolloErrorGuard>
          }
        />
      )}
    </ShopContextProvider>
  );
};
