import React, { useState } from 'react';
import cs from 'classnames';
import shortid from 'shortid';

import './Quantity.scss';
import { Input } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
  hideMobile?: boolean;
  maxQuantity?: number;
  quantity: number;
  onChange: (value: number) => void;
}

export const Quantity = (props: Props) => {
  const [value, setValue] = useState<number>(props.quantity || 1);
  const maxQty = props.maxQuantity;
  const debounced = useDebouncedCallback((val) => {
    if (!val || val < 0) {
      setValue(1);
      return;
    }
    props.onChange(val);
  }, 500);

  const decrement = () => {
    const newQty = value - 1;
    const newValue = newQty <= 0 ? 1 : newQty;

    setValue(newValue);
    props.onChange(newValue);
  };

  const increment = () => {
    const newQty = value + 1;
    const newValue = maxQty && newQty > maxQty ? maxQty : newQty;
    setValue(newValue);
    props.onChange(newValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value < 1) {
      setValue(value);
      debounced(value);
      return;
    }
    if (maxQty && value > maxQty) {
      setValue(maxQty);
      debounced(value);

      return;
    }
    setValue(value);
    debounced(value);
  };

  return (
    <div
      className={cs('Quantity', {
        // "Quantity--hide_mobile": props.hideMobile
      })}
    >
      <div className="Quantity__input">
        <button
          className="minus"
          disabled={props.disabled || props.disableDecrement}
          onClick={decrement}
        >
          -
        </button>
        <Input
          type="number"
          id="quantity"
          name="quantity"
          min={1}
          max={maxQty}
          value={value}
          onChange={handleChange}
          className="form-control Quantity__input__field"
        />
        <button
          className="plus"
          onClick={increment}
          disabled={
            !props.maxQuantity || props.disabled || props.disableIncrement
          }
        >
          +
        </button>
      </div>
    </div>
  );
};

interface QuantitySelectProps {
  disabled?: boolean;
  max?: number;
  value: number;
  onChange: (quantity: number) => void;
}

export const QuantitySelect = (props: QuantitySelectProps) => {
  const options = props.max
    ? Array.from({ length: props.max }, (v, i) => {
        return { key: shortid.generate(), value: i + 1 };
      })
    : null;

  const handleChange = (newValue: number) => {
    props.onChange(newValue);
  };

  return (
    <label>
      <select
        disabled={props.disabled || !options}
        id="qty"
        name="qty"
        value={props.value}
        onChange={(e) => handleChange(parseInt(e.target.value))}
      >
        {options?.map((item) => (
          <option value={item.value} key={`${item.key}-quantity`}>
            {item.value}
          </option>
        ))}
      </select>
    </label>
  );
};
