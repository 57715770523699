import React from "react";
import cs from "classnames";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { useShopFilterData } from "../../../lib/hooks/useShopFilterData";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { ShopSidebarPlaceholder } from "../ui/ShopSidebarPlaceholder";
import { BulkLoader } from "../../shared/BulkLoader/BulkLoader";
import { ShopAttributes } from "../ui/ShopAttributes";
import { GET_ATTRIBUTES_ORDERED } from "../../../queries/generalQueries";
import { useQuery } from "react-apollo";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useShopContext } from "../../../lib/context/ShopContext/ShopContext";
import * as brandsQueries from "../../../queries/brands";

import "../ui/ShopCategories.scss";
import "./ShopSidebar.scss";
import { useSearchParams } from "../../../lib/hooks/useSearchParams";

interface Props {
  className?: string;
  priceState: { min: number; max: number };
  handleChange: (value: number[]) => void;
  handleAfterChange: (value: number[]) => void;
}

export const ShopSidebar = (props: Props) => {
  // const shopHandlers = useShopHandlers();
  const shopFilterData = useShopFilterData();
  const searchParams = useSearchParams();
  const shopCtx = useShopContext();
  const router = useRouter();

  const { data } = useQuery(GET_ATTRIBUTES_ORDERED);
  const {
    loading: brandsLoading,
    data: brandsData,
    error: brandsError,
  } = useQuery(brandsQueries.getBrands);
  const items: brandsQueries.Brand[] = brandsData?.productBrands?.nodes ?? [];

  const selectedBrand = searchParams["brand"];

  // const hasOnHot = !!searchParams["tenxehta"];
  // const hasNewProducts = !!searchParams["tereja"];

  // const handleOnCategoryChange = (categorySlug: string) => {
  //   const pathname = categorySlug
  //     ? `/${shopFilterData?.redirectLinkPrefix}/${categorySlug}`
  //     : `/${shopFilterData?.redirectLinkPrefix}`;

  //   shopFilterData?.handleCategoryChange(categorySlug || "");
  //   shopHandlers.updatePathname(
  //     pathname,
  //     true,
  //     hasOnHot
  //       ? "tenxehta=true"
  //       : undefined || hasNewProducts
  //       ? "tereja=true"
  //       : undefined
  //   );
  // };

  const orderedAttributes =
    data?.generalOptions?.generalOptions?.orderAttributes ?? [];

  const ordered = shopFilterData?.attributesData?.sort(
    (a, b) =>
      orderedAttributes?.indexOf(a.slug) - orderedAttributes?.indexOf(b.slug)
  );

  return (
    <div className={cs("SidebarWrapper", props.className)}>
      <ApolloErrorGuard error={shopFilterData.error}>
        <HandleLoadingState
          loading={shopFilterData.loading}
          loadingPlaceholder={
            <BulkLoader length={1} component={ShopSidebarPlaceholder} />
          }
        >
          {/* <div className="SidebarWrapper__price">
            <h4 className="ShopCategories__title">{t(fields.filterByPrice)}</h4>
            <PriceSlider
              state={props.priceState}
              handleChange={props.handleChange}
              handleAfterOnChange={props.handleAfterChange}
            />
          </div> */}

          {/* <ShopCategories
            categories={shopFilterData.categories}
            onCategoryChange={handleOnCategoryChange}
          /> */}

          {/* <ShopSelect
            initialOpen={!!shopFilterData?.selectedSubCategory}
            loading={shopFilterData?.loading}
            relative
            closeOnSelect={false}
            disableMultiselect
            hideIfNoOptions
            showSelectedOption
            label={"Subkategorite"}
            className="SidebarWrapper__product_type"
            options={shopFilterData?.subCategoryOptions}
            selected={
              shopFilterData?.selectedSubCategory
                ? [shopFilterData?.selectedSubCategory]
                : undefined
            }
            onSelect={(selectOption) => {
              const pathName = selectOption
                ? `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}/${selectOption?.value}`
                : `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}`;
              shopFilterData?.handleCategoryChange(selectOption?.value || "");
              shopHandlers.updatePathname(
                pathName,
                true,
                hasOnHot
                  ? "tenxehta=true"
                  : undefined || hasNewProducts
                  ? "tereja=true"
                  : undefined
              );
            }}
          /> */}

          <ApolloErrorGuard error={shopFilterData?.attributes?.error}>
            {ordered?.map((attr, index) => {
              return (
                <ShopAttributes
                  key={`${attr.slug}-${index}`}
                  loading={shopFilterData?.attributes.loading}
                  isLastAttribute={
                    shopFilterData?.attributesData.length - 1 === index
                  }
                  attributeTitle={attr.label}
                  attribute={attr.slug}
                  options={attr?.terms.map((t) => ({
                    isColor: attr.slug === "PANGJYRA",
                    name: t.name,
                    value: t.slug,
                    label: t.name,
                    count: t.count,
                  }))}
                  onChange={(attr, selectedValue) =>
                    shopFilterData?.handleAttributesOnChange(
                      attr,
                      selectedValue
                    )
                  }
                />
              );
            })}
          </ApolloErrorGuard>
        </HandleLoadingState>
      </ApolloErrorGuard>

      {!router.pathname.startsWith("/brands") && (
        <HandleLoadingState
          loading={brandsLoading}
          loadingPlaceholder={
            <BulkLoader length={5} component={ShopSidebarPlaceholder} />
          }
        >
          <h4 className="ShopCategories__title mb-0">Brendet</h4>
          <ApolloErrorGuard error={brandsError}>
            <div className="ShopSelect">
              <div className="ShopSelect__dropdown">
                <ul className="ShopSelect__dropdown_list">
                  {items?.map((brand: any, index) => {
                    return (
                      <li
                        key={`${brand.value}-${index}`}
                        className="ShopSelect__dropdown_list_item"
                        data-selected={selectedBrand === brand.slug}
                        onClick={() => {
                          if (shopCtx.data.shopType === "category") {
                            router.history.push(
                              `/shop/${shopCtx?.data?.category || ""}/${
                                shopCtx?.data?.subCategory || ""
                              }/${shopCtx?.data?.subSubCategory || ""}/${
                                shopCtx?.data?.subSubSubCategory || ""
                              }?brand=${brand.slug}`
                            );
                            return;
                          }

                          router.history.push(
                            `/shop/${shopCtx?.data?.category || ""}/${
                              shopCtx?.data?.subCategory || ""
                            }/${shopCtx?.data?.subSubCategory || ""}/${
                              shopCtx?.data?.subSubSubCategory || ""
                            }?brand=${brand.slug}`
                          );
                        }}
                        data-active={selectedBrand === brand.slug}
                      >
                        {brand.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </ApolloErrorGuard>
        </HandleLoadingState>
      )}
    </div>
  );
};
