import React, { ReactNode } from "react";
import classNames from "classnames";
import { SidebarContextProvider } from "../../../lib/context/SidebarContext/SidebarContextProvider";
// import { ShopMobileFilters } from "../ShopMobileFilters/ShopMobileFilters";
// import { MobileFiltersContextProvider } from "../../../lib/context/MobileFiltersContext/MobileFiltersContextProvider";
import { Container } from "../../Container/Container";
import { MobileFiltersContextProvider } from "../../../lib/context/MobileFiltersContext/MobileFiltersContextProvider";
import { ShopMobileFilters } from "../ShopMobileFilters/ShopMobileFilters";
import { CartSidebar } from "../../../layout/CartSidebar/CartSidebar";

import "./Layout.scss";

interface Props {
  sidebar: ReactNode;
  content: ReactNode;
  head?: ReactNode;
  className?: string;
}

export const Layout = (props: Props) => {
  return (
    <div className={classNames("Layout", props.className)}>
      <SidebarContextProvider>
        <MobileFiltersContextProvider>
          <ShopMobileFilters />
          <CartSidebar sidebar={props.sidebar} />
          <div className="Layout__content">
            <Container className="p-0-mobile">{props.content}</Container>
          </div>
        </MobileFiltersContextProvider>
      </SidebarContextProvider>
    </div>
  );
};
