import { apiRequest } from "./api";
import { LoginFields } from "../pages/Login/Login";

export interface RegisterInputs {
  first_name: string;
  last_name: string;
  city: string;
  address: string;
  phone: string;
  email: string;
  email_confirmation: string;
  country: string;
  birthday: string;
  company: string;
  gender: string;
  industry: string;
  interested: string[];
  jobPosition: string;
  // postcode: string;
  deviceId: string;
}

export interface SetPassword {
  deviceId: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface VerifyInputs {
  token: string;
}

export interface ForgotPassword {
  email: string;
}

export const login = async (data: LoginFields) =>
  apiRequest<LoginFields, any>("post", "login", data);

export const register = async (data: RegisterInputs) =>
  apiRequest<RegisterInputs, any>("post", "register", data);

export const verify = async (data: VerifyInputs) =>
  apiRequest<VerifyInputs, any>("post", "verify", data);

export const setPassword = async (data: SetPassword) =>
  apiRequest<SetPassword, any>("post", "setPassword", data);

export const forgotPassword = async (data: ForgotPassword) =>
  apiRequest<ForgotPassword, any>("post", "forgot-password", data);
