import React from "react";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

import "../SingleProductDetails/SingleProductDetails.scss";
import "./ProductSingleContent.scss";

export const SingleProductContent = () => {
  const { product } = useProductSingleContext();
  const { renderProductFieldsByLanguage } = useProductSingleHelpers();

  return (
    <>
      <div className="SingleProductContent__header">
        {/* <div className="top-content">
          <WishListButton id={product.databaseId} />
        </div> */}
        <h1 className="SingleProduct__content__title">
          {renderProductFieldsByLanguage(product, "titleEn", "name")}
        </h1>
        {/* {price.sale ? (
          <ProductPricesTemporary regular={price.regular} sale={price.sale} />
        ) : ( */}
        {/* <ProductPrices
          onSale={product.onSale}
          regular={price.regular}
          sale={price.sale}
        /> */}
        {/* )} */}
      </div>
    </>
  );
};
