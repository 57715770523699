import React, { useEffect, useState } from "react";
import { ShopContextType, ShopContext } from "./ShopContext";

interface Props {
  data?: ShopContextType;
  children: React.ReactNode;
}

export const ShopContextProvider = (props: Props) => {
  const [data, setData] = useState<ShopContextType | undefined>(
    props.data || undefined
  );

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const context: ShopContextType = {
    data: {
      category: data?.data?.category,
      subCategory: data?.data?.subCategory,
      subSubCategory: data?.data?.subSubCategory,
      subSubSubCategory: data?.data?.subSubSubCategory,
      shopType: data?.data?.shopType,
      shopLinkPrefix: data?.data?.shopLinkPrefix,
      order: data?.data?.order,
      slug: data?.data?.slug,
    },
    handlers: data?.handlers,
  };

  return (
    <ShopContext.Provider value={context}>
      {props.children}
    </ShopContext.Provider>
  );
};
