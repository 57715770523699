import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ForgotPasswordFields } from "../../pages/ForgotPassword/ForgotPassword";

interface UseForgotPasswordFormOptions {
  initialValues: ForgotPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ForgotPasswordFields>
  ) => Promise<any>;
}

export const useForgotPasswordFormik = (
  options: UseForgotPasswordFormOptions
) => {
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Emaili duhet te permbaj @.")
      .required("Emaili eshte i nevojshem!"),
  });

  return useFormik({
    initialValues: {
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ForgotPasswordFormik = ReturnType<typeof useForgotPasswordFormik>;
