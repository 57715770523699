import React from "react";
import { useLoginFormik } from "../../lib/formik/useLoginFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useHistory } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useCookiesContext } from "../../lib/context/CookiesContextProvider/CookiesContext";
import { login } from "../../api/User";
import cs from "classnames";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import "./Login.scss";

export interface LoginFields {
  fcmToken: string;
  deviceId: string;
  email: string;
  password: string;
}

export const Login = () => {
  const authCtx = useAuthContext();
  const cookiesCtx = useCookiesContext();
  const notificationCtx = useNotification();
  const history = useHistory();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const formik = useLoginFormik({
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        password: values.password,
        deviceId: cookiesCtx.uuid,
        fcmToken: cookiesCtx.fcmToken,
      };

      try {
        const res = await login(data);

        if (!res) return;

        authCtx.login(res);
      } catch (e) {
        let error: any = e;
        notificationCtx.danger(
          error.response?.data?.data?.message || error.response?.data?.message,
          true
        );
      }
    },
  });

  return (
    <div className="Login">
      <div className={cs("Login__wrapper", isMobile && "Login__wrapper-m")}>
        <div className="Login__wrapper__img Login__wrapper__img_cs">
          <img
            style={{ width: 168, height: 33 }}
            src={require("../../assets/images/profiti-shop.png")}
            alt="logo"
          />
        </div>
        <h4>Kyçuni në llogarinë e juaj</h4>
        <LoginForm formik={formik} isLoading={formik.isSubmitting} />
      </div>
      <div className="Login__footer">
        <span>Nuk keni llogari në platformë?</span>
        <strong onClick={() => history.push("/register")}>
          Regjistrohu këtu
        </strong>
      </div>
    </div>
  );
};
