import React, { useRef, useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import Flickity from "react-flickity-component";
import { Container } from "../../Container/Container";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

import { BrandsLoader } from "./BrandsLoader";
import { ThemeLink } from "../ThemeLink/ThemeLink";
import { ApolloErrorGuard } from "../ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../HandleLoadingState/HandleLoadingState";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";
import { Image } from "../Image/Image";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";

import * as brandsQueries from "../../../queries/brands";

import "./BrandsIcons.scss";

export interface HomeVendorProps {
  name: string;
  slug: string;
  brandData: {
    logo: {
      sourceUrl: string;
    };
  };
}

export const BrandsIcons = () => {
  const { loading, data, error } = useQuery(brandsQueries.getHomeBrands);
  const flickity = useRef<Flickity | null>(null);
  const history = useHistory();
  const items: HomeVendorProps[] =
    data?.generalOptions?.generalOptions?.randomBrands?.randomBrands ?? [];
  const lngCtx = useLanguageContext();
  const prefix = lngCtx.getLangPrefix();
  const windowWidth = useWindowWidth();
  const [titleRef] = useState<any>(null);

  const isTablet = windowWidth < 1024;

  return (
    <div className="BrandsIcons">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={<BrandsIconsLoader total={6} />}
      >
        <ApolloErrorGuard error={error}>
          <Container>
            <div className="BrandsIcons__wrapper">
              <div className="BrandsIcons__wrapper_title">
                <span>Partnerët tanë të besuar:</span>
              </div>
              <div className="w-100 d-block">
                <Flickity
                  flickityRef={(c) => (flickity.current = c)}
                  className="carousel"
                  elementType="div"
                  options={{
                    wrapAround: false,
                    draggable: true,
                    cellAlign: items?.length <= 4 ? "center" : "left",
                    freeScroll: true,
                    resize: true,
                    pageDots: false,
                    prevNextButtons: false,
                    contain: true,
                    bgLazyLoad: true,
                    lazyLoad: 1,
                  }}
                  disableImagesLoaded={false}
                >
                  {!isTablet && (
                    <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
                  )}
                  {items?.map((item: HomeVendorProps) => {
                    return (
                      <li
                        onClick={() =>
                          history.push(`${prefix}/brands/${item?.slug}`)
                        }
                        className="BrandsIcons__list__item"
                        key={`${item?.slug}-home-brands-listing`}
                      >
                        <ThemeLink to={`/brands/${item?.slug}`}>
                          {item?.brandData?.logo?.sourceUrl ? (
                            <Image
                              alt={item?.name}
                              src={item?.brandData?.logo?.sourceUrl}
                            />
                          ) : (
                            <span className="BrandsIcons__list__item__name">
                              {removeHTMLEntities(item?.name)}
                            </span>
                          )}
                        </ThemeLink>
                      </li>
                    );
                  })}
                  {!isTablet && (
                    <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
                  )}
                </Flickity>
              </div>
              {/* <ul className="BrandsIcons__list">
                {items?.map((item: HomeVendorProps) => {
                  return (
                    item?.brandData?.logo && (
                      <li
                        onClick={() =>
                          history.push(`${prefix}/brands/${item.slug}`)
                        }
                        className="BrandsIcons__list__item"
                        key={`${item.slug}-home-brands-listing`}
                      >
                        <ThemeLink to={`/brands/${item.slug}`}>
                          {item.brandData?.logo?.sourceUrl ? (
                            <Image
                              alt={item.name}
                              src={item.brandData.logo.sourceUrl}
                            />
                          ) : (
                            <span className="BrandsIcons__list__item__name">
                              {removeHTMLEntities(item?.name)}
                            </span>
                          )}
                        </ThemeLink>
                      </li>
                    )
                  );
                })}
              </ul> */}
            </div>
          </Container>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};

export const BrandsIconsLoader = (props: { total: number }) => {
  const items = [];

  while (items.length <= props.total) {
    items.push(<BrandsLoader key={`${items.length}-home-brands-listing`} />);
  }

  return (
    <div className="BrandsIcons">
      <ul className="BrandsIcons__list">{items}</ul>
    </div>
  );
};
