import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../../shared/Image/Image';
import { CartNode } from '../../../queries/cartQueries';
import { ThemeLink } from '../../shared/ThemeLink/ThemeLink';
import { fields } from '../../../lib/translation/strings';
import { OrderItem, SingleOrder } from '../../../queries/ordersQueries';
import { useMutation } from 'react-apollo';
import { UPDATE_ORDER_STATUS } from '../../../queries/mutations/checkoutMutation';
import { useNotification } from '../../../lib/context/NotificationContext/NotificationContext';
import { Spinner } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as TimeLeft } from '../../../assets/icons/timeleft.svg';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';
import { Container } from '../../Container/Container';
import { Button } from '../../shared/Button/Button';

import './OrderSingleApproved.scss';
import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import { removeHTMLEntities } from '../../../lib/helpers/removeHTMLEntities';

const getStockQuantity = (item: CartNode) => {
  const product = item.product;

  if (product.type === 'SIMPLE') {
    return product?.stockQuantity || undefined;
  }

  if (product.type === 'VARIABLE') {
    return item?.variation?.stockQuantity || product.stockQuantity || undefined;
  }
};

interface Props {
  orderList: OrderItem[];
  orderData: SingleOrder;
}

export const OrderSingleApproved = (props: Props) => {
  const { orderList, orderData } = props;
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const navigation = useHistory();
  const ntfCtx = useNotification();
  const windowWidth = useWindowWidth();
  const isTablet = windowWidth <= 1024;
  const [orders, setOrders] = useState<any>([]);
  const [mutation, { loading }] = useMutation(UPDATE_ORDER_STATUS);

  const currentOrder = orders?.filter(
    (item: { orderId: string }) => item.orderId === orderData.orderNumber
  );

  useEffect(() => {
    if (currentOrder[0]?.timeLeft === 0) {
      cancelCurrentOrder(currentOrder[0].orderId);
    }
    // eslint-disable-next-line
  }, [currentOrder[0]?.timeLeft]);

  const cancelCurrentOrder = async (
    orderId: string,
    hasUserCanceled = false
  ) => {
    try {
      const res = await mutation({
        variables: {
          orderId: parseInt(orderId),
          status: 'CANCELLED',
          userId: authCtx?.user?.userId,
        },
      });

      if (!res) {
        return;
      }

      removeOrder(orderId);
      navigation.push('/my-profile/orders');
      ntfCtx.warning(
        hasUserCanceled
          ? 'Porosia juaj është anuluar me sukses!'
          : 'Porosia juaj është anuluar sepse nuk keni kompletuar porosinë brenda kohës së caktuar!',
        true
      );
    } catch (error) {
      ntfCtx.danger('Diçka shkoj keq ju lutemi provoni përsëri!', true);
    }
  };

  const completeCurrentOrder = async (orderId: string) => {
    try {
      const res = await mutation({
        variables: {
          orderId: parseInt(orderId),
          status: 'COMPLETED',
          userId: authCtx?.user?.userId,
        },
      });

      if (!res) {
        return;
      }

      removeOrder(orderId);
      navigation.push('/my-profile/orders');
      ntfCtx.success('Porosia juaj është kompletuar me sukses!', true);
    } catch (error) {
      ntfCtx.danger('Diçka shkoj keq ju lutemi provoni përsëri!', true);
    }
  };

  useEffect(() => {
    handleTimePerOrder(orderData.orderNumber);
  }, [orderData]);

  const handleTimePerOrder = (orderId: string) => {
    if (!orderId) {
      return;
    }

    // Get orders array from cookies or initialize it to an empty array
    const ordersFromCookies = JSON.parse(localStorage.getItem('orders')!) || [];
    setOrders(ordersFromCookies);

    // Find the order with the matching order id in the orders array
    const orderIndex = ordersFromCookies.findIndex(
      (order: { orderId: string }) => order.orderId === orderId
    );

    if (orderIndex === -1) {
      // If the order is not found in the orders array, add it with a time left of 10 minutes
      const newOrder = {
        orderId: orderId,
        timeLeft: 600,
        lastVisited: Date.now(),
      };
      setOrders([...ordersFromCookies, newOrder]);

      // Save the updated orders array to cookies
      localStorage.setItem(
        'orders',
        JSON.stringify([...ordersFromCookies, newOrder])
      );
    } else {
      // If the order is found in the orders array, update its time left
      const order = ordersFromCookies[orderIndex];
      const currentTime = Date.now();
      const timeElapsed = Math.floor((currentTime - order.lastVisited) / 1000);
      const newTimeLeft = order.timeLeft - timeElapsed;
      order.timeLeft = newTimeLeft >= 0 ? newTimeLeft : 0;
      order.lastVisited = currentTime;

      // Update the orders array and save it to cookies
      const newOrders = [...ordersFromCookies];
      newOrders[orderIndex] = order;
      setOrders(newOrders);
      localStorage.setItem('orders', JSON.stringify(newOrders));
    }
  };

  function formatTimeLeft(timeLeft: number) {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  const removeOrder = (orderId: string) => {
    const newOrders = orders.filter(
      (order: { orderId: string }) => order.orderId !== orderId
    );
    setOrders(newOrders);
    localStorage.setItem('orders', JSON.stringify(newOrders));
  };

  if (loading) {
    return (
      <div className="OrderSingleApproved__wrapper">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 70,
          }}
        >
          <Spinner size="lg" />
        </div>
      </div>
    );
  }

  return (
    <div className="OrderSingleApproved__wrapper">
      <div className="OrderSingleApproved">
        <div className="ProfileOrdersSingle__content">
          <div className="OrderSingleApproved__title">
            <span className="ProfileOrdersSingle__order-nr">
              <strong>{t(fields.order)}: </strong>#{orderData?.orderNumber}
            </span>

            {!isTablet && (
              <span className="ProfileOrdersSingle__order-nr OrderSingleApproved__quantity_title">
                Çmimi
              </span>
            )}
          </div>
          <div className="ProfileOrdersSingle__left-side">
            {orderList?.map((item: any, index: number) => {
              const stockQuantity = getStockQuantity(item);

              return isTablet ? (
                <div key={index} className="CartItem">
                  <div className="CartItem__wrapper">
                    <div className="CartItem__wrapper__left">
                      <div className="CartItem__image">
                        {item.product?.image && (
                          <ThemeLink to={`/product/${item.product?.slug}`}>
                            <Image
                              dynamicRef={true}
                              src={item?.product?.image?.sourceUrl}
                              srcSet={item?.product?.image?.sourceUrl}
                              alt={item.product.name}
                            />
                          </ThemeLink>
                        )}
                      </div>
                      <div className="CartItem__content">
                        <div className="CartItem__content_txt">
                          <ThemeLink to={`/product/${item.product?.slug}`}>
                            <h3 className="CartItem__content__title">
                              {item.product?.name}
                            </h3>
                          </ThemeLink>
                          {/* <span>{stockQuantity} në stok</span> */}
                        </div>
                      </div>
                    </div>
                    <div className="CartItem__wrapper__right">
                      <div className="CartItem__wrapper__right--m">
                        <span className="CartItem__wrapper__right--m-span">
                          Çmimi per copë
                        </span>
                        <div className="HowTo__content_big_card_desc_label">
                          <div className="HowTo__content_big_card_desc_label_minutes">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.product?.onSale
                                  ? item.product?.salePrice
                                  : item?.product?.regularPrice,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="CartItem__wrapper__right--m">
                        <span className="CartItem__wrapper__right--m-span">
                          Sasia
                        </span>
                        <div className="HowTo__content_big_card_desc_label">
                          <div className="HowTo__content_big_card_desc_label_minutes">
                            <span>{item?.quantity}</span>
                          </div>
                        </div>
                      </div>
                      <div className="CartItem__wrapper__right--m">
                        <span className="CartItem__wrapper__right--m-span">
                          Çmimi
                        </span>
                        <div className="CartItem__attributes__quantity">
                          {parseInt(item.total)?.toFixed(2)}€
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index} className="CartItem">
                  <div className="CartItem__wrapper">
                    <div className="CartItem__wrapper__l_r">
                      <div className="CartItem__wrapper__left">
                        <div className="CartItem__image">
                          {item.product?.image && (
                            <ThemeLink to={`/product/${item.product?.slug}`}>
                              <Image
                                dynamicRef={true}
                                src={item?.product?.image?.sourceUrl}
                                srcSet={item?.product?.image?.sourceUrl}
                                alt={item.product.name}
                              />
                            </ThemeLink>
                          )}
                        </div>
                        <div className="CartItem__content">
                          <div className="CartItem__content_txt">
                            <ThemeLink to={`/product/${item.product?.slug}`}>
                              <h3 className="CartItem__content__title">
                                {item.product?.name}
                              </h3>
                            </ThemeLink>
                            {/* <span>{stockQuantity} në stok</span> */}
                          </div>
                        </div>
                      </div>
                      <div className="CartItem__wrapper__right">
                        <div className="HowTo__content_big_card_desc_label">
                          <span className="ProfileOrdersSingle__order-nr OrderSingleApproved__quantity_title OrderSingleApproved__quantity_title_pr">
                            Çmimi për copë
                          </span>
                          <div className="HowTo__content_big_card_desc_label_minutes">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item.product?.onSale
                                  ? item.product?.salePrice
                                  : item?.product?.regularPrice,
                              }}
                            />
                          </div>
                        </div>
                        <div className="HowTo__content_big_card_desc_label">
                          <span className="ProfileOrdersSingle__order-nr OrderSingleApproved__quantity_title OrderSingleApproved__quantity_title_pr">
                            Sasia
                          </span>
                          <div className="HowTo__content_big_card_desc_label_minutes">
                            <span>{item?.quantity}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="CartItem__attributes__quantity">
                      {parseFloat(item.total)?.toFixed(2)}€
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="CartItem">
              <div className="CartItem__wrapper">
                <div className="CartItem__content">
                  <div className="CartItem__content_txt CartItem__content_txt--custom">
                    <h3 className="CartItem__content__title">Transporti</h3>
                    <span>{removeHTMLEntities(orderData?.shippingTotal)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentOrder[0]?.timeLeft !== 0 && (
        <div className="CartTotal">
          <Container>
            <div className="CartTotal__wrapper__approved">
              <div className="CartSidebar-footer">
                <div className="CartSidebar-footer--left">
                  <span>Çmimi total</span>
                  <h1 dangerouslySetInnerHTML={{ __html: orderData.total }} />
                </div>
                <Button
                  color="secondary"
                  title=""
                  onClick={() =>
                    cancelCurrentOrder(currentOrder[0].orderId, true)
                  }
                  style={{ marginRight: 8 }}
                >
                  Anulo
                </Button>
                <Button
                  color="primary"
                  title=""
                  onClick={() => completeCurrentOrder(currentOrder[0].orderId)}
                  className="FeedbackModal__button"
                >
                  Vazhdo me porosinë
                </Button>
              </div>
              <div className="CartTotal__wrapper__approved--time d-flex align-items-center">
                <TimeLeft style={{ marginRight: 7 }} />
                <span>{formatTimeLeft(currentOrder[0]?.timeLeft)} minuta</span>
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};
