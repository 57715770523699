import React from "react";
import cs from "classnames";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import { fields } from "../../../../lib/translation/strings";
import { useMenuContextNew } from "../../../../lib/context/MenuContextNew/MenuContextNew";

import { ShopProductCategory } from "../../../../queries/shop";
import { ThemeLink } from "../../../../components/shared/ThemeLink/ThemeLink";

import "./ColumnMenuLevel.scss";

interface Props {
  id: string;
  link: string;
  depth: number;
  items: ShopProductCategory[];
}

export const ColumnMenuLevel = (props: Props) => {
  const { t } = useTranslation();
  const menuCtx = useMenuContextNew();

  const classes = cs("ColumnMenuLevel", {
    "ColumnMenuLevel--active":
      menuCtx.desktop.activeLevels[props.depth] === props.id,
  });

  return (
    <>
      <div className={classes} data-depth={props.depth}>
        <Container>
          <div className="ColumnMenuLevel__wrapper">
            <ul className="ColumnMenuLevel__list">
              {props.items.map((item: ShopProductCategory) => {
                // const iconUrl = item.categoryOptions?.icon?.mediaItemUrl;

                return (
                  <div key={`${item.id}-menu-listing`}>
                    <li style={{ fontWeight: "bold" }}>
                      <ThemeLink
                        to={`${props.link}/${item.slug}`}
                        onClick={() => {
                          menuCtx.desktop.closeMenu();
                        }}
                      >
                        {t(item.name)}
                      </ThemeLink>
                    </li>
                    <div className="ColumnMenuLevel-menu-listing-child">
                      {item.children?.nodes.map((child) => {
                        return (
                          <li key={child.id} style={{ marginBottom: 12 }}>
                            <ThemeLink
                              to={`${props.link}/${item.slug}/${child.slug}`}
                              onClick={() => {
                                menuCtx.desktop.closeMenu();
                              }}
                            >
                              {t(child.name)}
                            </ThemeLink>
                          </li>
                        );
                      })}
                    </div>
                    {item.children.nodes.length ? (
                      <li
                        className="ColumnMenuLevel__all"
                        style={{ marginBottom: 12 }}
                      >
                        <ThemeLink
                          to={`${props.link}/${item.slug}/`}
                          onClick={() => {
                            menuCtx.desktop.closeMenu();
                          }}
                        >
                          {t(fields.viewAll)}
                        </ThemeLink>
                      </li>
                    ) : null}
                  </div>
                );
              })}
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};
