import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";

import { ResetPasswordFields } from "../../pages/ResetPassword/ResetPassword";

interface UseResetPasswordFormOptions {
  initialValues: ResetPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ResetPasswordFields>
  ) => Promise<any>;
}

export const useResetPasswordFormik = (
  options: UseResetPasswordFormOptions
) => {
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Passwordi eshte i nevojshem!"),
    passwordConfirm: Yup.string()
      .required("Konfirmimi i passwordit eshte i nevojshem!")
      .oneOf([Yup.ref("password")], "Passwordi nuk eshte i ngjashem!"),
  });

  return useFormik({
    initialValues: {
      ...options.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type ResetPasswordFormik = ReturnType<typeof useResetPasswordFormik>;
