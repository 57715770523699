import React from 'react';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import {
  GET_ORDERS_QUERY,
  Orders,
  OrdersItem,
} from '../../../queries/ordersQueries';
import { getFormatedDate } from './../../../lib/helpers/getFormatedDate';
import { removeHTMLEntities } from '../../../lib/helpers/removeHTMLEntities';
import { NavLink } from 'react-router-dom';
import { Row, Col, Spinner } from 'reactstrap';
import { errors, fields } from '../../../lib/translation/strings';
import { Image } from '../../shared/Image/Image';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';

import './ProfileOrders.scss';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';

export const ProfileOrderItem = (props: { order: OrdersItem }) => {
  const { t } = useTranslation();
  const { order } = props;

  const getStatus = (order: string) => {
    switch (order) {
      case 'ON_HOLD':
        return 'Në pritje';

      case 'APPROVED':
        return 'E aprovuar';

      case 'CANCELLED':
        return 'E anuluar';

      case 'COMPLETED':
        return 'E kompletuar';

      default:
        return null;
    }
  };

  return (
    <NavLink
      className={cs(
        'ProfileOrderItem',
        order.status === 'ON_HOLD' && 'ProfileOrderItem__onHold',
        order.status === 'CANCELLED' && 'ProfileOrderItem__declined',
        order.status === 'COMPLETED' && 'ProfileOrderItem__completed'
      )}
      to={`/my-profile/orders/${order.databaseId}`}
    >
      <div className="ProfileOrderItem__thumb">
        <Image
          src={order?.lineItems?.nodes[0]?.product?.image?.sourceUrl}
          alt=""
        />
      </div>
      <div className="ProfileOrderItem__content">
        <div className="ProfileOrderItem__info">
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.order)}: </strong>#{order.orderNumber}
          </span>
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.status)}: </strong>
            {getStatus(order.status)}
          </span>
          <span className="ProfileOrderItem__info__item">
            <strong>{t(fields.orderDate)}: </strong>
            {getFormatedDate(order.date, true)}
          </span>
          {order.status === 'APPROVED' && (
            <span className="ProfileOrderItem__info__item">
              <strong>{t(fields.total)}: </strong>
              {removeHTMLEntities(order.total)}
            </span>
          )}
        </div>
      </div>
    </NavLink>
  );
};

export const ProfileOrders = () => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const { loading, error, data } = useQuery<Orders>(GET_ORDERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      customerId: typeof authCtx.user?.userId === "string" ? parseInt(authCtx.user?.userId) : authCtx.user?.userId ,
    },
  });

  const orderData = data?.customer?.orders?.nodes;

  const noOrders = !orderData || orderData.length === 0;

  return (
    <div className="ProfileOrders">
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 70,
            }}
          >
            <Spinner size="lg" />
          </div>
        }
      >
        <ApolloErrorGuard error={error}>
          {noOrders ? (
            <p>{t(errors.noOrdersFound)}</p>
          ) : (
            <Row>
              {orderData?.map((order: any, i) => {
                return (
                  <Col xs={12} md={4} key={`${order.id}-${i}`}>
                    <ProfileOrderItem order={order} />
                  </Col>
                );
              })}
            </Row>
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
