import React, { useEffect, useState } from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";
import { verify } from "../../api/User";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { ReactComponent as Message } from "../../assets/icons/message-forgot.svg";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import { Button } from "../../components/shared/Button/Button";

import "./Verify.scss";

export const Verify = () => {
  const history = useHistory();
  const windowWidth = useWindowWidth();
  const searchParams = useSearchParams();
  const notificationCtx = useNotification();
  const [state, setState] = useState({
    error: false,
    loading: true,
  });

  const hasToken = !!searchParams["token"];

  const handleVerify = async () => {
    const data = {
      token: searchParams["token"]?.toString().replaceAll(" ", "+")!,
    };

    try {
      const res = await verify(data);

      setState({ ...state, loading: false });

      if (!res) return;
    } catch (e) {
      setState({ loading: false, error: true });
      if (e instanceof Error) {
        notificationCtx.danger(e?.message, true);
        return;
      }
    }
  };

  useEffect(() => {
    if (
      !hasToken ||
      (searchParams["token"] && searchParams["token"].length <= 50)
    ) {
      history.push("/login");
      return;
    }

    handleVerify();
  }, [hasToken]);

  const isMobile = windowWidth <= 1024;

  return (
    <div className="Login">
      {isMobile && (
        <div className="Login__wrapper__img">
          <img
            style={{ width: 168, height: 33 }}
            src={require("../../assets/images/profiti-shop.png")}
            alt="logo"
          />
        </div>
      )}
      <div className={cs("Login__wrapper", isMobile && "Login__wrapper-m")}>
        {!isMobile && (
          <div className="Login__wrapper__img">
            <img
              style={{ width: 168, height: 33 }}
              src={require("../../assets/images/profiti-shop.png")}
              alt="logo"
            />
          </div>
        )}
        <div className="Login__wrapper__success">
          {state.loading ? (
            <Spinner size="lg" color="#000" style={{ marginBottom: 50 }} />
          ) : state.error ? (
            <p>Diçka ka shkuar gabim ju lutemi provoni përsëri!</p>
          ) : (
            <p>Ju keni verifikuar email-in tënd me sukses!</p>
          )}
          <Message style={{ width: 132, height: 97 }} />
          {!state.error && !state.loading && (
            <Button
              onClick={() => history.push("/login")}
              color="primary"
              type="submit"
              className="Login__submit w-100-mobile"
            >
              Kyçuni tani
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
