import React, { useEffect, useState } from "react";
import { useRouter } from "../../hooks/useRouter";
import { useApolloClient } from "react-apollo";

import { isTokenExpired } from "../../helpers/isTokenExpired";
import { AuthContext, AuthContextType } from "./AuthContext";

// Queries
import { GET_VIEWER_QUERY } from "../../../queries/userQueries";
import {
  deleteLocalValue,
  getLocalValues,
  setLocalValue,
} from "../../helpers/localStorageHelpers";
import { ExecutionResult } from "graphql";
import { Logo } from "../../../components/Logo/Logo";

interface AuthContextProviderProps {
  children: React.ReactNode | null;
}

export interface AuthContextUserProps {
  id: string;
  email: string;
  userId: number;
  lastName: string;
  username: string;
  firstName: string;
  wishlist?: number[];
}

interface AuthContextProviderState {
  loading: boolean;
  user: AuthContextUserProps | undefined;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const router = useRouter();
  const client = useApolloClient();
  const localValues = getLocalValues();
  // const langPrefix = i18n.language === "sq" ? "" : "/" + i18n.language;
  const langPrefix = "/";

  const [state, setState] = useState<AuthContextProviderState>({
    loading: true,
    user: undefined,
    error: undefined,
    loginError: undefined,
    registerError: undefined,
  });

  //On route change: reset errors
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: undefined,
      loginError: undefined,
      registerError: undefined,
    }));
  }, [router.location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    checkAuthentication();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    if (!localValues.refreshToken || isTokenExpired(localValues.refreshToken)) {
      setState({
        ...state,
        user: undefined,
        loading: false,
      });
      return;
    }
    try {
      const res = await client.query({
        query: GET_VIEWER_QUERY,
      });

      if (res.errors) {
        setState({
          ...state,
          loading: false,
          error: res.errors.toString(),
        });
      }

      if (res?.data?.viewer) {
        const user = res?.data?.viewer;

        const wishlistProducts = user.wishListProducts.wishlistProducts
          ? JSON.parse(user.wishListProducts.wishlistProducts)
          : [];

        setState({
          ...state,
          user: {
            id: user.id,
            email: user.email,
            userId: user.userId,
            lastName: user.lastName,
            username: user.username,
            firstName: user.firstName,
            wishlist: wishlistProducts,
          },
          error: undefined,
          loading: false,
        });
      }
    } catch (err) {
      let errors: any = err;
      setState({
        ...state,
        error: errors.toString(),
        user: undefined,
        loading: false,
      });
    }
  };

  const updateUser = (values: AuthContextUserProps) => {
    setState({
      ...state,
      user: {
        ...values,
      },
    });
  };

  const login = async (
    res: {
      user: {
        id: string;
        authToken: string;
        email: string;
        firstName: string;
        lastName: string;
        username: string;
        userId: number;
        refreshToken: string;
        wishListProducts: {
          wishlistProducts: any;
        };
      };
    },
    redirect: boolean = true
  ) => {
    if (!res?.user) {
      return;
    }

    const user = res.user;

    const wishlistProducts = user?.wishListProducts?.wishlistProducts
      ? JSON.parse(user?.wishListProducts?.wishlistProducts)
      : [];

    // TODO: Re check how the local storage values get deleted
    setLocalValue("authToken", user?.authToken);
    setLocalValue("refreshToken", user?.refreshToken);
    deleteLocalValue("wishlist");

    setState({
      ...state,
      loginError: undefined,
      user: {
        id: user.id,
        email: user.email,
        userId: user.userId,
        lastName: user.lastName,
        username: user.username,
        firstName: user.firstName,
        wishlist: wishlistProducts,
      },
    });

    const hasRedirectParam = router.location.search.includes("redirect");
    const returnURL =
      redirect && hasRedirectParam
        ? decodeURIComponent(router.location.search.split("redirect=")[1])
        : "/";

    router.history.push(returnURL);
  };

  const register = async (res: ExecutionResult) => {
    if (res.data?.registerCustomer) {
      const user = res.data?.registerCustomer.customer;

      setState({
        ...state,
        registerError: undefined,
        user: {
          id: user.id,
          email: user.email,
          userId: user.databaseId,
          lastName: user.lastName,
          username: user.username,
          firstName: user.firstName,
        },
      });

      setLocalValue("authToken", res.data.registerCustomer.authToken);
      setLocalValue("refreshToken", res.data.registerCustomer.refreshToken);

      const hasRedirectParam = router.location.search.includes("redirect");
      const returnURL = hasRedirectParam
        ? decodeURIComponent(router.location.search.split("redirect=")[1])
        : "/";

      router.history.push(returnURL);
    }
  };

  const logout = async () => {
    localStorage.removeItem("PROFITISHOP_USER");
    setState({
      ...state,
      user: undefined,
    });
    await client.cache.reset();
    router.history.push(langPrefix);
  };

  const context: AuthContextType = {
    isAuthenticated: state.user !== undefined,
    isLoading: state.loading,
    error: state.error,
    loginError: state.loginError,
    registerError: state.registerError,
    user: state.user,
    updateUser,
    logout: logout,
    checkAuthentication,
    login: login,
    register: register,
  };

  if (state.loading) {
    return (
      <div className="App App--loading">
        <Logo noLink />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );
};
