import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { formInputs } from "../../lib/translation/strings";

import "./Register.scss";
import { RegisterSecondStepFormik } from "../../lib/formik/useRegisterSecondStepFormik";

interface Props {
  loading: boolean;
  formik: RegisterSecondStepFormik;
}

export const RegisterFormStepTwo = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div className="RegisterFormStepTwo">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" sm="12">
            <FormGroup>
              <Label for="registerPassword">Fjalëkalimi</Label>
              <Input
                type="password"
                id="registerPassword"
                placeholder={t(formInputs.password)}
                onChange={formik.handleChange}
                className="form-control-custom"
                value={formik.values.registerPassword}
                invalid={
                  !!formik.errors.registerPassword &&
                  formik.touched.registerPassword
                }
              />
              <FormFeedback>{formik.errors.registerPassword}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="registerConfirmPassword">Konfirmo fjalëkalimin</Label>

              <Input
                type="password"
                placeholder={t(formInputs.confirmPassword)}
                id="registerConfirmPassword"
                onChange={formik.handleChange}
                className="form-control-custom"
                value={formik.values.registerConfirmPassword}
                invalid={
                  !!formik.errors.registerConfirmPassword &&
                  formik.touched.registerConfirmPassword
                }
              />
              <FormFeedback>
                {formik.errors.registerConfirmPassword}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
