import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { useForgotPasswordFormik } from "../../lib/formik/useForgotPasswordFormik";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";

import { formInputs } from "../../lib/translation/strings";
import { Button } from "../../components/shared/Button/Button";
import { ReactComponent as Message } from "../../assets/icons/message-forgot.svg";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import cs from "classnames";
import { forgotPassword } from "../../api/User";

import "./ForgotPassword.scss";

export interface ForgotPasswordFields {
  email: string;
}

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const notification = useNotification();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const [state, setState] = useState({
    loading: false,
    success: false,
  });

  const formik = useForgotPasswordFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      notification.removeAll();
      setState({
        ...state,
        loading: true,
      });
      try {
        const data = {
          email: values.email,
        };

        await forgotPassword(data);
        setState({
          ...state,
          success: true,
          loading: false,
        });
      } catch (e) {
        let errors: any = e;
        notification.danger(errors.message);
        setState({
          ...state,
          loading: false,
        });
      }
    },
  });

  return (
    <div className="Login">
      {isMobile && (
        <div className="Login__wrapper__img">
          <img
            style={{ width: 168, height: 33 }}
            src={require("../../assets/images/profiti-shop.png")}
            alt="logo"
          />
        </div>
      )}
      <div className={cs("Login__wrapper", isMobile && "Login__wrapper-m")}>
        {!isMobile && (
          <div className="Login__wrapper__img">
            <img
              style={{ width: 168, height: 33 }}
              src={require("../../assets/images/profiti-shop.png")}
              alt="logo"
            />
          </div>
        )}
        <>
          {state.success ? (
            <div className="Login__wrapper__success">
              <p>Ne dërguam një mesazh konfirmimi në email-in tënd</p>
              <Message style={{ width: 132, height: 97 }} />
              <Button
                color="primary"
                type="submit"
                className="Login__submit w-100-mobile"
              >
                Shiko inboxin tuaj
              </Button>
            </div>
          ) : (
            <>
              <div className="Login__wrapper_title">
                <h4>Keni harruar fjalëkalimin ?</h4>
                <p>
                  Për ta krijuar një fjalëkalim të ri ju lutem vazhdoni me
                  pjesën më poshtë
                </p>
              </div>

              <div className="ForgotPassword__wrapper">
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs="12" sm="12">
                      <FormGroup>
                        <Label for="email">Email adresa</Label>
                        <Input
                          type="email"
                          id="email"
                          placeholder={t(formInputs.email)}
                          onChange={formik.handleChange}
                          className="form-control-custom"
                          value={formik.values.email}
                          invalid={
                            !!formik.errors.email && formik.touched.email
                          }
                        />
                        <FormFeedback>{formik.errors.email}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup>
                        <Button
                          color="primary"
                          type="submit"
                          className="Login__submit w-100-mobile"
                        >
                          {state.loading ? (
                            <Spinner size="sm" color="#fff" />
                          ) : (
                            "Vazhdo"
                          )}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </>
          )}
        </>
      </div>
      <div className="Login__footer">
        <span>Nuk keni llogari në platformë?</span>
        <strong onClick={() => history.push("/register")}>
          Regjistrohu këtu
        </strong>
      </div>
    </div>
  );
};

// const NewPassword = () => {
//   const authCtx = useAuthContext();
//   const notification = useNotification();
//   const [state, setState] = useState({
//     loading: false,
//     success: false,
//   });

//   const formik = useForgotPasswordFormik({
//     initialValues: {
//       email: "",
//     },
//     onSubmit: async (values) => {
//       notification.removeAll();
//       setState({
//         ...state,
//         loading: true,
//       });
//       try {
//         await authCtx.forgotPassword(values.email);
//         setState({
//           ...state,
//           success: true,
//           loading: false,
//         });
//       } catch (e: any) {
//         notification.danger(e.message);
//         setState({
//           ...state,
//           loading: false,
//         });
//       }
//     },
//   });

//   return (
//     <>
//       <div className="Login__wrapper_title">
//         <h4>Jepni fjalëkalimin e juaj të ri ?</h4>
//       </div>
//       <div className="ForgotPassword__wrapper">
//         <Form onSubmit={formik.handleSubmit}>
//           <Row>
//             <Col xs="12" sm="12">
//               <FormGroup>
//                 <Label for="password">Fjalëkalimi i ri</Label>
//                 <Input
//                   type="password"
//                   id="password"
//                   placeholder="Fjalëkalimi i ri"
//                   onChange={formik.handleChange}
//                   className="form-control-custom"
//                   value={formik.values.email}
//                   invalid={!!formik.errors.email && formik.touched.email}
//                 />
//                 <FormFeedback>{formik.errors.email}</FormFeedback>
//               </FormGroup>
//             </Col>
//             <Col xs="12" sm="12">
//               <FormGroup>
//                 <Label for="repassword">Pësërit fjalëkalimin e ri</Label>
//                 <Input
//                   type="password"
//                   id="repassword"
//                   placeholder="Pësërit fjalëkalimin e ri"
//                   onChange={formik.handleChange}
//                   className="form-control-custom"
//                   value={formik.values.email}
//                   invalid={!!formik.errors.email && formik.touched.email}
//                 />
//                 <FormFeedback>{formik.errors.email}</FormFeedback>
//               </FormGroup>
//             </Col>
//             <Col xs="12">
//               <FormGroup>
//                 <Button
//                   color="primary"
//                   type="submit"
//                   className="Login__submit w-100-mobile"
//                 >
//                   {state.loading ? "..." : "Vazhdo"}
//                 </Button>
//               </FormGroup>
//             </Col>
//           </Row>
//         </Form>
//       </div>
//     </>
//   );
// };
