import gql from "graphql-tag";
import { PageInfo } from "../types/generalTypes";
import { ProductProps } from "../types/productTypes";

export interface SearchProductVariables {
  limit: number;
  term: string;
  after: string;
}

export interface SearchAutocompleteProductsResponse {
  products: {
    nodes: ProductProps[];
    pageInfo: PageInfo;
  };
}

export const searchAutocompleteProducts = gql`
  query searchAutocompleteProducts($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        onSale
        type
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
        ... on SimpleProduct {
          id
          stockStatus
          stockQuantity
        }
        ... on VariableProduct {
          id
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const searchProducts = gql`
  query searchProducts($term: String, $limit: Int, $after: String) {
    products(first: $limit, after: $after, where: { search: $term }) {
      nodes {
        id
        slug
        databaseId
        name
        type
        ... on VariableProduct {
          name
        }
        ... on SimpleProduct {
          name
          stockStatus
          stockQuantity
          onSale
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _300X210)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
