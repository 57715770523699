import React from "react";

import { Slide } from "../../components/home/HomepageSlide/Slide";
import { Container } from "../../components/Container/Container";
import { NewIn } from "../../components/home/HomeTabs/tabs/NewIn";
import { TopSale } from "../../components/home/HomeTabs/tabs/TopSale";
import { OnSale } from "../../components/home/HomeTabs/tabs/OnSale";
import { BrandsIcons } from "../../components/shared/BrandsIcons/BrandsIcons";

import "./Home.scss";

export const Home = () => {
  return (
    <div className="Home">
      <Slide />
      <NewIn title="Produktet e reja" />
      <Container>
        <div className="Home__border" />
      </Container>
      <TopSale />
      <BrandsIcons />
      <OnSale />
    </div>
  );
};
