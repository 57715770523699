import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { getFormatedDate } from "../../../lib/helpers/getFormatedDate";
import { removeHTMLEntities } from "../../../lib/helpers/removeHTMLEntities";
import { fields, formInputs } from "../../../lib/translation/strings";
import { OrderItem, SingleOrder } from "../../../queries/ordersQueries";

import "./OrderSingleCompleted.scss";

interface Props {
  orderList: OrderItem[];
  orderData: SingleOrder;
}

export const OrderSingleCompleted = (props: Props) => {
  const { orderList, orderData } = props;
  const { t } = useTranslation();

  const getStatus = (order: string) => {
    switch (order) {
      case "ON_HOLD":
        return "Në pritje";

      case "APPROVED":
        return "E aprovuar";

      case "CANCELLED":
        return "E anuluar";

      case "COMPLETED":
        return "E kompletuar";

      default:
        return null;
    }
  };

  return (
    <div className="OrderedSingleCompleted">
      <span className="ProfileOrdersSingle__order-nr">
        <strong>Porosia: </strong>#{orderData?.orderNumber}
      </span>
      <div className="ProfileOrdersSingle__left-side">
        <Row>
          {orderList?.map((item: any, index: number) => {
            return (
              <Col xs={12} md={4} key={`${item.id}-${index}`}>
                <div className="ProfileOrdersSingle__item">
                  <div className="ProfileOrdersSingle__thumb">
                    <img src={item?.product?.image?.sourceUrl} alt="" />
                  </div>
                  <div className="ProfileOrdersSingle__content">
                    <span className="ProfileOrdersSingle__left-side__item">
                      {item?.product?.name}
                    </span>
                    <span className="ProfileOrdersSingle__left-side__item">
                      <strong>
                        {removeHTMLEntities(item?.product?.regularPrice)}
                      </strong>
                    </span>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="ProfileOrdersSingle__right-side">
        <Row>
          <Col xs={12} md={4}>
            <div className="ProfileOrdersSingle__address">
              <h4>{t(fields.billing)}: </h4>
              {orderData.billing.firstName && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.name)}: </strong>
                  {orderData.billing.firstName}
                </span>
              )}
              {orderData.billing.lastName && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.lastName)}: </strong>
                  {orderData.billing.lastName}
                </span>
              )}
              {orderData.billing.address1 && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.address)}: </strong>
                  {orderData.billing.address1}
                </span>
              )}
              {orderData.billing.state && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.country)}: </strong>
                  {orderData.billing.state}
                </span>
              )}
              {orderData.billing.postcode && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.zipCode)}: </strong>
                  {orderData.billing.postcode}
                </span>
              )}
              {orderData.billing.phone && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.telephoneNumber)}: </strong>
                  {orderData.billing.phone}
                </span>
              )}
              {orderData.billing.email && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.email)}: </strong>
                  {orderData.billing.email}
                </span>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="ProfileOrdersSingle__address">
              <h4>{t(fields.shipping)}: </h4>
              {orderData.shipping.firstName && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.name)}: </strong>
                  {orderData.shipping.firstName}
                </span>
              )}
              {orderData.shipping.lastName && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.lastName)}: </strong>
                  {orderData.shipping.lastName}
                </span>
              )}
              {orderData.shipping.address1 && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.address)}: </strong>
                  {orderData.shipping.address1}
                </span>
              )}
              {orderData.shipping.state && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.country)}: </strong>
                  {orderData.shipping.state}
                </span>
              )}
              {orderData.shipping.postcode && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.zipCode)}: </strong>
                  {orderData.shipping.postcode}
                </span>
              )}
              {orderData.shipping.phone && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.telephoneNumber)}: </strong>
                  {orderData.shipping.phone}
                </span>
              )}
              {orderData.shipping.email && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(formInputs.email)}: </strong>
                  {orderData.shipping.email}
                </span>
              )}
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="ProfileOrdersSingle__address">
              <h4>{t(fields.orderDetails)}: </h4>
              {orderData.date && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.orderDate)}: </strong>
                  {getFormatedDate(orderData.date)}
                </span>
              )}
              {orderData.status && (
                <span className="ProfileOrdersSingle__right-side__contact-item">
                  <strong>{t(fields.status)}: </strong>
                  {getStatus(orderData.status)}
                </span>
              )}
              {orderData.total && (
                <span className="ProfileOrdersSingle__right-side__contact-item right">
                  <strong>{t(fields.total)}: </strong>
                  {removeHTMLEntities(orderData.total)}
                </span>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
