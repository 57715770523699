import React from "react";
import ContentLoader from "react-content-loader";

export const MobileAttributeItemPlaceholder = () => {
  return (
    <ContentLoader
      speed={2}
      height={50}
      style={{ marginTop: 15 }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="MobileAttributeItemPlaceholder"
    >
      <rect x="0" y="0" width="100%" height="50" />
    </ContentLoader>
  );
};
