import React, { useContext } from "react";
import { AuthContextUserProps } from "./AuthContextProvider";
import { ExecutionResult } from "graphql";

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: AuthContextUserProps;
  error?: string | { [key: string]: string };
  loginError?: string | { [key: string]: string };
  registerError?: string;
  checkAuthentication: () => void;
  // login: (username: string, password: string, redirect?: boolean) => void;
  login: (
    res: {
      user: {
        id: string;
        authToken: string;
        email: string;
        firstName: string;
        lastName: string;
        username: string;
        userId: number;
        refreshToken: string;
        wishListProducts: {
          wishlistProducts: any;
        };
      };
    },
    redirect?: boolean
  ) => void;
  logout: (redirect?: string | false) => void;
  register: (res: ExecutionResult, noRedirect?: boolean) => void;
  updateUser: (values: AuthContextUserProps) => void;
}

const AuthContextValues: AuthContextType = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: undefined,
  loginError: undefined,
  registerError: undefined,
  checkAuthentication: () => {},
  login: () => {},
  logout: () => {},
  register: () => {},
  updateUser: () => {},
};

export const AuthContext = React.createContext<AuthContextType>(
  AuthContextValues
);

export const useAuthContext = () => useContext(AuthContext);
