import React from "react";
import { useTranslation } from "react-i18next";

import { ShopMobileFiltersScreen } from "./ShopMobileFiltersScreen";
import { formatCategoriesToShopMobileFilterItems } from "../../lib/formatCategoriesToShopMobileFilterItems";
import { useShopFilterData } from "../../../../lib/hooks/useShopFilterData";
import { fields } from "../../../../lib/translation/strings";
import {
  MobileFiltersStep,
  useMobileFiltersContext,
} from "../../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { Icon } from "../../../shared/Icon/Icon";

export const ChooseCategory = () => {
  const { t } = useTranslation();
  const shopFilterData = useShopFilterData();
  const mobileFiltersCtx = useMobileFiltersContext();

  const items = formatCategoriesToShopMobileFilterItems(
    shopFilterData?.categories
  );

  return (
    <ShopMobileFiltersScreen
      onGoBack={() => mobileFiltersCtx.closeFiltersSidebar()}
      body={
        <>
          <li
            className="ShopMobileFilters__main_list_item"
            onClick={() => {
              mobileFiltersCtx.setCategory("");
              mobileFiltersCtx.setSubCategory(undefined);
              mobileFiltersCtx.setSubSubCategory(undefined);
              mobileFiltersCtx.setTempSelectedAttribute(undefined);
              mobileFiltersCtx.setSelectedAttributes(null);
            }}
          >
            <span>
              {t(fields.viewAll)}
              {mobileFiltersCtx.category === "" && <Icon icon="check" />}
            </span>
          </li>

          {items.map((item) => {
            const isSelected = mobileFiltersCtx.category === item.slug;
            return (
              <li
                key={item.id}
                className="ShopMobileFilters__main_list_item"
                onClick={() => {
                  mobileFiltersCtx.setCategory(item.slug);

                  if (mobileFiltersCtx.category !== item.slug) {
                    mobileFiltersCtx.setSubCategory(undefined);
                    mobileFiltersCtx.setSubSubCategory(undefined);
                    mobileFiltersCtx.setTempSelectedAttribute(undefined);
                    mobileFiltersCtx.setSelectedAttributes(null);
                  }

                  if (item.children) {
                    mobileFiltersCtx.setStep(
                      MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE
                    );
                  }
                }}
              >
                <span>
                  {item.label}
                  {isSelected && <Icon icon="check" />}
                </span>
                {item.children && (
                  <span>
                    <Icon icon="chevron-right" />
                  </span>
                )}
              </li>
            );
          })}
        </>
      }
    />
  );
};
