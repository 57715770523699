import React from "react";
import { Spinner } from "reactstrap";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { useShopFilterData } from "../../lib/hooks/useShopFilterData";

import "./ShopByCategories.scss";

export const ShopByCategories = () => {
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const shopFilterData = useShopFilterData();

  const hasOnHot = !!searchParams["tenxehta"];
  const hasNewProducts = !!searchParams["tereja"];
  const hasBrand = !!searchParams["brand"];
  const brand = searchParams["brand"];

  const handleOnCategoryChange = (categorySlug: string) => {
    const pathname = categorySlug
      ? `/${shopFilterData?.redirectLinkPrefix}/${categorySlug}`
      : `/${shopFilterData?.redirectLinkPrefix}`;

    shopFilterData?.handleCategoryChange(categorySlug || "");
    shopHandlers.updatePathname(
      pathname,
      true,
      hasOnHot
        ? "tenxehta=true"
        : undefined || hasNewProducts
        ? "tereja=true"
        : undefined || hasBrand
        ? `brand=${brand}`
        : undefined
    );
  };

  if (shopFilterData?.loading) {
    return <Spinner style={{ marginLeft: 30 }} size="lg" color="#000" />;
  }

  if (!shopFilterData.categories) {
    return <div />;
  }

  return (
    <div className="ShopByCategories">
      <div className="ShopByCategories__wrapper">
        {shopFilterData.categories?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => handleOnCategoryChange(item.slug)}
              className="ShopByCategories__wrapper-item"
            >
              {item.image?.mediaItemUrl && (
                <div className="ShopByCategories__wrapper-item--img">
                  <img src={item.image?.mediaItemUrl} alt="category" />
                </div>
              )}
              <label>{item.name}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
