import React, { useEffect, useState } from "react";
import { MenuContext, MenuContextType } from "./MenuContext";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { useRouter } from "../../hooks/useRouter";

interface Props {
  children: React.ReactNode;
}

export const MenuContextProvider = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const windowWidth = useWindowWidth();
  const router = useRouter();

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (windowWidth <= 768 && isOpened) {
      document.body.style.overflow = "hidden";
      document.getElementById("App")?.classList.add("App--show-backdrop");
    }

    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById("App")?.classList.remove("App--show-backdrop");
    };
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (windowWidth >= 768) {
      setIsOpened(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    setIsOpened(false);
  }, [router.location.pathname]);

  const openSidebar = () => {
    setIsOpened(true);
  };

  const closeSidebar = () => {
    setIsOpened(false);
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const context: MenuContextType = {
    isOpened,
    closeSidebar,
    openSidebar,
    toggleSidebar,
  };

  return (
    <MenuContext.Provider value={context}>
      {props.children}
    </MenuContext.Provider>
  );
};
