import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { ThemeLink } from '../shared/ThemeLink/ThemeLink';
import { ProductProps } from '../../types/productTypes';
import { Image } from '../shared/Image/Image';
import { RemoveItemButton } from '../RemoveItemButton/RemoveItemButton';
// import { ProductWatermark } from "./ProductItemWatermark";
import { getProductImages } from '../../pages/SingleProduct/lib/helpers/getProductImages';
import { useHistory } from 'react-router-dom';
import { useProductSingleHelpers } from '../lib/useProductSingleHelpers';
import { WishListButton } from '../shared/WishListButton/WishListButton';
import { useCartContext } from '../../lib/context/CartContext/CartContext';

import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';
import { ReactComponent as ShoppingCart } from '../../assets/icons/shopping-cart.svg';

import './ListOfProducts.scss';

interface Props {
  big?: boolean;
  product?: ProductProps;
  noWishlistButton?: boolean;
  onRemove?: () => void;
  gridView?: 'ONE' | 'LIST';
}

export const Product = (props: Props) => {
  const history = useHistory();
  const notification = useNotification();
  const sliderImages = getProductImages(props.product);
  const [loading, setLoading] = useState(false);
  const [selectImg] = useState(sliderImages[0]?.slug);
  const { items, addProduct } = useCartContext();
  const { renderProductFieldsByLanguage } = useProductSingleHelpers();

  const thumbnail = props.big
    ? props.product?.image?.mediaItemUrl
    : props.product?.image?.sourceUrl;

  const salePrice = props.product?.regularPrice
    ? parseFloat(props.product?.salePrice)
    : 0;
  const regularPrice = props.product?.regularPrice
    ? parseFloat(props.product?.regularPrice)
    : 0;

  const discountedPercentage =
    salePrice !== 0 ? ((regularPrice - salePrice) / regularPrice) * 100 : 0;

  const productStockStatus = () => {
    if (!props.product) {
      return;
    }

    if (props.product.stockQuantity) {
      return props.product.stockQuantity;
    }

    if (props.product?.type === 'SIMPLE') {
      return props.product.stockStatus === 'OUT_OF_STOCK' ? 0 : 1;
    }

    const variationsStock = props.product.variations?.nodes?.filter(
      (vs) => vs.stockQuantity && vs.stockQuantity >= 1
    );

    return variationsStock?.length;
  };

  const stockStatus = productStockStatus();

  const img = sliderImages?.find((item: any) => item?.slug === selectImg);

  const stockQuantity = props.product?.stockQuantity
    ? props.product?.stockQuantity
    : 0;

  const getItemFromCart = () => {
    const cartItem =
      items?.filter(
        (item: any) =>
          item.product.node?.databaseId === props.product?.databaseId
      ) || [];

    if (cartItem.length === 0) {
      return;
    }

    return cartItem[0] as any;
  };

  const handleAddToCart = async () => {
    const cartItem = getItemFromCart();

    if (stockStatus === 0 || !stockStatus) {
      notification.warning('Produkti i zgjedhur nuk është në stok!'); //This product is out of stock.
      return;
    }

    if (cartItem && cartItem.quantity + props.product?.stockQuantity > 1) {
      notification.warning(
        `Ju nuk mund ta shtoni atë shumë në shportë, ju tashmë keni ${cartItem?.quantity} sasi në shportën tuaj.`
      );
      return;
    }

    try {
      setLoading(true);
      const id = props.product?.databaseId ?? props.product?.id;

      await addProduct(+id!, 1, undefined);
    } catch (error) {
      let errors: any = error;
      notification.warning(errors.message, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`Product${props.big ? ' Product--big' : ''}`}>
      {props.onRemove && <RemoveItemButton onClick={props.onRemove} />}
      <div className="Product__image_and_slider">
        <div className="Product__image">
          <span className="Product__image__holder">
            <ThemeLink to={`/product/${props.product?.slug}`}>
              <Image
                src={
                  img?.sourceUrl ||
                  img?.mediaItemUrl ||
                  thumbnail ||
                  props.product?.thumbnailUrl
                }
                alt={props.product?.name}
              />
            </ThemeLink>
          </span>
          <div className="Product__info">
            {discountedPercentage > 0 && (
              <span className="Product__discount">
                -{discountedPercentage.toFixed()}%
              </span>
            )}
            {/* {stockStatus === 0 || !stockStatus ? (
              <span className="Product__out-of-stock">Jashtë stokut!</span>
            ) : (
              stockQuantity <= 4 && (
                <span className="Product__out-of-stock Product__info-limited">
                  E limituar!
                </span>
              )
            )} */}
          </div>
          {!props.noWishlistButton &&
            (props?.product?.databaseId || props?.product?.id) && (
              <WishListButton
                id={props.product?.databaseId || parseInt(props?.product?.id)}
              />
            )}
        </div>
      </div>
      <div className="Product__content">
        <ThemeLink to={`/product/${props.product?.slug}`}>
          <h4 className="Product__content__title">
            {renderProductFieldsByLanguage(props.product!, 'titleEn', 'name')}
          </h4>
        </ThemeLink>
        {/* {(stockQuantity || props.product?.stock) && (
          <span>{stockQuantity || props.product?.stock} në stok</span>
        )} */}
        {props.gridView === 'ONE' && (
          <div
            dangerouslySetInnerHTML={{
              __html: renderProductFieldsByLanguage(
                props.product!,
                'descriptionEn',
                'description'
              )?.toString(),
            }}
          />
        )}
        <button
          id="addToCardBtn"
          className="btn--addtocart"
          onClick={() => history.push(`/product/${props.product?.slug}`)}
        >
          <div className="d-flex justify-content-center align-items-center">
            Shiko në detaje
          </div>
        </button>
        <button
          id="addToCardBtn"
          className="btn--addtocart btn--addtocart--reverse"
          onClick={handleAddToCart}
          disabled={loading || stockStatus === 0}
        >
          {loading ? (
            <Spinner
              style={{
                color: '#fff',
                width: '1.25rem',
                height: '1.25rem',
                borderWidth: '.2em',
                margin: 'auto',
              }}
            />
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <ShoppingCart
                style={{ marginRight: 8 }}
                fill={stockStatus === 0 ? '#fff' : '#012db6'}
                width={20}
                height={20}
              />{' '}
              Shto në shport
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
