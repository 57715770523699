import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

import { useAuthContext } from "../../context/AuthContext/AuthContext";

import { ProfileAccountFields } from "../../../components/ProfilePages/ProfileAccount/ProfileAccount";

interface UseProfileAccountOptions {
  initialValues?: ProfileAccountFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProfileAccountFields>
  ) => Promise<any>;
}

export const useProfileAccountFormik = (opts: UseProfileAccountOptions) => {
  const authCtx = useAuthContext();

  const ProfileAccountSchema = Yup.object().shape({
    firstName: Yup.string().required("Emri eshte i nevojshem!"),
    lastName: Yup.string().required("Mbiemri eshte i nevojshem!"),
  });

  return useFormik({
    initialValues: {
      email: authCtx.user?.email || "",
      lastName: authCtx.user?.lastName || "",
      username: authCtx.user?.username || "",
      firstName: authCtx.user?.firstName || "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfileAccountSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileAccountFormik = ReturnType<typeof useProfileAccountFormik>;
