import React from "react";
import { formatCategoriesToShopMobileFilterItems } from "../../lib/formatCategoriesToShopMobileFilterItems";
import { ShopMobileFiltersScreen } from "./ShopMobileFiltersScreen";
import {
  MobileFiltersStep,
  useMobileFiltersContext
} from "../../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { useShopFilterData } from "../../../../lib/hooks/useShopFilterData";
import { Icon } from "../../../shared/Icon/Icon";

export const ChooseSubCategory = () => {
  const mobileFiltersCtx = useMobileFiltersContext();
  const shopFilterData = useShopFilterData();

  const subCategories = shopFilterData?.categories.find(
    cat => cat.slug === mobileFiltersCtx.category
  )?.children?.nodes;

  const items = formatCategoriesToShopMobileFilterItems(subCategories || []);

  return (
    <ShopMobileFiltersScreen
      title={mobileFiltersCtx?.category}
      onGoBack={() => {
        mobileFiltersCtx.setStep(
          MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE
        );
      }}
      body={
        <>
          {items.map(item => {
            const isSelected = mobileFiltersCtx.subCategory === item.slug;
            return (
              <li
                key={item.id}
                className="ShopMobileFilters__main_list_item"
                onClick={() => {
                  mobileFiltersCtx.setSubCategory(item.slug);
                }}
              >
                <span>
                  {item.label}
                  {isSelected && <Icon icon="check" />}
                </span>
                {item.children && (
                  <span>
                    <Icon icon="chevron-right" />
                  </span>
                )}
              </li>
            );
          })}
        </>
      }
    />
  );
};
