import React from "react";
import { Spinner } from "reactstrap";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { useShopFilterData } from "../../lib/hooks/useShopFilterData";
import { useShopContext } from "../../lib/context/ShopContext/ShopContext";

import "./ShopBySubSubSubCategories.scss";

export const ShopBySubSubSubCategories = () => {
  const shopCtx = useShopContext();
  const shopHandlers = useShopHandlers();
  const searchParams = useSearchParams();
  const shopFilterData = useShopFilterData();

  const hasOnHot = !!searchParams["tenxehta"];
  const hasNewProducts = !!searchParams["tereja"];
  const hasBrand = !!searchParams["brand"];
  const brand = searchParams["brand"];

  const handleClickSubSubSubCategory = (selectOption: string) => {
    const pathName = selectOption
      ? `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}/${shopCtx?.data?.subCategory}/${shopCtx?.data?.subSubCategory}/${selectOption}`
      : `/${shopFilterData?.redirectLinkPrefix}/${shopCtx?.data?.category}/${shopCtx?.data?.subCategory}/${shopCtx?.data?.subSubCategory}`;
    shopFilterData?.handleCategoryChange(selectOption || "");
    shopHandlers.updatePathname(
      pathName,
      true,
      hasOnHot
        ? "tenxehta=true"
        : undefined || hasNewProducts
        ? "tereja=true"
        : undefined || hasBrand
        ? `brand=${brand}`
        : undefined
    );
  };

  if (shopFilterData?.loading) {
    return <Spinner style={{ marginLeft: 30 }} size="lg" color="#000" />;
  }

  if (shopFilterData?.selectedSubSubSubCAtegory) {
    return <div />;
  }

  return (
    <div className="ShopBySubSubSubCategories">
      <div className="ShopBySubSubSubCategories__wrapper">
        {shopFilterData?.subSubSubCategoryOptions?.subSubSubCategoryOptions?.map(
          (item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleClickSubSubSubCategory(item.value)}
                className="ShopBySubSubSubCategories__wrapper-item"
              >
                {item?.icon && (
                  <div className="ShopBySubSubSubCategories__wrapper-item--img">
                    <img src={item?.icon} alt="category" />
                  </div>
                )}
                <label>{item.label}</label>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};
