import React, { useContext } from "react";

export interface CookiesContextType {
  uuid: string;
  fcmToken: string;
  cookiesEnabled: boolean;
}

const CookiesContextValues: CookiesContextType = {
  uuid: "",
  fcmToken: "",
  cookiesEnabled: false,
};

export const CookiesContext = React.createContext<CookiesContextType>(
  CookiesContextValues
);

export const useCookiesContext = () => useContext(CookiesContext);
