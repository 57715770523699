import React, { PropsWithChildren, useState } from "react";
import { onMessageListener } from "../../../firebase";

import {
  FirebaseNotificationsContext,
  FirebaseNotificationsContextType,
} from "./FirebaseNotificationsContext";

export const FirebaseNotificationsContextProvider = (
  props: PropsWithChildren<Record<string, unknown>>
) => {
  const [notifications, setNotifications] = useState<any>([]);

  onMessageListener()
    .then((payload: { notification: { title: string; body: string } }) => {
      setNotifications([...notifications, payload.notification]);
    })
    .catch((err) => console.log("failed: ", err));

  const resetNotifications = () => {
    setNotifications([]);
  };

  const context: FirebaseNotificationsContextType = {
    notifications,
    resetNotifications,
    notificationsLength: notifications.length,
  };

  return (
    <FirebaseNotificationsContext.Provider value={context}>
      {props.children}
    </FirebaseNotificationsContext.Provider>
  );
};
