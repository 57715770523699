import React, { useState } from "react";
import { useRegisterFormik } from "../../lib/formik/useRegisterFormik";
import { RegisterForm } from "./RegisterForm";
import { Col, FormGroup, Spinner } from "reactstrap";
import { Button } from "../../components/shared/Button/Button";
import { useHistory } from "react-router-dom";

import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { RegisterFormStepTwo } from "./RegisterFormStepTwo";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left-grey.svg";
import { useRegisterSecondStepFormik } from "../../lib/formik/useRegisterSecondStepFormik";
import { useCookiesContext } from "../../lib/context/CookiesContextProvider/CookiesContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import cs from "classnames";
import { register } from "../../api/User";
import { RegisterFormStepThree } from "./RegisterFormStepThree";
import { useRegisterThirdStepFormik } from "../../lib/formik/useRegisterThirdStepFormik";

import "./Register.scss";

export interface RegisterFields {
  registerName: string;
  registerLastName: string;
  registerEmail: string;
  registerEmailConfirm: string;
  registerTelephone: string;
  registerAddress: string;
  registerCity: string;
  registerCountry: string;
  registerBirthday: string;
  registerGender: string;
  registerJobPosition: string;
  registerCompany: string;
}

export interface RegisterFieldsStepTwo {
  registerConfirmPassword: string;
  registerPassword: string;
}

export interface RegisterFieldsStepThree {
  registerIndustry: string;
  registerInterested: string[];
}

export const Register = () => {
  const router = useRouter();
  const cookiesCtx = useCookiesContext();
  const notification = useNotification();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const formik = useRegisterFormik({
    onSubmit: async (values) => {
      if (!values) {
        return;
      }

      setStep(2);
    },
  });

  const formik2 = useRegisterSecondStepFormik({
    onSubmit: async (values) => {
      const data = {
        first_name: formik.values.registerName,
        last_name: formik.values.registerLastName,
        city: formik.values.registerCity,
        address: formik.values.registerAddress,
        phone: formik.values.registerTelephone.toString(),
        email: formik.values.registerEmail,
        email_confirmation: formik.values.registerEmail,
        country: formik.values.registerCountry,
        birthday: formik.values.registerBirthday,
        company: formik.values.registerCompany,
        gender: formik.values.registerGender,
        jobPosition: formik.values.registerJobPosition,
        password: values.registerPassword,
        password_confirmation: values.registerConfirmPassword,
        deviceId: cookiesCtx.uuid,
        industry: formik3.values.registerIndustry,
        interested: formik3.values.registerInterested,
      };

      try {
        const res = await register(data);

        if (!res) {
          return;
        }

        notification.success(
          "Jeni regjistruar me sukses, pranoni emailin per te kompletuar regjistrimin!",
          true
        );

        router.history.push("/login");
      } catch (e) {
        let error: any = e;
        notification.danger(
          error.response?.data?.data?.message || error.response?.data?.message,
          true
        );
      }
    },
  });

  const formik3 = useRegisterThirdStepFormik({
    onSubmit: async (values) => {
      if (!values) {
        return;
      }

      setStep(3);
    },
  });

  const handleSteps = () => {
    if (step > 3) {
      return;
    }

    if (step === 1) {
      formik.submitForm();
      return;
    }

    if (step === 2) {
      formik3.submitForm();
      return;
    }

    if (step === 3) {
      formik2.submitForm();
      return;
    }
  };

  return (
    <div className="Register">
      {isMobile && (
        <div className="Login__wrapper__img">
          <img
            style={{ width: 168, height: 33 }}
            src={require("../../assets/images/profiti-shop.png")}
            alt="logo"
          />
        </div>
      )}
      <div className="Register__steps">
        <div className="Register__steps_wrapper">
          <span>Hapi {step} nga 3</span>
          <div className="Register__steps_wrapper_dots">
            <div
              style={{
                width: 22,
                height: 4,
                backgroundColor: "#1A3F9C",
                borderRadius: 6666,
                marginRight: 10,
              }}
            />
            <div
              style={{
                width: 22,
                height: 4,
                backgroundColor:
                  step === 2 || step === 3 ? "#1A3F9C" : "#D0CECE",
                borderRadius: 6666,
                marginRight: 10,
              }}
            />
            <div
              style={{
                width: 22,
                height: 4,
                backgroundColor: step === 3 ? "#1A3F9C" : "#D0CECE",
                borderRadius: 6666,
              }}
            />
          </div>
        </div>
      </div>
      <div className={cs("Login__wrapper", isMobile && "Login__wrapper-m")}>
        {!isMobile && (
          <div className="Login__wrapper__img">
            <img
              style={{ width: 168, height: 33 }}
              src={require("../../assets/images/profiti-shop.png")}
              alt="logo"
            />
          </div>
        )}
        <h4 style={{ textAlign: "center" }}>Regjistrohu në platformë</h4>
        {step === 1 ? (
          <RegisterForm formik={formik} loading={formik.isSubmitting} />
        ) : step === 2 ? (
          <RegisterFormStepThree
            formik={formik3}
            loading={formik3.isSubmitting}
          />
        ) : (
          <RegisterFormStepTwo
            formik={formik2}
            loading={formik2.isSubmitting}
          />
        )}
        <Col xs="12">
          <FormGroup>
            <Button
              color="primary"
              type="submit"
              disabled={formik.isSubmitting}
              className="w-100-mobile"
              onClick={handleSteps}
            >
              {formik2.isSubmitting ? (
                <Spinner size="sm" color="#fff" />
              ) : (
                "Vazhdo"
              )}
            </Button>
          </FormGroup>
        </Col>

        {(step === 2 || step === 3) && (
          <div className="Login__goBack">
            <ArrowLeft style={{ marginRight: 16 }} />
            <span
              onClick={() => {
                setStep((prevStep) => prevStep - 1);
              }}
            >
              Kthehu një hap mbrapa
            </span>
          </div>
        )}
      </div>
      <div className="Login__footer">
        <span>Keni llogari në plaformë?</span>
        <strong onClick={() => history.push("/login")}>Kyçuni këtu</strong>
      </div>
    </div>
  );
};
