import React, { useContext } from "react";

export interface ShopContextData {
  category?: string;
  subCategory?: string;
  subSubCategory?: string;
  subSubSubCategory?: string;
  shopType?: "category" | "brand";
  shopLinkPrefix?: string;
  order?: string;
  slug?: string;
}

export interface ShopContextHandlers {
  handleCategoryChange?: (category: string) => void;
  handleAttributeChange?: (data: any) => void;
  handleOrderChange?: (value: string) => void;
}

export interface ShopContextType {
  data: ShopContextData;
  handlers?: ShopContextHandlers;
}

const defaultShopContext: ShopContextType = {
  data: {
    category: undefined,
    subCategory: undefined,
    subSubCategory: undefined,
    subSubSubCategory: undefined,
    shopType: undefined,
    shopLinkPrefix: undefined,
    order: undefined,
    slug: undefined,
  },
};

export const ShopContext = React.createContext<ShopContextType>(
  defaultShopContext
);

export function useShopContext() {
  return useContext(ShopContext);
}
