import { createContext, useContext } from "react";

export interface FirebaseNotificationsContextType {
  notifications: [];
  resetNotifications: () => void;
  notificationsLength: number;
}

const FirebaseNotificationsContextValues: FirebaseNotificationsContextType = {
  notifications: [],
  notificationsLength: 0,
  resetNotifications: () => {},
};

export const FirebaseNotificationsContext = createContext<FirebaseNotificationsContextType>(
  FirebaseNotificationsContextValues
);

export const useFirebaseNotificationsContext = () =>
  useContext(FirebaseNotificationsContext);
