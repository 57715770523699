import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

import { fields, formInputs } from "../../../lib/translation/strings";

import { ProfileAddressFormik } from "../../../lib/formik/profile/useProfileAddressFormik";

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressBilling = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <>
      {/* <FormGroup className="form-group">
        <Label for="name">{t(formInputs.name)}</Label>
        <Input
          type="text"
          name="billing.firstName"
          onChange={formik.handleChange}
          value={formik.values.billing.firstName || ""}
          invalid={
            !!formik.errors.billing?.firstName &&
            formik.touched.billing?.firstName
          }
        />
        <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
      </FormGroup>

      <FormGroup className="form-group">
        <Label for="name">{t(formInputs.lastName)}</Label>
        <Input
          type="text"
          name="billing.lastName"
          onChange={formik.handleChange}
          value={formik.values.billing.lastName || ""}
          invalid={
            !!formik.errors.billing?.lastName &&
            formik.touched.billing?.lastName
          }
        />
        <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
      </FormGroup>

      <FormGroup className="form-group">
        <Label for="state">{t(fields.country)}</Label>
        <SelectCountry
          name="state"
          hasError={
            !!formik.errors.billing?.country && formik.touched.billing?.country
          }
          errorMessage={formik.errors.billing?.country}
          onSelect={(country: string) => {
            formik.setFieldValue("billing.country", country);
          }}
          initialValue={formik.values.billing.country}
        />
      </FormGroup> */}
      <FormGroup className="form-group">
        <Label for="name">{t(formInputs.address)}</Label>
        <Input
          type="text"
          name="billing.address1"
          onChange={formik.handleChange}
          placeholder={t(formInputs.address)}
          value={formik.values.billing.address1 || ""}
          invalid={
            !!formik.errors.billing?.address1 &&
            formik.touched.billing?.address1
          }
        />
        <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
      </FormGroup>
      <FormGroup className="form-group">
        <Label for="name">{t(fields.city)}</Label>
        <Input
          type="text"
          name="billing.city"
          onChange={formik.handleChange}
          placeholder={t(fields.city)}
          value={formik.values.billing.city || ""}
          invalid={
            !!formik.errors.billing?.city && formik.touched.billing?.city
          }
        />
        <FormFeedback>{formik.errors.billing?.city}</FormFeedback>
      </FormGroup>

      {/* <FormGroup className="form-group">
        <Label for="name">{t(formInputs.telephoneNumber)}</Label>
        <Input
          type="text"
          name="billing.phone"
          onChange={formik.handleChange}
          value={formik.values.billing.phone || ""}
          invalid={
            !!formik.errors.billing?.phone && formik.touched.billing?.phone
          }
        />
        <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
      </FormGroup> */}
    </>
  );
};
