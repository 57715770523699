import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { useApolloClient } from "react-apollo";
import {
  MobileFiltersContext,
  MobileFiltersStep,
} from "./MobileFiltersContext";
import {
  AttributeFilter,
  ProductTaxonomyEnum,
} from "../../../types/generalTypes";
import { useShopContext } from "../ShopContext/ShopContext";
import * as shopQueries from "../../../queries/shop";
import { formatAttributesToShopMobileFilterItems } from "../../../components/Shop/lib/formatAttributesToShopMobileFilterItems";
import { ShopMobileFiltersItem } from "../../../components/Shop/ShopMobileFilters/ShopMobileFilters";
import { useRouter } from "../../hooks/useRouter";
import queryString from "query-string";
import { getTaxonomyByShopType } from "../../hooks/useShopFilterData";
import { useShopHandlers } from "../../hooks/useShopHandlers";
import { useSearchParams } from "../../hooks/useSearchParams";

interface Props {
  children: React.ReactNode;
}

export const MobileFiltersContextProvider = (props: Props) => {
  const router = useRouter();
  const windowWidth = useWindowWidth();
  const shopCtx = useShopContext();
  const shopHandlers = useShopHandlers();
  const client = useApolloClient();
  const searchParams = useSearchParams();
  const brand = searchParams["brand"];

  const [isOpened, setIsOpened] = useState(false);
  const [step, setStep] = useState<MobileFiltersStep>(
    shopCtx.data?.category
      ? MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE
      : MobileFiltersStep.CHOOSE_CATEGORY
  );

  const [category, setCategory] = useState<string | undefined>(
    shopCtx.data.category
  );
  const [subCategory, setSubCategory] = useState<string | undefined>(
    shopCtx.data.subCategory
  );

  const [subSubCategory, setSubSubCategory] = useState<string | undefined>(
    shopCtx.data.subSubCategory
  );

  const [loadingAttributes, setLoadingAttributes] = useState(false);
  const [attributes, setAttributes] = useState<ShopMobileFiltersItem[] | null>(
    null
  );

  const [tempSelectedAttribute, setTempSelectedAttribute] = useState<
    string | undefined
  >(undefined);

  const getInitialSelectedAttributes = (): ShopMobileFiltersItem[] | null => {
    const initialSelectedValues = queryString.parse(router.location.search);
    const res = Object.keys(initialSelectedValues).map((i) => {
      const innerRes = attributes?.find((j) => j.slug === i);
      const items = innerRes?.children?.filter((f) =>
        initialSelectedValues[i]?.includes(f.slug)
      );
      if (!items) {
        return [] as ShopMobileFiltersItem[];
      }
      return items;
    });
    return res.flat();
  };

  const initialSelectedAttributes = getInitialSelectedAttributes();

  const [selectedAttributes, setSelectedAttributes] = useState<
    ShopMobileFiltersItem[] | null
  >(initialSelectedAttributes);

  // Whenever you open sidebar on mobile, reset selected items from URL.
  useEffect(() => {
    setStep(MobileFiltersStep.CHOOSE_CATEGORY);
    setCategory(shopCtx.data.category);
    setSubCategory(shopCtx.data.subCategory);
    setSubSubCategory(shopCtx.data.subSubCategory);
    setSelectedAttributes(initialSelectedAttributes);
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (windowWidth <= 768 && isOpened) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAttributes(category, subCategory, subSubCategory);
  }, [category, subCategory, router.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, [selectedAttributes]);

  const openFiltersSidebar = () => {
    setIsOpened(true);
  };

  const closeFiltersSidebar = () => {
    setIsOpened(false);
  };

  const toggleFiltersSidebar = () => {
    setIsOpened(!isOpened);
  };

  const clearFilters = () => {
    if (
      step === MobileFiltersStep.CHOOSE_SUBCATEGORY ||
      step === MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE
    ) {
      setSubCategory(undefined);
      setSubSubCategory(undefined);
      setTempSelectedAttribute(undefined);
      setSelectedAttributes([]);
    }
    if (step === MobileFiltersStep.CHOOSE_CATEGORY) {
      setCategory(undefined);
      setSubCategory(undefined);
      setSubSubCategory(undefined);
      setTempSelectedAttribute(undefined);
      setSelectedAttributes(null);
    }

    if (step === MobileFiltersStep.CHOOSE_ATTRIBUTE) {
      setSelectedAttributes(null);
    }
  };

  const fetchAttributes = async (
    cat?: string,
    subCat?: string,
    subSubCategory?: string
  ) => {
    const categoryTerms = {
      taxonomy: ProductTaxonomyEnum.PRODUCTCATEGORY,
      terms: subSubCategory || subCat || cat || "",
    };

    const brandTerms = {
      taxonomy: ProductTaxonomyEnum.PRODUCTBRAND,
      terms: brand as string,
    };

    const taxonomy = getTaxonomyByShopType(
      shopCtx?.data?.shopType
    ) as ProductTaxonomyEnum;

    const initialCategoryTerms = shopCtx.data.slug
      ? [
          categoryTerms,
          {
            taxonomy: taxonomy,
            terms: shopCtx?.data?.slug,
          },
        ]
      : [categoryTerms];

    const initialBrandTerms = brand
      ? [
          brandTerms,
          {
            taxonomy: taxonomy,
            terms: brand as string,
          },
        ]
      : [brandTerms];

    const filteredCategoryTerms = initialCategoryTerms.filter(
      (term) => term.terms !== ""
    );

    const filteredBrandTerms = initialBrandTerms.filter(
      (term) => !term.terms && term.terms !== ""
    );

    const newTaxonomy = shopHandlers.prepareTaxonomyFilters(
      lodash.groupBy(selectedAttributes ?? [], "data.slug")
    );

    try {
      setLoadingAttributes(true);

      const res = await client.query<
        shopQueries.GetAttributeFiltersResponse,
        shopQueries.GetAttributeFiltersVariables
      >({
        query: shopQueries.getAttributeFilters,
        variables: {
          chosenAttributes: [
            ...filteredCategoryTerms,
            ...filteredBrandTerms,
            ...newTaxonomy,
          ],
        },
      });

      const attributes =
        res?.data?.attributeFilters || ([] as AttributeFilter[]);

      setAttributes(formatAttributesToShopMobileFilterItems(attributes));
    } catch (e) {
    } finally {
      setLoadingAttributes(false);
    }
  };

  const getSearchParamsFromSelectedAttributes = () => {
    const groupedAttributes = lodash.groupBy(
      selectedAttributes ?? [],
      "data.slug"
    );
    const keys = Object.keys(groupedAttributes);
    return keys.map(
      (k) => `${k}=${groupedAttributes[k].map((s) => s.slug).join(",")}`
    );
  };

  const showResults = () => {
    const searchParams = getSearchParamsFromSelectedAttributes();

    const newPath = `/shop/${category} ${
      subCategory ? `/${subCategory}` : ""
    } ${subSubCategory ? `/${subSubCategory}` : ""} ${
      searchParams.length ? `?${searchParams.join("&")}` : ""
    }`;

    router.history.push(newPath.replace(/\s+/g, ""));

    closeFiltersSidebar();
  };

  const context: MobileFiltersContext = {
    isOpened,
    step,
    category,
    subCategory,
    subSubCategory,
    setSubSubCategory,
    selectedAttributes,
    attributes,
    loadingAttributes,
    tempSelectedAttribute,
    setCategory,
    setSubCategory,
    closeFiltersSidebar,
    openFiltersSidebar,
    toggleFiltersSidebar,
    setStep,
    clearFilters,
    setTempSelectedAttribute,
    setSelectedAttributes,
    showResults,
  };

  return (
    <MobileFiltersContext.Provider value={context}>
      {props.children}
    </MobileFiltersContext.Provider>
  );
};
