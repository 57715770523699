import { ShopProductCategory } from "../../../queries/shop";
import { ShopMobileFiltersItem } from "../ShopMobileFilters/ShopMobileFilters";

export const formatCategoriesToShopMobileFilterItems = (
  categories: ShopProductCategory[]
) => {
  const formatedArray: ShopMobileFiltersItem[] = categories?.map(cat => {
    return {
      id: cat.id,
      slug: cat.slug,
      label: cat.name,
      data: cat,
      children: cat.children?.nodes
        ? formatCategoriesToShopMobileFilterItems(cat.children?.nodes)
        : null
    };
  });

  return formatedArray;
};
