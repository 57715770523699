import React from "react";
import { useQuery } from "react-apollo";
import { getNewInProducts } from "../../../../queries/home";
import { SlideShow } from "../../../SlideShow/SlideShow";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { Container } from "../../../Container/Container";

import "./NewIn.scss";

interface Props {
  title: string;
}

export const NewIn = (props: Props) => {
  const { title } = props;
  const { loading, error, data } = useQuery(getNewInProducts);
  const products = data?.products?.nodes || [];

  return (
    <div className="NewIn animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <Container>
          <SlideShow
            loading={loading}
            error={error}
            items={products}
            title={title}
            redirectLink="tereja=true"
          />
        </Container>
      </ApolloErrorGuard>
    </div>
  );
};
