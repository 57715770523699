import React, { useContext } from "react";
import { ShopMobileFiltersItem } from "../../../components/Shop/ShopMobileFilters/ShopMobileFilters";

export enum MobileFiltersStep {
  "CHOOSE_CATEGORY" = "CHOOSE_CATEGORY",
  "CHOOSE_SUBCATEGORY_OR_ATTRIBUTE" = "CHOOSE_SUBCATEGORY_OR_ATTRIBUTE",
  "CHOOSE_SUBCATEGORY" = "CHOOSE_SUBCATEGORY",
  "CHOOSE_ATTRIBUTE" = "CHOOSE_ATTRIBUTE",
}
export interface MobileFiltersContext {
  isOpened: boolean;
  step: MobileFiltersStep;

  category?: string;
  subCategory?: string;
  subSubCategory?: string;
  attributes: ShopMobileFiltersItem[] | null;
  loadingAttributes?: boolean;
  selectedAttributes: ShopMobileFiltersItem[] | null;
  tempSelectedAttribute?: string;

  toggleFiltersSidebar: () => void;
  openFiltersSidebar: () => void;
  closeFiltersSidebar: () => void;
  setSelectedAttributes: React.Dispatch<
    React.SetStateAction<ShopMobileFiltersItem[] | null>
  >;
  setCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSubSubCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTempSelectedAttribute: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setStep: (step: MobileFiltersStep) => void;
  clearFilters: () => void;

  showResults: () => void;
}

const defaultMobileFiltersContext: MobileFiltersContext = {
  isOpened: false,
  step: MobileFiltersStep.CHOOSE_ATTRIBUTE,
  category: undefined,
  subCategory: undefined,
  subSubCategory: undefined,
  attributes: null,
  loadingAttributes: false,
  selectedAttributes: null,

  toggleFiltersSidebar: () => {},
  openFiltersSidebar: () => {},
  closeFiltersSidebar: () => {},
  setCategory: () => {},
  setSubCategory: () => {},
  setSubSubCategory: () => {},
  setStep: () => {},
  clearFilters: () => {},
  setTempSelectedAttribute: () => {},
  setSelectedAttributes: () => {},
  showResults: () => {},
};

export const MobileFiltersContext = React.createContext<MobileFiltersContext>(
  defaultMobileFiltersContext
);

export function useMobileFiltersContext() {
  return useContext(MobileFiltersContext);
}
