import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { formValidation } from "../translation/strings";

export interface OrderFeedback {
  message: string;
  review: number;
}

interface UseRegisterFormOptions {
  initialValues?: OrderFeedback;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<OrderFeedback>
  ) => Promise<any>;
}

export const useOrderFeedbackFormik = (options: UseRegisterFormOptions) => {
  const { t } = useTranslation();

  const OrderFeedbackSchema = Yup.object().shape({
    message: Yup.string().required(t(formValidation.message)),
  });

  return useFormik({
    initialValues: {
      message: "",
      review: 0,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: OrderFeedbackSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type OrderFeedbackFormik = ReturnType<typeof useOrderFeedbackFormik>;
