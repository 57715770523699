import React, { ReactNode, useRef } from "react";
import cn from "classnames";

import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";

import { Icon } from "../../components/shared/Icon/Icon";

import "./CartSidebar.scss";
// import { Button } from "../../components/shared/Button/Button";

interface Props {
  sidebar: ReactNode;
}

export const CartSidebar = (props: Props) => {
  const ref = useRef(null);
  const menuSidebar = useMenuSidebar();

  return (
    <div
      ref={ref}
      className={cn("CartSidebar", menuSidebar.cart && "CartSidebar--opened")}
    >
      <div className="CartSidebar-head">
        <p>Filtro & Rendit</p>
        <span role="button" onClick={() => menuSidebar.closeSidebar("cart")}>
          <Icon icon="x" />
        </span>
      </div>
      <div style={{ padding: 30 }}>{props.sidebar}</div>
    </div>
  );
};
