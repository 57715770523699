import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-apollo";
import Slider from "react-slick";
import {
  GET_TYPE_A_CAMPAIGNS,
  CampaignTypeAPost,
} from "../../../queries/campaignsQueries";
import { SlideLoader } from "./SlideLoader";
import { ApolloErrorGuard } from "../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { Icon } from "../../shared/Icon/Icon";
import { Container } from "../../Container/Container";

import "./Slide.scss";

const CustomArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 45,
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "transparent",
        borderRadius: 20,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon
        icon="chevron-right"
        style={{ color: "rgba(238, 238, 238, 0.8)" }}
      />
    </span>
  );
};

const CustomArrowLeft = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <span
      role="button"
      className={`CustomArrow ${className}`}
      style={{
        width: 45,
        height: 45,
        justifyContent: "center",
        alignItems: "center",
        ...style,
        display: "flex !important",
        background: "transparent",
        borderRadius: 20,
        "&:hover": {
          borderColor: "#ff681c",
        },
      }}
      onClick={onClick}
    >
      <Icon icon="chevron-left" style={{ color: "rgba(238, 238, 238, 0.8)" }} />
    </span>
  );
};

const SlideProgressBar = (props: { sliderRef: any }) => {
  const { sliderRef } = props;
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWidth((prevWidth) => {
        if (prevWidth === 100) {
          return 0; // reset width to 0 if it reaches 100%
        }
        return prevWidth + 1;
      });
    }, 90); // 9 seconds divided by 100 steps

    // Set up a listener to detect the end of the slider's autoplay
    sliderRef.current?.innerSlider.on("afterChange", () => {
      clearInterval(interval);
      setWidth(100);
    });

    // Clear interval and reset width when component unmounts
    return () => {
      clearInterval(interval);
      setWidth(0);
    };
  }, []);

  return (
    <div className="Slide__progressbar">
      <Container>
        <div className="Slide__progressbar__wrapper">
          <ProgressBar width={width} />
        </div>
      </Container>
    </div>
  );
};

const ProgressBar = ({ width }: { width: number }) => {
  return <div className="bar" style={{ width: `${width}%` }} />;
};

export const Slide = () => {
  const slider = useQuery(GET_TYPE_A_CAMPAIGNS);
  const sliderRef = useRef<any>(null);

  const sliderData =
    slider.data?.generalOptions?.generalOptions?.homeAds
      ?.bannerSliderCampaigns ?? [];

  const settings = {
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 9000,
    fade: false,
    prevArrow: <CustomArrowLeft />,
    nextArrow: <CustomArrow />,
  };

  return (
    <div className="Slide">
      <HandleLoadingState
        loading={slider.loading}
        loadingPlaceholder={<SlideLoader />}
      >
        <ApolloErrorGuard error={slider.error}>
          <Slider
            pauseOnFocus={false}
            pauseOnDotsHover={false}
            pauseOnHover={false}
            {...settings}
          >
            {sliderData?.map((item: CampaignTypeAPost) => {
              return (
                <div key={item.id} className="Slide__item">
                  <img src={item?.featuredImage?.node?.mediaItemUrl} alt="" />
                  {/* eslint-disable-next-line */}
                  <a
                    href={item?.campaignsOptions?.link}
                    className="Slide__content"
                  />
                </div>
              );
            })}
          </Slider>
          {!slider.loading && <SlideProgressBar sliderRef={sliderRef} />}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
