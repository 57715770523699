import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { createApolloClient } from "./apollo/createApolloClient";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { MenuSidebarContextProvider } from "./lib/context/MenuSidebarContext/MenuSidebarContextProvider";
import { CookiesContextProvider } from "./lib/context/CookiesContextProvider/CookiesContextProvider";

import { App } from "./App";

import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";
import { FirebaseNotificationsContextProvider } from "./lib/context/FirebaseNotificationsContextProvicer/FirebaseNotificationsContextProvider";

const apolloClient = createApolloClient();

const element = (
  <React.Fragment>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <CookiesContextProvider>
          <FirebaseNotificationsContextProvider>
            <AuthContextProvider>
              <MenuSidebarContextProvider>
                <App />
                <ToastContainer
                  position="top-right"
                  limit={5}
                  autoClose={6000}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </MenuSidebarContextProvider>
            </AuthContextProvider>
          </FirebaseNotificationsContextProvider>
        </CookiesContextProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.Fragment>
);

ReactDOM.render(element, document.getElementById("root"));

serviceWorker.unregister();
