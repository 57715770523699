import React from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

import { useAuthContext } from "../lib/context/AuthContext/AuthContext";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";

import { MenuContextNewProvider } from "../lib/context/MenuContextNew/MenuContextProviderNew";
import { Header } from "../layout/Header/Header";
import { MenuResponsive } from "../layout/Header/Menu/MenuResponsive/MenuResponsive";
import { Footer } from "../layout/Footer/Footer";
import { useMenuSidebar } from "../lib/context/MenuSidebarContext/MenuSidebarContext";
import { removeLangFromUrl } from "../lib/helpers/removeLangFromUrl";
import { ThemeRedirect } from "../components/ThemeRedirect/ThemeRedirect";
import { CartContextProvider } from "../lib/context/CartContext/CartContextProvider";

export const PrivateRoute = (props: RouteProps) => {
  const location = useLocation();
  const authCtx = useAuthContext();
  const langCtx = useLanguageContext();
  const menuSidebar = useMenuSidebar();

  const handleOnClick = () => {
    menuSidebar.closeSidebar("wishlist");
    menuSidebar.closeSidebar("cart");
  };

  if (!authCtx.isAuthenticated) {
    const url = removeLangFromUrl(location.pathname);

    return (
      <ThemeRedirect
        to={`${langCtx.getLangPrefix()}/login?redirect=${encodeURIComponent(
          url
        )}`}
      />
    );
  }

  return (
    <>
      <CartContextProvider>
        <div className="App__backdrop" onClick={handleOnClick} />
        <MenuContextNewProvider>
          <Header />
          <MenuResponsive />
        </MenuContextNewProvider>
        <div className="Main_content">
          <Route {...props} />
        </div>
        <Footer />
      </CartContextProvider>
    </>
  );
};
