import React, { useEffect, useRef, useState } from "react";

// import { debounce } from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "react-apollo";
import {
  InputGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { useRouter } from "../../../lib/hooks/useRouter";

import { actions, errors } from "../../../lib/translation/strings";

import { ProductProps } from "../../../types/productTypes";
import * as searchQueries from "../../../queries/search";

import { Icon } from "../../../components/shared/Icon/Icon";
import { Button } from "../../../components/shared/Button/Button";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";

import "./Search.scss";

interface Props {
  className?: string;
}
export const Search = (props: Props) => {
  const ref = useRef(null);
  const router = useRouter();
  const { t } = useTranslation();
  const client = useApolloClient();
  const lngCtx = useLanguageContext();
  const prefix = lngCtx.getLangPrefix();

  const [open, setOpen] = useState(true);
  const [hasMore, setHasMore] = useState<boolean | undefined>(false);
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [products, setProducts] = useState<ProductProps[]>([]);

  useEffect(() => {
    setOpen(false);
  }, [router.location]);

  useOutsideClickDetection(ref, () => setOpen(false));

  const searchProducts = async (value: string) => {
    try {
      const res = await client.query<
        searchQueries.SearchAutocompleteProductsResponse,
        searchQueries.SearchProductVariables
      >({
        query: searchQueries.searchAutocompleteProducts,
        variables: {
          limit: 5,
          term: value,
          after: "",
        },
      });
      setOpen(true);
      setHasMore(res?.data?.products?.pageInfo?.hasNextPage);
      setProducts(res.data.products.nodes);
    } catch (e) {
      console.error(e);
    } finally {
      setSearching(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchValue.length < 3) {
      return;
    }

    router.history.push(`/search?s=${searchValue}`);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value.length === 0) {
      setOpen(false);
      setSearching(false);
      return;
    }

    if (value.length > 2) {
      setSearching(true);
      searchProducts(value);
    }
  };

  return (
    <div
      ref={ref}
      className={classNames(
        "Search flex-fill",
        props.className,
        open && "Search--has-results"
      )}
    >
      <Form className="Search__form" onSubmit={handleSubmit}>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {searching ? (
                <Spinner style={{ width: 24, height: 24 }} color="#012db6" />
              ) : (
                <Icon
                  icon="search"
                  spin={searching}
                  style={{ margin: "0 auto" }}
                />
              )}
            </InputGroupText>
          </InputGroupAddon>
          <Input
            value={searchValue}
            className="Search__input"
            onChange={handleSearchChange}
            placeholder={t(actions.findProduct)}
          />

          <InputGroupAddon addonType="append">
            <button type="submit" className="Search__btn_submit">
              {t(actions.search)}
            </button>
          </InputGroupAddon>
        </InputGroup>
      </Form>

      {open && (
        <div className="Search__results">
          <ul>
            {products.length === 0 ? (
              <li className="text-center">{t(errors.noProductsFound)}</li>
            ) : (
              products.map((product) => (
                <li key={product.id}>
                  <Link to={`${prefix}/product/${product?.slug}`}>
                    <div className="Search__results__item">
                      <div className="d-flex align-items-center justify-flex-start">
                        <span className="Search__results__item__thumb">
                          <img src={product?.image?.mediaItemUrl} alt="" />
                        </span>
                        <span className="Search__results__item__name">
                          {product?.name}
                        </span>
                      </div>
                      {/* <ProductPrices
                        sale={product?.salePrice}
                        regular={product?.regularPrice}
                        onSale={product?.onSale}
                      /> */}
                    </div>
                  </Link>
                </li>
              ))
            )}
            {hasMore && (
              <li className="Search__results__more text-center">
                <Button
                  color="primary"
                  style={{ color: "#fff" }}
                  className="btn-block"
                  onClick={() =>
                    router.history.push(`/search?s=${searchValue}`)
                  }
                >
                  {t(actions.seeAllResults)}
                </Button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
