import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { ProductProps } from "../../types/productTypes";
import { useHistory } from "react-router-dom";

import { useWishlist, WishlistAttributes } from "../../lib/hooks/useWishlist";
import { actions, fields, messages } from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";

import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { HandleNoItemsState } from "../../components/shared/HandleNoItemsState/HandleNoItemsState";
import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";
import { Image } from "../../components/shared/Image/Image";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { Container } from "../../components/Container/Container";

import { ReactComponent as Delete } from "../../assets/icons/delete-cart.svg";
import "./Wishlist.scss";

export const Wishlist = () => {
  const { t } = useTranslation();
  const wishlist = useWishlist();
  const history = useHistory();

  const [state, setState] = useState<WishlistAttributes>({
    loading: true,
    error: undefined,
    data: [],
  });

  React.useEffect(() => {
    wishlist.getUserWishlist().then((res: WishlistAttributes | undefined) => {
      if (res) {
        setState({ ...res });
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRemove = async (id: number) => {
    try {
      await wishlist.removeItem(id);

      setState((prev) => {
        return {
          ...prev,
          data: state.data?.filter((item: any) => item.databaseId !== id),
        };
      });
    } catch (e) {
      console.error(e);
    }
  };

  const path = [{ name: "Lista ime", slug: "" }];

  return (
    <div className="Wishlist">
      <BreadCrumb path={path} shopType="my-list" />
      <div className="Wishlist__wrapper">
        <Container className="p-0-mobile">
          <ApolloErrorGuard error={state.error}>
            <HandleLoadingState
              loading={state.loading}
              loadingPlaceholder={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner size="lg" />
                </div>
              }
            >
              <HandleNoItemsState
                hasItems={state.data.length > 0}
                placeholder={
                  <EmptyPage
                    title={t(fields.myList)}
                    buttonText={t(actions.addProducts)}
                    description={t(messages.emptyList)}
                  />
                }
              >
                <HeadingTitle title="Produktet favorite" />

                <div className="Wishlist__list">
                  {state.data.map((item: ProductProps) => (
                    <div key={item.databaseId} className="CartItem">
                      <div className="CartItem__wrapper">
                        <div className="CartItem__image">
                          {item.image && (
                            <ThemeLink to={`/product/${item.slug}`}>
                              <Image
                                dynamicRef={true}
                                src={item?.image?.mediaItemUrl}
                                srcSet={item?.image.sourceUrl}
                                alt={item.name}
                              />
                            </ThemeLink>
                          )}
                          {/* <WishListButton id={item.product?.databaseId} /> */}
                        </div>
                        <div className="CartItem__content">
                          <div className="CartItem__content_txt">
                            <ThemeLink to={`/product/${item.slug}`}>
                              <h3 className="CartItem__content__title">
                                {item.name}
                              </h3>
                            </ThemeLink>
                            {/* <span>{item.stockQuantity} në stok</span> */}
                          </div>
                          <div className="CartItem__attributes">
                            <button
                              id="addToCardBtn"
                              className="btn--addtocart"
                              onClick={() =>
                                history.push(`/product/${item?.slug}`)
                              }
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                Shiko në detaje
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="CartItem__delete">
                        <div
                          className="CartItem__delete__icon"
                          onClick={() => handleRemove(item.databaseId)}
                        >
                          <Delete />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </HandleNoItemsState>
            </HandleLoadingState>
          </ApolloErrorGuard>
        </Container>
      </div>
    </div>
  );
};
