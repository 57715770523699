import React from "react";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { ThemeLink } from "../ThemeLink/ThemeLink";

interface Props {
  noLink?: boolean;
}

export const Logo = (props: Props) => {
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  return (
    <div className="Logo">
      {props.noLink ? (
        <img
          style={
            isMobile ? { width: 140, height: 50 } : { width: 222, height: 45 }
          }
          src={require("../../assets/images/profiti-shop.png")}
          alt="logo"
        />
      ) : (
        <ThemeLink to="/">
          <img
            style={
              isMobile ? { width: 140, height: 50 } : { width: 222, height: 45 }
            }
            src={require("../../assets/images/profiti-shop.png")}
            alt="logo"
          />
        </ThemeLink>
      )}
    </div>
  );
};
