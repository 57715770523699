import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';
import { useProductSingleContext } from '../../lib/context/ProductSingleContext/ProductSingleContext';
import { ReactComponent as ShoppingCart } from '../../assets/icons/shopping-cart.svg';
import { ReactComponent as World } from '../../assets/icons/world.svg';
import { errors } from '../../lib/translation/strings';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { Icon } from '../shared/Icon/Icon';
import { useWishlist } from '../../lib/hooks/useWishlist';
import { ReactComponent as HeartFill } from '../../assets/icons/heart-fill.svg';
import { Spinner } from 'reactstrap';

import './SingleProductButtons.scss';

export const SingleProductButtons = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const { items, addProduct } = useCartContext();
  const {
    inStock,
    product,
    variation,
    quantity,
    emptySelectedAttributes,
    selectedAttributes,
    attributes,
  } = useProductSingleContext();

  const [loading, setLoading] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(() =>
    product.type === 'VARIABLE' ? 1 : product.stockQuantity
  );

  const {
    isLoading: isLoadingWishlist,
    addToWishlist,
    isProductOnWishList,
    removeItem: removeFromWishlist,
  } = useWishlist();

  const [isFavorite, setIsFavorite] = useState(
    isProductOnWishList(product.databaseId)
  );

  const handleClick = async () => {
    if (isFavorite) {
      await removeFromWishlist(product.databaseId);
      setIsFavorite(false);
      return;
    }
    await addToWishlist(product.databaseId);
    setIsFavorite(true);
  };

  useEffect(() => {
    handleVariationMaxQuantity();
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  const getItemFromCart = () => {
    const cartItem =
      items?.filter(
        (item: any) => item.product.node?.databaseId === product.databaseId
      ) || [];

    if (cartItem.length === 0) {
      return;
    }

    if (variation) {
      return cartItem.find(
        (item: any) => item.variation.node?.id === variation.id
      );
    }

    return cartItem[0] as any;
  };

  const handleVariationMaxQuantity = () => {
    if (product.type !== 'VARIABLE') {
      setMaxQuantity(product.stockQuantity);
      return;
    }

    if (!variation) {
      setMaxQuantity(1);
      return;
    }

    setMaxQuantity(
      variation.stockStatus === 'IN_STOCK' ? variation.stockQuantity || 10 : 1
    );
  };

  const handleAddToCart = async () => {
    const cartItem = getItemFromCart();

    if (!inStock) {
      notification.warning(t(errors.productNotInStock), true); //This product is out of stock.
      return;
    }

    if (product.type === 'VARIABLE' && attributes.length === 0) {
      notification.warning(t(errors.noVariations), true); // No available variations.
      return;
    }

    const ngjyra = selectedAttributes.find((s) => s.attribute === 'pa_ngjyra');

    let isColor = ngjyra?.attribute === 'pa_ngjyra';

    if (product.type === 'VARIABLE' && !variation && isColor) {
      notification.warning(
        'Please select a variation to add it to cart.',
        true
      ); //Please select a variation to add it to cart.
      return;
    }

    if (product.type === 'VARIABLE' && !variation && !isColor) {
      notification.warning(
        'Please select a variation to add it to cart.',
        true
      ); //Please select a variation to add it to cart.
      return;
    }

    if (product.type === 'VARIABLE' && variation && !inStock) {
      notification.warning(t(errors.variationOutOfStock), true); //This variation of the product is out of stock.
      return;
    }

    if (cartItem && cartItem.quantity + quantity > maxQuantity) {
      notification.warning(
        t(errors.cannotAddAmountOfStockToCart, {
          stockQuantity: maxQuantity,
          quantity: cartItem?.quantity,
        }),
        true
      );
      return;
    }

    try {
      setLoading(true);

      await addProduct(
        product.databaseId,
        quantity,
        product.type === 'VARIABLE' ? variation.databaseId : undefined
      );
    } catch (error) {
      let errors: any = error;
      notification.warning(errors.message, true);
    } finally {
      setLoading(false);
    }
  };

  // const isOutOfStock =
  //   product.stockStatus === "OUT_OF_STOCK" || product.stockQuantity === 0;

  return (
    <div className="SingleProduct__content__buttons">
      {selectedAttributes.length > 0 && (
        <div
          onClick={emptySelectedAttributes}
          className="SingleProduct__content__buttons-recycle"
        >
          <Icon icon="recycle" />
          <span>Spastroje</span>
        </div>
      )}

      <div className="d-flex justify-content-between">
        <div className="SingleProduct__content__buttons--buttons">
          <button
            id="addToCardBtn"
            className="btn--addtocart"
            onClick={handleAddToCart}
            disabled={loading}
            // disabled={loading || isOutOfStock}
          >
            {loading ? (
              <Spinner
                style={{
                  color: '#fff',
                  width: '1.25rem',
                  height: '1.25rem',
                  borderWidth: '.2em',
                  margin: 'auto',
                }}
              />
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <ShoppingCart
                  style={{ marginRight: 8 }}
                  fill="white"
                  width={20}
                  height={20}
                />{' '}
                Shto në shport
              </div>
            )}
          </button>
          <button
            className="btn--addtocart btn--wishlist"
            onClick={handleClick}
            disabled={isLoadingWishlist || loading}
          >
            <div className="d-flex justify-content-center align-items-center">
              {isLoadingWishlist ? (
                <Spinner
                  style={{
                    color: '#000',
                    width: '1.25rem',
                    height: '1.25rem',
                    borderWidth: '.2em',
                    margin: 'auto',
                    marginRight: 8,
                  }}
                />
              ) : isFavorite ? (
                <HeartFill width={23} height={22} style={{ marginRight: 8 }} />
              ) : (
                <Icon style={{ marginRight: 8 }} icon="heart" />
              )}
              <span>Shtoje në listë</span>
            </div>
          </button>
        </div>
        {product?.productFields?.externalLink && (
          <div
            onClick={() =>
              (window.location.href = product?.productFields?.externalLink)
            }
            className="SingleProduct__content__buttons--lookMore"
          >
            <World style={{ marginRight: 6 }} />
            <span>Mëso më shumë nga brendi</span>
          </div>
        )}
      </div>
    </div>
  );
};
