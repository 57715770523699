import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Quantity } from '../shared/QuantityInput/Quantity';
import { useProductSingleContext } from '../../lib/context/ProductSingleContext/ProductSingleContext';
import {
  ProductSingleProps,
  ProductSingleVariationProps,
} from '../../queries/archive';

import './ProductSingleVariations.scss';

export const ProductSingleVariations = () => {
  const {
    product,
    variation,
    quantity,
    setQuantity,
  } = useProductSingleContext();
  const [maxQuantity, setMaxQuantity] = useState(() =>
    product.type === 'VARIABLE' ? 1 : product.stockQuantity
  );

  useEffect(() => {
    handleVariationMaxQuantity();
  }, [variation]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVariationMaxQuantity = () => {
    if (product.type !== 'VARIABLE') {
      setMaxQuantity(product.stockQuantity);
      return;
    }

    if (!variation) {
      setMaxQuantity(1);
      return;
    }

    setMaxQuantity(
      variation.stockStatus === 'IN_STOCK' ? variation.stockQuantity || 10 : 1
    );
  };

  // const getStockQuantityCustom = (
  //   filteredVariations: ProductSingleVariationProps[],
  //   product?: ProductSingleProps
  // ) => {
  //   if (product?.type === "SIMPLE") return product.stockQuantity;

  //   return filteredVariations[0]?.stockQuantity;
  // };

  // const stockQuantity = getStockQuantityCustom([variation], product);

  return (
    <div className="SingleProduct__variations_wrapper">
      <div style={{ marginBottom: 15 }}>
        <Quantity
          hideMobile={true}
          quantity={quantity}
          maxQuantity={maxQuantity}
          disableIncrement={quantity === maxQuantity}
          onChange={(quantity: number) => setQuantity(quantity)}
        />
      </div>
      {/* {(variation || product?.type === "SIMPLE") && (
        <span className="SingleProduct__variations_wrapper_stock">
          ({stockQuantity} në stok)
        </span>
      )} */}
    </div>
  );
};

interface ColorProps {
  name: string;
  options: any;
  onClick: (color: string) => void;
}

export const ColorBoxes = (props: ColorProps) => {
  const { t } = useTranslation();
  const { selectedAttributes } = useProductSingleContext();

  const selected =
    selectedAttributes
      ?.filter((s) => s.attribute !== 'pa_madhesia')
      ?.map((s) => ({ value: s.value, label: s.value })) || [];

  useEffect(() => {
    if (props.options.length === 1) {
      props.onClick(props.options[0]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      placeholder={t('Zgjidhni një opsion')}
      className="SingleProduct__variation-select"
      options={props.options.map((i: string) => ({ label: i, value: i }))}
      onChange={(item: any) => {
        props.onClick(item.value || '');
      }}
      value={selected}
    />
  );
};
