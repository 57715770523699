import { useQuery } from "react-apollo";
import {
  ChosenAttributesInput,
  ProductTaxonomyEnum,
} from "../../types/generalTypes";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRouter } from "./useRouter";
import { useSearchParams } from "./useSearchParams";
import { useShopHandlers } from "./useShopHandlers";

import * as shopQueries from "../../queries/shop";

export const useAttributeFiltersQuery = (args?: ChosenAttributesInput) => {
  const router = useRouter();
  const params = useParams<any>();
  const searchParams = useSearchParams();
  const shopHandlers = useShopHandlers();

  const brand = searchParams["brand"];

  const categoryTerms = {
    taxonomy: ProductTaxonomyEnum.PRODUCTCATEGORY,
    terms:
      params.childChildCategory ||
      params.childCategory ||
      params.parentCategory ||
      "",
  };

  const brandTerms = {
    taxonomy: ProductTaxonomyEnum.PRODUCTBRAND,
    terms: brand as string,
  };

  const initialCategoryTerms = args ? [categoryTerms, args] : [categoryTerms];
  const initialBrandTerms = args ? [brandTerms, args] : [brandTerms];

  const filteredCategoryTerms = initialCategoryTerms.filter(
    (term) => term.terms !== ""
  );

  const filteredBrandTerms = initialBrandTerms.filter(
    (term) => !term.terms && term.terms !== ""
  );

  const { loading, error, data, refetch } = useQuery<
    shopQueries.GetAttributeFiltersResponse,
    shopQueries.GetAttributeFiltersVariables
  >(shopQueries.getAttributeFilters, {
    variables: {
      chosenAttributes: [...filteredCategoryTerms, ...filteredBrandTerms],
    },
  });

  // // On parent_category/child_category change, refetch attribute filters
  // useEffect(() => {
  //   refetch({
  //     chosenAttributes: filteredCategoryTerms
  //   });
  // }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  //Refetch attributes on query params change
  useEffect(() => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(searchParams);
    refetch({
      chosenAttributes: [
        ...filteredCategoryTerms,
        ...taxonomyFilters,
      ] as ChosenAttributesInput[],
    });
  }, [router.location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    error,
    data,
    refetch,
  };
};
