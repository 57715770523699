import React from 'react';
import { ApolloErrorGuard } from '../../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { useQuery } from 'react-apollo';
import { getMostWatchedProducts } from '../../../../queries/home';
import { SlideShow } from '../../../SlideShow/SlideShow';
import { Container } from '../../../Container/Container';

import './OnSale.scss';

export const OnSale = () => {
  const getMostWatchedCategories = localStorage.getItem(
    'PROFITISHOP_USER_FAV_CATEGORY'
  );

  const getMostWatchedCategoriesJSON = getMostWatchedCategories
    ? JSON.parse(getMostWatchedCategories)
    : [];

  const sortedCategories = getMostWatchedCategoriesJSON.sort(
    (a: { count: number }, b: { count: number }) => b.count - a.count
  );

  const highestCountProducts = sortedCategories.filter(
    (item: { count: number }) => item.count === sortedCategories[0].count
  );

  const { loading, error, data } = useQuery(getMostWatchedProducts, {
    variables: {
      category: highestCountProducts[0]?.categorySlug,
    },
  });

  const products = data?.products?.nodes || [];

  if (products.length === 0) {
    return null;
  }

  return (
    <div className="OnSale animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <Container>
          <SlideShow
            loading={loading}
            error={error}
            items={products}
            title={highestCountProducts[0]?.categorySlug.split('-').join(' ')}
            redirectLink="meteshikuarat=true"
          />
        </Container>
      </ApolloErrorGuard>
    </div>
  );
};
