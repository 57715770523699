import React, { useState, useEffect } from "react";

import { ReactComponent as ArrowToTop } from "../../assets/icons/arrow-to-top.svg";

import "./ScrollToTop.scss";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const toggleVisibility = () => {
    if (window.scrollY > 400) {
      setShowTopBtn(true);
      return;
    }
    setShowTopBtn(false);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!showTopBtn) {
    return null;
  }

  return (
    <div className="ScrollToTop">
      <div onClick={goToTop} className="ScrollToTop__button">
        <ArrowToTop fill="#fff" color="#fff" style={{ marginTop: 2 }} />
      </div>
    </div>
  );
};
export default ScrollToTop;
