import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-apollo";
import _ from "lodash";
import * as queryMenu from "../../../queries/shop";

import { useRouter } from "../../hooks/useRouter";

import { MenuContextNew, MenuContextNewType } from "./MenuContextNew";

interface MenuContextNewProviderProps {
  children: React.ReactNode;
}

export const MenuContextNewProvider = (props: MenuContextNewProviderProps) => {
  const { pathname } = useRouter();
  const desktopMenuRef = useRef(null);
  const [desktopLevels, setDesktopLevels] = useState({});
  const [visibleDesktopMenu, setVisibleDesktopMenu] = useState(false);
  const [visibleMobileMenu, setVisibleMobileMenu] = useState(false);
  const [mobileActiveLevel, setMobileActiveLevel] = useState("none");
  // const [visibleDesktopMenu, setVisibleDesktopMenu] = useState(true);
  const [infoBar] = useState({
    data: [],
    loading: false,
    error: undefined,
  });

  // useOutsideClickDetection(desktopMenuRef, (target) => {
  //   if (target && target.id === "mainMenuToggler") {
  //     return;
  //   }

  //   closeDesktopMenu();
  // });

  useEffect(() => {
    closeMobileMenu();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const { loading, error, data } = useQuery<
    queryMenu.GetShopProductCategoriesResponse,
    queryMenu.GetShopProductCategoriesVariables
  >(queryMenu.getProductCategories, {
    variables: {
      customTaxonomy: [],
    },
  });

  const updateActiveLevels = (level: number, id: string) => {
    /*
      Get only level keys that are above the current one, in order to close lower levels.

      E.g: updateActiveLevels(3,[id]) => gets the levels that are less than level 3,
      level 4 menu is removed and level 3 is overwritten.
    */
    if (!visibleDesktopMenu) {
      setVisibleDesktopMenu(true);
    }

    setDesktopLevels((prev) => {
      const aboveLevels = _.pickBy(prev, (value, key) => {
        return key < level.toString();
      });

      return {
        ...aboveLevels,
        [level]: id,
      };
    });
  };

  const handleDesktopToggle = () => {
    // setVisibleDesktopMenu((prev) => !prev);
    setDesktopLevels({});
  };

  const handleMobileToggle = () => {
    // if (mobileActiveLevel !== "none") {
    //   setVisibleMobileMenu(false);
    //   setMobileActiveLevel("none");
    //   return;
    // }

    setVisibleMobileMenu((prev) => !prev);
  };

  const closeDesktopMenu = () => {
    // setVisibleDesktopMenu(false);
    setDesktopLevels({});
    setVisibleDesktopMenu(false);
  };

  const closeMobileMenu = () => {
    // if (!visibleMobileMenu && mobileActiveLevel !== "none") {
    //   return;
    // }

    setVisibleMobileMenu(false);
    // setMobileActiveLevel("none");
  };

  const openMobileMenu = () => {
    setVisibleMobileMenu(true);
    setMobileActiveLevel("first");
  };

  const context: MenuContextNewType = {
    desktop: {
      menuRef: desktopMenuRef,
      closeMenu: closeDesktopMenu,
      // visibleMenu: visibleDesktopMenu,
      activeLevels: desktopLevels,
      updateActiveLevels,
      toggleMenu: handleDesktopToggle,
      visibleDesktopMenu,
    },
    mobile: {
      openMenu: openMobileMenu,
      closeMenu: closeMobileMenu,
      visibleMenu: visibleMobileMenu,
      toggleMenu: handleMobileToggle,
      activeLevel: mobileActiveLevel,
      setActiveLevel: setMobileActiveLevel,
    },
    loading,
    error: error,
    infoBar,
    menu: data?.productCategories.nodes,
  };

  return (
    <MenuContextNew.Provider value={context}>
      {props.children}
    </MenuContextNew.Provider>
  );
};
