import React from "react";
import { ApolloErrorGuard } from "../../../shared/ApolloErrorGuard/ApolloErrorGuard";
import { useQuery } from "react-apollo";
import { GET_HOT_SALES_PRODUCTS } from "../../../../queries/home";
import { SlideShow } from "../../../SlideShow/SlideShow";
import { Container } from "../../../Container/Container";

import "./TopSale.scss";

export const TopSale = () => {
  const { loading, error, data } = useQuery(GET_HOT_SALES_PRODUCTS);

  const products = data?.hotSales || [];

  if (products.length === 0) {
    return null;
  }

  return (
    <div className="TopSale animate__animated animate__fadeIn">
      <ApolloErrorGuard error={error}>
        <Container>
          <SlideShow
            loading={loading}
            error={error}
            items={products}
            title="Shitjet e nxehta"
            redirectLink="tenxehta=true"
          />
        </Container>
      </ApolloErrorGuard>
    </div>
  );
};
