import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RegisterFields } from "../../pages/Register/Register";
import { formValidation } from "../translation/strings";

interface UseRegisterFormOptions {
  initialValues?: RegisterFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<RegisterFields>
  ) => Promise<any>;
}

export const useRegisterFormik = (options: UseRegisterFormOptions) => {
  const { t } = useTranslation();

  const RegisterSchema = Yup.object().shape({
    registerName: Yup.string().required(t(formValidation.firstName)),
    registerLastName: Yup.string().required(t(formValidation.lastName)),
    registerCity: Yup.string().required(t(formValidation.city)),
    registerCountry: Yup.string().required(t(formValidation.country)),
    registerAddress: Yup.string().required(t(formValidation.address)),
    registerTelephone: Yup.number()
      .typeError("Numri i telefonit duhet te permbajë numra!")
      .required(t(formValidation.phone)),
    registerEmail: Yup.string().required(t(formValidation.email)),
    registerEmailConfirm: Yup.string().required(t(formValidation.confirmEmail)),
    registerBirthday: Yup.string().required("Data e lindjes është e nevojshme"),
    // registerVAT: Yup.string().required("Tax/VAT numri është i nevojshëm"),
    registerGender: Yup.string().required("Gjinia është e nevojshme"),

    registerJobPosition: Yup.string().required(
      "Tituli i pozitës është i nevojshëm"
    ),
    registerCompany: Yup.string().required("Kompania është e nevojshme"),
    // registerZipCode: Yup.string().required("Zip kodi është i nevojshëm"),
  });

  return useFormik({
    initialValues: {
      registerName: "",
      registerLastName: "",
      registerEmail: "",
      registerEmailConfirm: "",
      registerTelephone: "",
      registerAddress: "",
      registerCity: "",
      registerCountry: "",
      registerBirthday: "",
      // registerVAT: "",
      registerGender: "",
      registerJobPosition: "",
      registerCompany: "",
      // registerZipCode: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterFormik = ReturnType<typeof useRegisterFormik>;
