import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import Rate from "rc-rate";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Spinner,
  Container,
  Row,
  FormFeedback,
  Input,
  Form,
  Label,
} from "reactstrap";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { useFeedbackFormik } from "../../lib/formik/useFeedback";
import { FEEDBACK } from "../../queries/mutations/generalMutation";
import {
  errors,
  formMessages,
  formValidation,
} from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import { GET_FEEDBACK_OPTIONS } from "../../queries/generalQueries";

import "rc-rate/assets/index.css";
import "./FeedbackModal.scss";

interface Props {
  open: boolean;
  toggle: () => void;
}

export const FeedbackModal = (props: Props) => {
  const { t } = useTranslation();
  const ntfCtx = useNotification();
  const { open, toggle } = props;
  const { data } = useQuery(GET_FEEDBACK_OPTIONS);

  const [mutation, { loading }] = useMutation(FEEDBACK);

  const formik = useFeedbackFormik({
    onSubmit: async (values) => {
      try {
        const res = await mutation({
          variables: {
            feedback: values.feedback,
            message: values.message,
            review: values.review,
          },
        });

        if (!res) {
          return;
        }
        props.toggle();
        ntfCtx.success(t(formMessages.thanksForComment), true);
      } catch (error) {
        ntfCtx.danger(t(errors.wentWrong), true);
      }
    },
  });

  const feedbackOptions =
    data?.generalOptions?.generalOptions?.feedbackForm?.feedbackOptions ?? [];

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalBody>
        <Container>
          <div className="FeedbackModal">
            <h1>Vlersoni platformë tonë me një koment</h1>
            <Form style={{ marginBottom: 30 }} onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs="12" sm="12">
                  <FormGroup>
                    <Input
                      disabled={loading}
                      type="select"
                      placeholder="Lloji i punes"
                      id="feedback"
                      invalid={
                        !!formik.errors.feedback && formik.touched.feedback
                      }
                      onChange={formik.handleChange}
                      value={formik.values.feedback}
                      className="FeedbackModal__select form-control form-control-custom"
                    >
                      <option value="">
                        {loading
                          ? "Ju lutem prisni..."
                          : "Zgjidh llojin e feedback-it"}
                      </option>
                      {feedbackOptions?.map((item: { title: string }) => (
                        <option
                          key={`${item.title}-register-city`}
                          value={item.title}
                        >
                          {item.title}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>{formik.errors.feedback}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs="12" sm="12">
                  <div className="FeedbackModal__rate">
                    <Label>Vlersimi nga 1 deri në 5</Label>
                    <Rate
                      style={{ fontSize: 30 }}
                      allowHalf={false}
                      onChange={(value: number) =>
                        formik.setFieldValue("review", value)
                      }
                    />
                    {formik.values.review === 0 && formik.touched.review && (
                      <span className="FeedbackModal__rate_error">
                        {t(formValidation.required)}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12">
                  <FormGroup className="form-group">
                    <Label for="message">Pëshkrimi</Label>
                    <Input
                      type="textarea"
                      id="message"
                      placeholder="Pëshkrimi"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      invalid={
                        !!formik.errors.message && formik.touched.message
                      }
                    />
                    <FormFeedback>{formik.errors.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <div className="w-100" />
                <Col xs="12">
                  <FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={loading || formik.values.review === 0}
                      className="w-100-mobile FeedbackModal__button"
                    >
                      {loading ? <Spinner size="sm" /> : "Komento"}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <div onClick={props.toggle} className="FeedbackModal__close">
              <span>X</span>
            </div>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};
