import React, { useState } from "react";
import cs from "classnames";
import { useQuery } from "react-apollo";
import { Col, Row, Spinner } from "reactstrap";
import { useRouter } from "../../lib/hooks/useRouter";
import { Container } from "../../components/Container/Container";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { getCourses, getCoursesCategories } from "../../queries/home";
import { Select } from "../../components/GeneralFilters/SortSelect/Select";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-bisht.svg";
import {
  getBrands,
  GetBrandsResponse,
  GetBrandsVariables,
} from "../../queries/brands";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";

import "./HowTo.scss";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

export interface CoursesResponse {
  id: string;
  databaseId: number;
  content: string;
  featuredImage: {
    node: {
      id: string;
      mediaItemUrl: string;
      slug: string;
    };
  };
  slug: string;
  title: string;

  document: {
    link: string;
    id: string;
  };
  productBrands: {
    nodes: {
      name: string;
      id: string;
    };
  };
  courseVideo: {
    video: string;
  };
}

export interface CoursesCategories {
  courseCategories: {
    nodes: CoursesCategory[];
  };
}

interface CoursesCategory {
  id: string;
  name: string;
  slug: string;
  databaseId: number;
}

interface Props {
  courseCategoryId: string;
}

export const HowTo = (props: RouteComponentProps<Props>) => {
  const history = useHistory();
  const { courseCategoryId } = props.match.params;
  const [active, setActive] = useState(courseCategoryId || "");
  const [activeBrand, setActiveBrand] = useState("");
  const router = useRouter();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const {
    data: courseCategories,
    loading: courseCategoriesLoading,
    error: courseCategoriesError,
  } = useQuery(getCoursesCategories);

  const { data, loading, error, refetch } = useQuery(getCourses, {
    variables: {
      courseCategoryId,
      productBrandId: null,
    },
  });

  const { data: brandData } = useQuery<GetBrandsResponse, GetBrandsVariables>(
    getBrands,
    {
      variables: {
        limit: 1000,
        after: "",
        search: "",
        firstLetter: "",
      },
    }
  );

  const brands = brandData?.productBrands?.nodes ?? [];

  const options = brands?.map((brand) => ({
    value: brand.databaseId,
    label: brand.slug?.toUpperCase(),
  }));

  const courses = isMobile
    ? data?.courses?.nodes ?? []
    : data?.courses?.nodes?.slice(1) ?? [];

  const courseOne = data?.courses?.nodes[0];

  const coursesCategories = courseCategories?.courseCategories?.nodes ?? [];

  const categoriesOptions = coursesCategories?.map((category: any) => ({
    value: category.slug,
    label: category.name,
  }));

  const changeCategory = (slug: string, id: number) => {
    setActive(slug);

    const pathname = id ? `/help/${id}` : `/help`;

    router.history.push({
      pathname: pathname,
    });
  };

  const changeCategoryMobile = (e: string) => {
    setActive(e);

    const pathname = e ? `/help/${e}` : `/help`;

    router.history.push({
      pathname: pathname,
    });
  };

  const changeBrand = (e: string) => {
    setActiveBrand(e);

    refetch({
      courseCategoryId,
      productBrandId: e.length === 0 ? null : (e as any),
    });

    // const pathname = e.target.value && courseCategoryId
    //   ? `/help/${courseCategoryId}/${e.target.value}`
    //   : !courseCategoryId && e.target.value
    //   ? `/help/${e.target.value}`
    //   : `/help`;

    // router.history.push({
    //   pathname: pathname,
    // });
  };

  if (loading || courseCategoriesLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <Spinner color="#000" size="lg" />
      </div>
    );
  }

  if (error || courseCategoriesError) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 100,
        }}
      >
        <span>Diçka shkoj keq, ju lutemi provoni përsëri!</span>
      </div>
    );
  }

  const path = [{ name: "Akademi", slug: "" }];

  return (
    <div className="HowTo">
      <Container>
        <BreadCrumb path={path} shopType="help" />

        <div className="HowTo__wrapper">
          <Row>
            <Col md="3" lg="3">
              <div className="HowTo__sidebar">
                {isMobile ? (
                  <div className="HowTo__sidebar__filter">
                    <label>Kategoritë: </label>
                    <Select
                      value={active || ""}
                      loading={loading}
                      name="order-brands"
                      options={[
                        {
                          value: null as any,
                          label: "Zgjedhë kategorinë",
                          isPlaceholder: true,
                        },
                        ...categoriesOptions,
                      ]}
                      onChange={(e) => changeCategoryMobile(e as string)}
                    />
                  </div>
                ) : (
                  <>
                    <label>Kategoritë</label>
                    <div className="HowTo__sidebar__categories">
                      {coursesCategories?.map((item: CoursesCategory) => {
                        return (
                          <div
                            key={item.id}
                            onClick={() =>
                              changeCategory(item.slug, item.databaseId)
                            }
                            className={cs(
                              "HowTo__sidebar__categories_category",
                              active === item.slug &&
                                "HowTo__sidebar__categories_category_active"
                            )}
                          >
                            <span>{item.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
                <div className="HowTo__sidebar__filter">
                  <label>Filtro sipas: </label>
                  <Select
                    value={activeBrand || ""}
                    loading={loading}
                    name="order-brands"
                    options={[
                      {
                        value: null as any,
                        label: "Brendeve",
                        isPlaceholder: true,
                      },
                      ...options,
                    ]}
                    onChange={(e) => changeBrand(e as string)}
                  />
                </div>
              </div>
            </Col>
            <Col md="9" lg="9">
              <div className="HowTo__content">
                {!isMobile && (
                  <div
                    onClick={() =>
                      history.push(`/single-help/${courseOne?.id}`)
                    }
                    className="HowTo__content_big_card"
                  >
                    <div className="HowTo__content_big_card_img">
                      <img
                        alt=""
                        src={courseOne?.featuredImage?.node?.mediaItemUrl}
                      />
                    </div>
                    <div className="HowTo__content_big_card_desc">
                      {courseOne?.courseVideo?.video && (
                        <div className="HowTo__content_big_card_desc_label">
                          <div className="HowTo__content_big_card_desc_label_video">
                            <span>Video</span>
                          </div>
                          <div className="HowTo__content_big_card_desc_label_minutes">
                            <span>8 min për të shikuar</span>
                          </div>
                        </div>
                      )}
                      <div className="HowTo__content_big_card_desc">
                        <h1>{courseOne?.title}</h1>
                        <p>{courseOne?.content}</p>
                        <div className="HowTo__content_big_card_desc_arrow">
                          <ArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="HowTo__content_cards">
                  {courses?.map((item: CoursesResponse) => {
                    return (
                      <div
                        onClick={() =>
                          history.push(`/single-help/${courseOne?.id}`)
                        }
                        key={item.slug}
                        className="HowTo__content_small_card"
                      >
                        <div className="HowTo__content_small_card_img">
                          <img
                            alt=""
                            src={item.featuredImage?.node?.mediaItemUrl}
                          />
                        </div>
                        <div className="HowTo__content_small_card_desc">
                          {item.courseVideo?.video && (
                            <div className="HowTo__content_small_card_desc_label">
                              <div className="HowTo__content_small_card_desc_label_video">
                                <span>Video</span>
                              </div>
                              <div className="HowTo__content_small_card_desc_label_minutes">
                                <span>8 min për të shikuar</span>
                              </div>
                            </div>
                          )}
                          <div className="HowTo__content_small_card_desc">
                            <div className="HowTo__content_small_card_desc_title">
                              <h1>{item.title}</h1>
                              <div className="HowTo__content_small_card_desc_arrow">
                                <ArrowRight />
                              </div>
                            </div>
                            <p>{item?.content}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
