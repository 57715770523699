import React from "react";
import { useTranslation } from "react-i18next";

import { ShopMobileFiltersScreen } from "./ShopMobileFiltersScreen";
import {
  MobileFiltersStep,
  useMobileFiltersContext
} from "../../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { BulkLoader } from "../../../shared/BulkLoader/BulkLoader";
import { MobileAttributeItemPlaceholder } from "./MobileAttributeItemPlaceholder";
import { fields } from "../../../../lib/translation/strings";
import { Icon } from "../../../shared/Icon/Icon";

export const ChooseSubCategoryOrAttribute = () => {
  const { t } = useTranslation();
  const mobileFiltersCtx = useMobileFiltersContext();

  return (
    <ShopMobileFiltersScreen
      title={mobileFiltersCtx?.category}
      onGoBack={() => {
        mobileFiltersCtx.setStep(MobileFiltersStep.CHOOSE_CATEGORY);
      }}
      body={
        <>
          <li
            className="ShopMobileFilters__main_list_item"
            onClick={() => {
              mobileFiltersCtx.setStep(MobileFiltersStep.CHOOSE_SUBCATEGORY);
            }}
          >
            <span>
              {t(fields.productType)}
              {!!mobileFiltersCtx.subCategory && <Icon icon="check" />}
            </span>
            <span>
              <Icon icon="chevron-right" />
            </span>
          </li>
          <HandleLoadingState
            loading={mobileFiltersCtx.loadingAttributes}
            loadingPlaceholder={
              <li style={{ display: "flex", flexDirection: "column" }}>
                <BulkLoader
                  length={2}
                  component={MobileAttributeItemPlaceholder}
                />
              </li>
            }
          >
            {mobileFiltersCtx?.attributes?.map(item => {
              const isSelected = !!mobileFiltersCtx.selectedAttributes?.find(
                attr => attr?.data?.slug === item.slug
              );

              return (
                <li
                  key={item.id}
                  className="ShopMobileFilters__main_list_item"
                  onClick={() => {
                    mobileFiltersCtx.setTempSelectedAttribute(item.slug);
                    mobileFiltersCtx.setStep(
                      MobileFiltersStep.CHOOSE_ATTRIBUTE
                    );
                  }}
                >
                  <span>
                    {item.label}
                    {isSelected && <Icon icon="check" />}
                  </span>
                  {item.children && (
                    <span>
                      <Icon icon="chevron-right" />
                    </span>
                  )}
                </li>
              );
            })}
          </HandleLoadingState>
        </>
      }
    />
  );
};
