import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { ReactComponent as Notification } from "../../assets/icons/notifictaion.svg";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { useFirebaseNotificationsContext } from "../../lib/context/FirebaseNotificationsContextProvicer/FirebaseNotificationsContext";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import { getNotifications, markAsReadNotification } from "../../queries/home";

import "./NotificationsHeader.scss";

export interface NotificationProps {
  id: string;
  link: string;
  slug: string;
  title: string;
  content: string;
  notificationData: {
    orderId: string;
    thumbnailUrl: string;
  };
}

export const NotificationsHeader = () => {
  const ref = useRef(null);
  const authCtx = useAuthContext();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const notificationsCtx = useFirebaseNotificationsContext();

  const { data, loading, error, refetch } = useQuery(getNotifications, {
    variables: {
      userId: authCtx?.user?.userId?.toString(),
    },
  });

  const [mutation] = useMutation(markAsReadNotification);

  const unreadNotifications = data?.notifications?.nodes?.filter(
    (notification: { notificationData: { read: boolean } }) =>
      !notification.notificationData?.read
  );

  const unreadCount = unreadNotifications?.length;

  React.useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (open) {
      document.body.style.overflowY = "scroll";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    };
  }, [open]); // eslint-disable-line

  useOutsideClickDetection(ref, () => setOpen(false));

  useEffect(() => {
    if (notificationsCtx.notifications.length) {
      refetch();
    }
  }, [notificationsCtx.notifications, refetch]);

  const notifications = data?.notifications?.nodes ?? [];

  const handleOpenNotificationPopUp = async () => {
    if (open || !unreadCount) {
      setOpen(!open);
      return;
    }

    setOpen(!open);

    try {
      await mutation({
        variables: {
          userId: authCtx?.user?.userId?.toString(),
        },
        awaitRefetchQueries: true,
        refetchQueries: ["getNotifications", "notifications"],
      });
      notificationsCtx.resetNotifications();
    } catch (error) {
      setOpen(false);
    }
  };

  if (error) {
    return null;
  }

  return (
    <>
      {open && <div className="Overlay" />}
      <div
        ref={ref}
        className="UNavigation__item UNavigation__item--login NotificationsHeader "
      >
        <span
          onClick={handleOpenNotificationPopUp}
          className="UNavigation__item--wishlist-icon"
        >
          <Notification />
          {unreadCount && unreadCount > 0 ? (
            <div className="UNavigation__item--wishlist-icon-number">
              {unreadCount}
            </div>
          ) : null}
        </span>

        {open && (
          <div className="NotificationsHeader__dropdown">
            <h1>Notifikimet</h1>
            <div className="NotificationsHeader__dropdown_items">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 100,
                  }}
                >
                  <Spinner color="#000" size="lg" />
                </div>
              ) : notifications.length === 0 &&
                notificationsCtx.notifications?.length === 0 ? (
                <p>Ju skeni ende ndonjë notifikim!</p>
              ) : (
                <>
                  {notifications.map(
                    (item: NotificationProps, index: number) => {
                      const handleNavigate = () => {
                        if (item.notificationData?.orderId) {
                          history.push(
                            `/my-profile/orders/${item?.notificationData?.orderId}`
                          );
                          return;
                        }
                        window.location.href = item.link;
                      };

                      return (
                        <div
                          onClick={handleNavigate}
                          key={index}
                          className="NotificationsHeader__dropdown_item"
                        >
                          <div className="NotificationsHeader__dropdown_item_img">
                            <img
                              style={{
                                width: 46,
                                height: 46,
                                objectFit: "contain",
                              }}
                              src={item?.notificationData?.thumbnailUrl}
                              alt="logo"
                            />
                          </div>
                          <div className="NotificationsHeader__dropdown_item_desc">
                            <span>{item.title}</span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
