import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { actions } from "../../lib/translation/strings";
import { Container } from "../Container/Container";
import { ThemeLink } from "../shared/ThemeLink/ThemeLink";

import "./BreadCrumb.scss";

interface BreadCrumbProps {
  shopType?: string;
  path?: { name?: string; slug?: string }[];
  singlePage?: boolean;
  physicalStore?: boolean;
}

export const BreadCrumb = (props: BreadCrumbProps) => {
  const { t } = useTranslation();
  const searchParams = useSearchParams();

  const hasBrand = !!searchParams["brand"];
  const brand = searchParams["brand"];

  return (
    <div className="BreadCrumb">
      <Container>
        <div className="BreadCrumb__wrapper">
          <ThemeLink to="/" className="BreadCrumb__home">
            {t(actions.home)}
          </ThemeLink>
          <ul className="BreadCrumb__list">
            {props.path?.map((item, index) => {
              let isProductSingle =
                props.singlePage &&
                props.path &&
                props.path?.length - 1 === index;

              return (
                item.name !== "" && (
                  <li key={`${item?.slug}-${index}`}>
                    <ThemeLink
                      to={`/${
                        props.physicalStore
                          ? "physical-stores"
                          : isProductSingle
                          ? "product"
                          : props.shopType
                      }/${item?.slug}${hasBrand ? `?brand=${brand}` : ""}`}
                    >
                      {item.name}
                    </ThemeLink>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
};
