import React, { useState } from "react";
import cs from "classnames";
import { Spinner } from "reactstrap";

import "./Select.scss";

export interface SortSelectOption {
  value: string | number;
  label: string;
  isPlaceholder?: boolean;
}

interface Props {
  onChange: (selectedValue: string | string[]) => void;
  options: SortSelectOption[];
  value?: string | string[];
  name: string;
  className?: string;
  loading?: boolean;
  isInvalid?: boolean;
  multiselect?: boolean;
}

export const Select = (props: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    Array.isArray(props.value) ? props.value : [props.value || ""]
  );
  const placeholderOption = props.options.find((o) => o.isPlaceholder);

  const options = props.options
    ? props.options.filter((o) => !o.isPlaceholder)
    : [];

  const handleOptionSelect = (value: string) => {
    let selected;
    if (props.multiselect) {
      if (selectedValues.includes(value)) {
        selected = selectedValues.filter((v) => v !== value); // Remove value if already selected
      } else {
        selected = [...selectedValues, value]; // Add value if not selected
      }
    } else {
      selected = [value]; // Select only the clicked value
    }

    setSelectedValues(selected);
    props.onChange(props.multiselect ? selected : selected[0]); // Call the onChange prop with selected value or values
  };

  const getSelectedValuesText = () => {
    if (props.multiselect) {
      const selectedLabels = options
        .filter((option) => selectedValues.includes(option.value.toString()))
        .map((option) => option.label);
      return selectedLabels.join(","); // Return labels of selected values joined by comma
    } else {
      const selectedOption = options.find(
        (option) => option.value === selectedValues[0]
      );
      return selectedOption ? selectedOption.label : ""; // Return label of the single selected value or an empty string
    }
  };

  return (
    <div className={cs("Select", props.className)}>
      {props.loading && (
        <div className="Select__loading">
          <Spinner size="sm" color="secondary" className="Spinner" />
        </div>
      )}
      <select
        className={cs(
          "form-control form-control-custom",
          props.isInvalid && "is-invalid"
        )}
        value={selectedValues} // Use selectedValues array as the value
        name={props.name}
        onChange={(e) => handleOptionSelect(e.target.value)}
      >
        {placeholderOption && (
          <option value="">
            {!props.multiselect
              ? placeholderOption.label
              : getSelectedValuesText()}
          </option>
        )}
        {options.map((o, i) => (
          <option key={`${o.value}-${i}`} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    </div>
  );
};
