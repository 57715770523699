import React from "react";
import { useTranslation } from "react-i18next";

import { useMobileFiltersContext } from "../../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { BlockButton } from "../../../shared/BlockButton/BlockButton";
import { actions } from "../../../../lib/translation/strings";
import { Icon } from "../../../shared/Icon/Icon";

interface ShopMobileFiltersScreenProps {
  title?: string;
  body?: React.ReactNode;
  onGoBack: () => void;
}

export const ShopMobileFiltersScreen = (
  props: ShopMobileFiltersScreenProps
) => {
  const { t } = useTranslation();
  const mobileFiltersCtx = useMobileFiltersContext();

  return (
    <div className="ShopMobileFiltersScreen">
      <div className="ShopMobileFilters__top">
        <button
          className="ShopMobileFilters__top_back"
          onClick={props.onGoBack}
        >
          <Icon icon="chevron-left" />
        </button>
        <div className="ShopMobileFilters__top_title">
          {props.title || t(actions.filter)}
        </div>
        <span
          role="button"
          className="ShopMobileFilters__top_clear"
          onClick={mobileFiltersCtx.clearFilters}
        >
          {t(actions.clean)}
        </span>
      </div>
      <div className="ShopMobileFilters__main">
        <ul className="ShopMobileFilters__main_list">{props.body}</ul>
      </div>
      <div className="ShopMobileFilters__bottom">
        <BlockButton
          fill
          className="m-auto w-100"
          onClick={mobileFiltersCtx.showResults}
        >
          {t(actions.show)}
        </BlockButton>
      </div>
    </div>
  );
};
