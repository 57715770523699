import { ShopMobileFiltersItem } from "../ShopMobileFilters/ShopMobileFilters";
import { AttributeFilter } from "../../../types/generalTypes";
import React from "react";

export const formatAttributesToShopMobileFilterItems = (
  attributes: AttributeFilter[]
) => {
  const formatedArray: ShopMobileFiltersItem[] = attributes?.map(attribute => {
    return {
      id: attribute.id,
      slug: attribute.slug,
      label: attribute.label,
      data: attribute,
      children: attribute.terms
        ? attribute.terms.map(attrTerm => {
            const label =
              attribute.slug === "PANGJYRA" ? (
                <>
                  <div className="ShopAttributes__color d-inline-block">
                    <div style={{ backgroundColor: `#${attrTerm.slug}` }} />
                  </div>
                  <small style={{ marginLeft: 5 }}>({attrTerm.count})</small>
                </>
              ) : (
                <span>
                  {attrTerm.name}
                  <small style={{ marginLeft: 5 }}>({attrTerm.count})</small>
                </span>
              );

            return {
              id: attrTerm.id,
              slug: attrTerm.slug,
              label: label,
              data: attribute,
              children: null
            };
          })
        : null
    };
  });

  return formatedArray;
};
