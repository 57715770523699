import React from "react";
import { DesktopMenu } from "../DesktopMenu/DesktopMenu";
// import { MainNavigation } from "../../../../components/MainNavigation/MainNavigation";

import "./DesktopHeader.scss";

export const DesktopHeader = () => {
  return (
    <div className="DesktopHeader">
      <DesktopMenu />
    </div>
  );
};
