// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { onMessage, getMessaging } from "@firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMNkhQepFYmTrKqkqfL-S_1wqtjOSAAVg",
  authDomain: "profiti-6ff7c.firebaseapp.com",
  projectId: "profiti-6ff7c",
  storageBucket: "profiti-6ff7c.appspot.com",
  messagingSenderId: "355381833059",
  appId: "1:355381833059:web:0eb347bc33e9ae53967756",
  measurementId: "G-RGLF17E64X",
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
