import { useFormik, FormikValues, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { RegisterFieldsStepTwo } from "../../pages/Register/Register";
import { formValidation } from "../translation/strings";

interface UseRegisterFormOptions {
  initialValues?: RegisterFieldsStepTwo;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<RegisterFieldsStepTwo>
  ) => Promise<any>;
}

export const useRegisterSecondStepFormik = (
  options: UseRegisterFormOptions
) => {
  const { t } = useTranslation();

  const RegisterSchema = Yup.object().shape({
    registerPassword: Yup.string()
      .min(5, t(formValidation.minPasswordLength))
      .required(t(formValidation.password)),
    registerConfirmPassword: Yup.string()
      .oneOf([Yup.ref("registerPassword")], t(formValidation.passwordNotMatch))
      .required(t(formValidation.password)),
  });

  return useFormik({
    initialValues: {
      registerPassword: "",
      registerConfirmPassword: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterSecondStepFormik = ReturnType<
  typeof useRegisterSecondStepFormik
>;
