import React from "react";
import { MenuLevel } from "../MenuLevel/MenuLevel";
import { ShopProductCategory } from "../../../queries/shop";
import { nextLevelType } from "../../../lib/helpers/nextLevelType";
import { InlineMenuLevel } from "../MenuLevel/InlineMenuLevel/InlineMenuLevel";
import { useMenuContextNew } from "../../../lib/context/MenuContextNew/MenuContextNew";
import cs from "classnames";

import "./MainMenu.scss";

export const MainMenu = () => {
  const menuCtx = useMenuContextNew();
  const mainItems = menuCtx.menu;

  const nextLevel = mainItems?.filter((item: ShopProductCategory) => {
    return item.children?.nodes.length !== 0;
  });

  const classes = cs("MainMenu__subcategories", {
    "MainMenu__subcategories--active": menuCtx.desktop.visibleDesktopMenu,
  });

  const mainClasses = cs("MainMenu", {
    "MainMenu__active-subcategories": menuCtx.desktop.visibleDesktopMenu,
  });

  return (
    <div onMouseLeave={menuCtx.desktop?.closeMenu} className={mainClasses}>
      {mainItems && mainItems.length > 0 && (
        <InlineMenuLevel depth={0} items={mainItems} />
      )}
      <div className={classes}>
        {nextLevel?.map((item: ShopProductCategory) => {
          return (
            <MenuLevel
              item={item}
              link={`/shop/${item.slug}`}
              depth={0}
              type={nextLevelType(item)}
              key={`${item.id}-menu-listing`}
            />
          );
        })}
      </div>
    </div>
  );
};
