import { useFormik, FormikHelpers, FormikValues } from "formik";

import * as Yup from "yup";
import { ProfileAddressFields } from "../../../components/ProfilePages/ProfileAddress/ProfileAddress";

interface UseProfileAddressOptions {
  initialValues: ProfileAddressFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProfileAddressFields>
  ) => Promise<any>;
}

export const useProfileAddressFormik = (opts: UseProfileAddressOptions) => {
  const ProfileAddressSchema = Yup.object().shape({
    billing: Yup.object().shape({
      firstName: Yup.string().required("Emri eshte i nevojshem!"),
      lastName: Yup.string().required("Mbiemri eshte i nevojshem!"),
      city: Yup.string().required("Qyteti eshte i nevojshem!"),
      address1: Yup.string().required("Adresa eshte e nevojshme!"),
      phone: Yup.string().required("Numri telefonit eshte i nevojshem!"),
    }),
  });

  return useFormik({
    initialValues: {
      ...opts.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfileAddressSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfileAddressFormik = ReturnType<typeof useProfileAddressFormik>;
