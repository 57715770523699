import React, { useRef, useState } from "react";
import cs from "classnames";
import { BrandItem } from "./BrandItem";
import { InfoMessage } from "../shared/InfoMessage/InfoMessage";
import { LoadMoreButton } from "../shared/LoadMoreButton/LoadMoreButton";
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState";
import { LoadingPlaceholder } from "../shared/LoadingPlaceholder/LoadingPlaceholder";
import Flickity from "react-flickity-component";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import { Brand } from "../../queries/brands";

import "./BrandsList.scss";

interface Props {
  hasNextPage?: boolean;
  isLoadingMore?: boolean;
  loading?: boolean;
  brands: Brand[];

  onLoadMore: () => void;
}

export const BrandsList = (props: Props) => {
  const noAvailableBrands = !props.brands || Number(props.brands.length) === 0;
  const flickity = useRef<Flickity | null>(null);
  const [titleRef] = useState<any>(null);
  const windowWidth = useWindowWidth();

  const isTablet = windowWidth < 1024;

  return (
    <>
      <HandleLoadingState
        loading={props.loading}
        loadingPlaceholder={
          <div className="d-flex justify-content-center align-content-center w-100">
            <LoadingPlaceholder />
          </div>
        }
      >
        <div
          className={cs(
            "BrandsList",
            noAvailableBrands && !props.loading && "BrandsList--empty"
          )}
        >
          {noAvailableBrands ? (
            <InfoMessage
              buttonLink="/shop"
              title={"Nuk ka brende!"}
              description={"Nuk ka description per brende!"}
            />
          ) : (
            <>
              <div className="SlideShow__wrapper">
                <Flickity
                  flickityRef={(c) => (flickity.current = c)}
                  className={"carousel"}
                  elementType={"div"}
                  options={{
                    wrapAround: false,
                    draggable: true,
                    cellAlign: props.brands?.length <= 4 ? "center" : "left",
                    freeScroll: true,
                    resize: true,
                    pageDots: !isTablet,
                    prevNextButtons: false,
                    contain: true,
                    bgLazyLoad: true,
                    lazyLoad: 1,
                  }}
                  disableImagesLoaded={false}
                >
                  {!isTablet && (
                    <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
                  )}
                  {props.brands?.map((brand, index: number) => {
                    return (
                      <div
                        key={`${brand.id}-${index}`}
                        className="SlideShow-box"
                        id={`SlideShow-${brand.slug}`}
                      >
                        <BrandItem brand={brand} key={brand.id} />
                      </div>
                    );
                  })}
                  {!isTablet && (
                    <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
                  )}
                </Flickity>
              </div>
            </>
          )}
        </div>
        {props.hasNextPage && (
          <LoadMoreButton
            onClick={props.onLoadMore}
            loading={props.isLoadingMore || false}
          />
        )}
      </HandleLoadingState>
    </>
  );
};
