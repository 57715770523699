import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { errors } from "../../lib/translation/strings";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import "./ProductSingleVariationsNew.scss";

export const ProductSingleVariationsNew = () => {
  const { t } = useTranslation();
  const {
    attributes,
    updateSelectedAttributes,
    selectedAttributes,
  } = useProductSingleContext();

  const color =
    attributes
      ?.filter((s: any) => s.name === "pa_ngjyra")
      ?.map((s: any) => {
        const options = Array.from(s.values) as string[];
        return { value: options[0] };
      }) || [];

  useEffect(() => {
    updateSelectedAttributes("pa_ngjyra", color[0]?.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="SingleProduct__variations_wrapper">
      {attributes.map((attr: any) => {
        const options = Array.from(attr.values) as string[];

        return (
          <div
            key={`${attr.name}-single-variations`}
            className="SingleProduct__content__variations"
          >
            <span className="label mr-5">{t(attr.name)}</span>
            <div className="ShopSelect__dropdown">
              <ul className="ShopSelect__dropdown_list">
                {!options || options.length === 0 ? (
                  <span>{t(errors.noOptions)}</span>
                ) : (
                  options?.map((option: string, index) => {
                    const items =
                      selectedAttributes
                        ?.filter((s) => s.attribute !== "pa_ngjyra")
                        ?.map((s) => ({ value: s.value, label: s.value })) ||
                      [];

                    const target =
                      items && Array.isArray(items)
                        ? items.find((o) => {
                            return o.value === option;
                          })
                        : null;

                    return (
                      <li
                        key={`${option}-${index}`}
                        data-selected={target?.value === option}
                        className="ShopSelect__dropdown_list_item"
                        onClick={() =>
                          updateSelectedAttributes(attr.name, option)
                        }
                      >
                        {option}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};
