import React from "react";
import { useHistory } from "react-router-dom";
import { UserCart } from "./UserCart";
import { Icon } from "../../../components/shared/Icon/Icon";
import { useWishlist } from "../../../lib/hooks/useWishlist";
import { ProfileHeader } from "../../../components/ProfileHeader/ProfileHeader";
import { NotificationsHeader } from "../../../components/NotificationsHeader/NotificationsHeader";
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth";
import { useMenuContext } from "../../../lib/context/MenuContext/MenuContext";
import cs from "classnames";

import "./UNavigation.scss";

export const UNavigation = () => {
  const menuCtx = useMenuContext();
  const history = useHistory();
  const wishList = useWishlist();
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const count = wishList.getProductsIDs().length;

  return (
    <div className="UNavigation">
      <UserCart className="UNavigation__item" />
      <NotificationsHeader />
      <span
        role="button"
        onClick={() => history.push("/my-list")}
        className="UNavigation__item UNavigation__item--wishlist"
      >
        <span className="UNavigation__item--wishlist-icon">
          <Icon icon="heart" />
          {count && count > 0 ? (
            <div className="UNavigation__item--wishlist-icon-number">
              {count}
            </div>
          ) : null}
        </span>
      </span>
      <ProfileHeader />
      <span
        role="button"
        className={cs(
          "Logo__mobile-menu",
          ((count && count > 0) || isMobile) && "Logo__mobile-menu--mobile"
        )}
        onClick={menuCtx.openSidebar}
      >
        <Icon icon="menu" />
      </span>

      {/* <LanguageSwitcher /> */}
    </div>
  );
};
