import React from "react";
import { ShopMobileFiltersScreen } from "./ShopMobileFiltersScreen";
import {
  MobileFiltersStep,
  useMobileFiltersContext
} from "../../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { ShopMobileFiltersItem } from "../ShopMobileFilters";
import { Icon } from "../../../shared/Icon/Icon";

export const ChooseAttribute = () => {
  const mobileFiltersCtx = useMobileFiltersContext();

  const handleSelectAttribute = (item: ShopMobileFiltersItem) => {
    const currentAttributes = mobileFiltersCtx.selectedAttributes ?? [];

    if (currentAttributes.includes(item)) {
      mobileFiltersCtx.setSelectedAttributes(
        currentAttributes.filter(attr => attr.slug !== item.slug)
      );
      return;
    }

    mobileFiltersCtx.setSelectedAttributes([...currentAttributes, item]);
  };

  const attribute = mobileFiltersCtx.attributes?.find(
    a => a.slug === mobileFiltersCtx.tempSelectedAttribute
  );

  const items = attribute?.children ?? [];

  const attr = mobileFiltersCtx.attributes?.find(
    attr => attr.slug === mobileFiltersCtx.tempSelectedAttribute
  );

  return (
    <ShopMobileFiltersScreen
      title={attr?.data?.name}
      onGoBack={() => {
        mobileFiltersCtx.setStep(
          MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE
        );
      }}
      body={
        <>
          {items.map(item => {
            const isSelected = mobileFiltersCtx.selectedAttributes?.includes(
              item
            );
            return (
              <li
                key={item.slug}
                className="ShopMobileFilters__main_list_item"
                onClick={() => handleSelectAttribute(item)}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {item.label}
                  {isSelected && <Icon icon="check" />}
                </span>
                {item.children && (
                  <span>
                    <Icon icon="chevron-right" />
                  </span>
                )}
              </li>
            );
          })}
        </>
      }
    />
  );
};
