import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  FormFeedback,
  Label,
} from "reactstrap";
import { RegisterFormik } from "../../lib/formik/useRegisterFormik";
import { useTranslation } from "react-i18next";
import { formInputs } from "../../lib/translation/strings";
import DatePicker from "react-datepicker";
import moment from "moment";
import cs from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { Select } from "../../components/GeneralFilters/SortSelect/Select";
import { getMonth, getYear } from "date-fns";
import { MONTHS, YEARS } from "../Cart/CartTotal";

import "./Register.scss";

interface Props {
  loading: boolean;
  formik: RegisterFormik;
}

const GENDER_OPTIONS = [
  {
    id: 1,
    value: "mashkull",
    label: "Mashkull",
  },
  {
    id: 2,
    value: "femër",
    label: "Femër",
  },
];

export const RegisterForm = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  const optionsGender = GENDER_OPTIONS.map((gender) => ({
    value: gender.value,
    label: gender.label,
  }));

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs="12" sm="6">
          <div className="RegisterForm__left">
            <span>Të dhënat personale</span>
          </div>
          <FormGroup className="form-group">
            <Label for="registerName">Emri</Label>
            <Input
              type="text"
              id="registerName"
              placeholder={t(formInputs.name)}
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.registerName}
              invalid={
                !!formik.errors.registerName && formik.touched.registerName
              }
            />
            <FormFeedback>{formik.errors.registerName}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="registerLastName">Mbiemri</Label>
            <Input
              type="text"
              id="registerLastName"
              placeholder={t(formInputs.lastName)}
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerLastName}
              invalid={
                !!formik.errors.registerLastName &&
                formik.touched.registerLastName
              }
            />
            <FormFeedback>{formik.errors.registerLastName}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="registerBirthday">Data e lindjes</Label>
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="next__btn mr-1"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    {"<"}
                  </button>
                  <select
                    className="next__btn"
                    value={getYear(date)}
                    onChange={({ target: { value } }) =>
                      changeYear(parseInt(value))
                    }
                  >
                    {YEARS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    className="next__btn"
                    value={MONTHS[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(MONTHS.indexOf(value))
                    }
                  >
                    {MONTHS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    className="next__btn ml-1"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    {">"}
                  </button>
                </div>
              )}
              id="registerBirthday"
              name="registerBirthday"
              selected={Date.parse(formik.values.registerBirthday) as any}
              onChange={(date) =>
                formik.setFieldValue(
                  "registerBirthday",
                  moment(date).format("MM/DD/yyyy")
                )
              }
              className={cs(
                "form-control form-control-custom",
                !!formik.errors.registerBirthday &&
                  formik.touched.registerBirthday &&
                  "is-invalid"
              )}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data e lindjes"
              closeOnScroll
            />

            {!!formik.errors.registerBirthday &&
              formik.touched.registerBirthday && (
                <span className="invalid-feedback d-block">
                  Data e lindjes është e nevojshme!
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="registerEmail">Emaili</Label>
            <Input
              type="text"
              id="registerEmail"
              placeholder="Emaili"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerEmail}
              invalid={
                !!formik.errors.registerEmail && formik.touched.registerEmail
              }
            />
            <FormFeedback>{formik.errors.registerEmail}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="registerEmailConfirm">Konfirmo Email-in</Label>
            <Input
              type="text"
              id="registerEmailConfirm"
              placeholder="Konfirmo Email-in"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerEmailConfirm}
              invalid={
                !!formik.errors.registerEmailConfirm &&
                formik.touched.registerEmailConfirm
              }
            />
            <FormFeedback>{formik.errors.registerEmailConfirm}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="registerGender">Gjinia</Label>
            <Select
              value={formik.values.registerGender || ""}
              name="registerGender"
              className={
                !!formik.errors.registerGender && formik.touched.registerGender
                  ? "is-invalid"
                  : ""
              }
              isInvalid={
                !!formik.errors.registerGender && formik.touched.registerGender
              }
              options={[
                {
                  value: "",
                  label: "Gjinia",
                  isPlaceholder: true,
                },
                ...optionsGender,
              ]}
              onChange={(value) =>
                formik.setFieldValue("registerGender", value)
              }
            />
            <FormFeedback>{formik.errors.registerGender}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs="12" sm="6">
          <div className="RegisterForm__left">
            <span>Informacionet e adreses</span>
          </div>
          <FormGroup>
            <Label for="registerCompany">Kompania</Label>
            <Input
              type="text"
              id="registerCompany"
              placeholder="Kompania"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerCompany}
              invalid={
                !!formik.errors.registerCompany &&
                formik.touched.registerCompany
              }
            />
            <FormFeedback>{formik.errors.registerCompany}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="registerJobPosition">Titulli i pozites</Label>
            <Input
              type="text"
              id="registerJobPosition"
              placeholder="Titulli i pozites"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerJobPosition}
              invalid={
                !!formik.errors.registerJobPosition &&
                formik.touched.registerJobPosition
              }
            />
            <FormFeedback>{formik.errors.registerJobPosition}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="registerTelephone">Numri i telefonit</Label>
            <Input
              type="tel"
              id="registerTelephone"
              placeholder={t(formInputs.telephoneNumber)}
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerTelephone}
              invalid={
                !!formik.errors.registerTelephone &&
                formik.touched.registerTelephone
              }
            />
            <FormFeedback>{formik.errors.registerTelephone}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="registerAddress">Adresa</Label>
            <Input
              type="text"
              id="registerAddress"
              placeholder={t(formInputs.address)}
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerAddress}
              invalid={
                !!formik.errors.registerAddress &&
                formik.touched.registerAddress
              }
            />
            <FormFeedback>{formik.errors.registerAddress}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="registerTelephone">Qyteti</Label>
            <Input
              type="text"
              id="registerCity"
              placeholder={t(formInputs.city)}
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerCity}
              invalid={
                !!formik.errors.registerCity && formik.touched.registerCity
              }
            />
            <FormFeedback>{formik.errors.registerCity}</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="registerCountry">Shteti</Label>
            <Input
              type="text"
              id="registerCountry"
              placeholder="Shteti"
              onChange={formik.handleChange}
              className="form-control-custom"
              value={formik.values.registerCountry}
              invalid={
                !!formik.errors.registerCountry &&
                formik.touched.registerCountry
              }
            />
            <FormFeedback>{formik.errors.registerCountry}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
