import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/shared/Button/Button';
import { FormFeedback, Input, Spinner } from 'reactstrap';
import { actions } from '../../lib/translation/strings';
import { Modal, ModalBody, Container } from 'reactstrap';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import DatePicker from 'react-datepicker';
import {
  CheckoutFields,
  CheckoutFormik,
  useCheckoutFormik,
} from '../../lib/formik/useCheckoutFormik';
import {
  CheckoutInputData,
  CheckoutResponse,
  CheckoutVariables,
  CHECKOUT_ORDER,
} from '../../queries/mutations/checkoutMutation';
import { useMutation } from 'react-apollo';
import { useAddressQuery } from '../../lib/hooks/useAddressQuery';
import { useRouter } from '../../lib/hooks/useRouter';
import { setLocalValue } from '../../lib/helpers/localStorageHelpers';
import { ShippingFields } from '../../components/Checkout/ShippingFields/ShippingFields';
import moment from 'moment';
import cs from 'classnames';
import { FormGroup, Label } from 'reactstrap';
import { Select } from '../../components/GeneralFilters/SortSelect/Select';
import { OrderFeedbackModal } from '../../components/OrderFeedback/OrderFeedbackModal';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';

import './Cart.scss';
import { useWindowWidth } from '../../lib/hooks/useWindowWidth';

interface Props {
  formik?: CheckoutFormik;
}

const TANSPORT_TYPE_OPTIONS = [
  { id: 1, value: 'bus', label: 'Bus' },
  { id: 2, value: 'office', label: 'Office' },
  { id: 3, value: 'post', label: 'Post' },
];

export const YEARS = range(1990, getYear(new Date()) + 1, 1);
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CartTotal = (props: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const cartCtx = useCartContext();
  const addressQuery = useAddressQuery();
  const windowWidth = useWindowWidth();
  const [open, setOpen] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [checkoutMutation, checkoutRes] = useMutation<
    CheckoutResponse,
    CheckoutVariables
  >(CHECKOUT_ORDER);
  const isMobile = windowWidth <= 768;
  const transportTypeOptions = TANSPORT_TYPE_OPTIONS.map((transport) => ({
    value: transport.value,
    label: transport.label,
  }));

  const customer = addressQuery?.data?.customer;

  // On success, redirect to order confirmation page.
  useEffect(() => {
    const data = checkoutRes?.data?.checkout;
    const isNewUser = formik.values.createAccount;

    if (data?.customer && isNewUser) {
      setLocalValue('authToken', data.customer.jwtAuthToken);
    }

    if (data?.customer && isNewUser) {
      authCtx.updateUser({
        id: data.customer.id,
        email: data.customer.email,
        userId: data.customer.customerId,
        lastName: data.customer.lastName,
        username: data.customer.username,
        firstName: data.customer.firstName,
        wishlist: [],
      });
    }

    if (data?.order?.id) {
      cartCtx.refetch();
      // router.history.push(`/checkout/${data.order?.databaseId}`);
      setOpenOrderModal(true);
    }
  }, [checkoutRes]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnClick = () => {
    if (props.formik?.isSubmitting || !props.formik) {
      return;
    }

    props.formik.submitForm();
  };

  const formik = useCheckoutFormik({
    initialValues: {
      billing: {
        firstName: customer?.billing.firstName || '',
        lastName: customer?.billing.lastName || '',
        address1: customer?.billing.address1 || '',
        city: customer?.billing.city || '',
        country: customer?.billing.country || 'XK',
        phone: customer?.billing.phone || '',
      },
      shipping: {
        firstName: customer?.shipping.firstName || '',
        lastName: customer?.shipping.lastName || '',
        address1: customer?.shipping.address1 || '',
        city: customer?.shipping.city || '',
        country: customer?.shipping?.country || 'XK',
      },
      transportType: '',
      transferDate: '',
      email: authCtx.user?.email || '',
      billingSameAsShipping: true,
      comment: '',
      termsAndConditions: true,
      paymentMethod: 'cod',
    },
    onSubmit: async (values) => {
      const inputData = prepareCheckoutInputData(values);

      try {
        await checkoutMutation({
          variables: {
            inputData: inputData,
          },
        });

        cartCtx.refetch();
      } catch (error) {
        window.scrollTo(0, 0);
      }
    },
  });

  const prepareCheckoutInputData = (values: CheckoutFields) => {
    let inputData: CheckoutInputData = {
      clientMutationId: 'checkout-react-app',
      billing: {
        ...values.billing,
        email: values.email,
        country: values.billing?.country,
      },
      shipToDifferentAddress: !values.billingSameAsShipping,
      paymentMethod: values.paymentMethod,
      shippingMethod: cartCtx.chosenShippingMethod,
      customerNote: values.comment,
      transferDate: values.transferDate,
      transportType: values.transportType,
    };
    if (!values.billingSameAsShipping) {
      inputData['shipping'] = {
        country: values.shipping?.country || '',
        firstName: values.shipping.firstName || '',
        lastName: values.shipping.lastName || '',
        city: values.shipping.city || '',
        address1: values.shipping.address1 || '',
      };
    }

    return inputData;
  };

  const buttonTitle = props.formik?.isSubmitting
    ? t(actions.processing)
    : t(actions.continueWithPurchase);

  const toggleOrderFeedbackModal = () => {
    setOpenOrderModal(false);
    router.history.push(
      `/my-profile/orders/${checkoutRes?.data?.checkout?.order?.databaseId}`
    );
  };

  return (
    <div className="CartTotal__wrapper">
      <Modal isOpen={open} toggle={() => setOpen(!open)}>
        <ModalBody>
          <Container>
            <div className="FeedbackModal">
              <h1 className="FeedbackModal__cart_title">
                Kërkesa për ta porosit produktin aprovohet nga admini
              </h1>
              <p className="FeedbackModal__cart_txt">
                Brenda 24 orëve porosia aprovohet dhe ju mund të vazhdoni tutje.
              </p>
              {changeAddress ? (
                <ShippingFields
                  onChange={() => setChangeAddress(false)}
                  formik={formik}
                />
              ) : (
                <>
                  <div className="FeedbackModal__grey">
                    <span>Porosia dërgohet tek</span>
                    <div className="FeedbackModal__grey_addr">
                      <label>
                        {formik?.values?.shipping?.firstName}{' '}
                        {formik?.values?.shipping?.lastName}
                      </label>
                      <label>{customer?.billing?.phone}</label>
                      <label>{formik?.values?.shipping?.address1}</label>
                    </div>

                    <Button
                      onClick={() => setChangeAddress(true)}
                      color="secondary"
                      type="submit"
                      disabled={false}
                      className="w-100-mobile FeedbackModal__button_grey"
                    >
                      Ndrysho adresën
                    </Button>
                  </div>
                  <div
                    style={{ marginTop: 10 }}
                    className="FeedbackModal__grey"
                  >
                    <div className="FeedbackModal__grey_addr w-100">
                      <FormGroup>
                        <Label for="transferDate">
                          Zgjedhë datën e transportit
                        </Label>

                        {isMobile ? (
                          <Input
                            type="date"
                            onChange={(event) => {
                              formik.setFieldValue(
                                'transferDate',
                                moment(event.target.value).format('MM/DD/yyyy')
                              );
                            }}
                          />
                        ) : (
                          <DatePicker
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <button
                                  className="next__btn mr-1"
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                >
                                  {'<'}
                                </button>
                                <select
                                  className="next__btn"
                                  value={getYear(date)}
                                  onChange={({ target: { value } }) =>
                                    changeYear(parseInt(value))
                                  }
                                >
                                  {YEARS.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  className="next__btn"
                                  value={MONTHS[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(MONTHS.indexOf(value))
                                  }
                                >
                                  {MONTHS.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <button
                                  className="next__btn ml-1"
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                >
                                  {'>'}
                                </button>
                              </div>
                            )}
                            id="transferDate"
                            name="transferDate"
                            selected={
                              Date.parse(formik.values.transferDate) as any
                            }
                            onChange={(date) =>
                              formik.setFieldValue(
                                'transferDate',
                                moment(date).format('MM/DD/yyyy')
                              )
                            }
                            className={cs(
                              'form-control form-control-custom',
                              !!formik.errors.transferDate &&
                                formik.touched.transferDate &&
                                'is-invalid',
                              'DatePicker'
                            )}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Data e transportit"
                            closeOnScroll
                          />
                        )}
                        {!!formik.errors.transferDate &&
                          formik.touched.transferDate && (
                            <span className="invalid-feedback d-block">
                              {formik.errors.transferDate}
                            </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="transportType">
                          Zgjedhë mënyrën e transportit
                        </Label>
                        <Select
                          value={formik.values.transportType}
                          name="transportType"
                          className={
                            !!formik.errors.transportType &&
                            formik.touched.transportType
                              ? 'is-invalid'
                              : ''
                          }
                          options={[
                            {
                              value: '',
                              label: 'Transporti',
                              isPlaceholder: true,
                            },
                            ...transportTypeOptions,
                          ]}
                          onChange={(value) =>
                            formik.setFieldValue('transportType', value)
                          }
                        />
                        <FormFeedback>
                          {formik.errors.transportType}
                        </FormFeedback>
                      </FormGroup>
                    </div>
                  </div>
                </>
              )}
              <div className="FeedbackModal__btn">
                <Button
                  onClick={formik.handleSubmit}
                  color="primary"
                  type="submit"
                  disabled={false}
                  className="w-100-mobile FeedbackModal__button"
                  style={{ marginBottom: 30, marginTop: 40 }}
                >
                  {checkoutRes?.loading ? (
                    <Spinner size="sm" />
                  ) : (
                    'Konfirmoj të vazhdoj'
                  )}
                </Button>
              </div>

              <div
                onClick={() => setOpen(false)}
                className="FeedbackModal__close"
              >
                <span>X</span>
              </div>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <OrderFeedbackModal
        orderId={checkoutRes?.data?.checkout?.order?.databaseId}
        open={openOrderModal}
        toggle={toggleOrderFeedbackModal}
      />
      <div className="CartTotal">
        {props.formik ? (
          <Button
            color="primary"
            title={buttonTitle}
            onClick={handleOnClick}
            className="FeedbackModal__button"
          >
            {props.formik?.isSubmitting ? (
              <>
                Ju lutem prisni <Spinner className="ml-2" />
              </>
            ) : (
              <>Vazhdo me porosinë</>
            )}
          </Button>
        ) : cartCtx?.items?.length ? (
          <div className="CartSidebar-footer">
            <div className="CartSidebar-footer--left">
              <span>Totali + transporti</span>
              <h1>
                {cartCtx?.items?.length}{' '}
                {cartCtx?.items?.length === 1 ? 'produkt' : 'produkte'}
              </h1>
            </div>
            <Button
              color="primary"
              title={buttonTitle}
              onClick={() => setOpen(true)}
              className="FeedbackModal__button"
            >
              Vazhdo me porosinë
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
