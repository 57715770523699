import React from "react";
import cs from "classnames";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import { useMenuContextNew } from "../../../../lib/context/MenuContextNew/MenuContextNew";
import { fields } from "../../../../lib/translation/strings";
import { ShopProductCategory } from "../../../../queries/shop";

import { ThemeLink } from "../../../../components/shared/ThemeLink/ThemeLink";

import "./InlineMenuLevel.scss";

interface Props {
  id?: string;
  link?: string;
  depth: number;
  items: Array<ShopProductCategory>;
}

export const InlineMenuLevel = (props: Props) => {
  const menuCtx = useMenuContextNew();
  const { t } = useTranslation();

  const classes = cs(
    "InlineMenuLevel",
    {
      "InlineMenuLevel--active":
        props.depth === 0 ||
        menuCtx.desktop.activeLevels[props.depth] === props.id,
    },
    Object.keys(menuCtx.desktop.activeLevels).length > 0 &&
      "InlineMenuLevel--active-border"
  );

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: ShopProductCategory,
    isActive: boolean
  ) => {
    const hasChildren = item.children && item.children.nodes.length > 0;

    if (hasChildren || isActive) {
      event.preventDefault();
    }

    if (isActive) {
      return;
    }

    menuCtx.desktop.updateActiveLevels(props.depth + 1, item.id);

    if (!hasChildren) {
      menuCtx.desktop.closeMenu();
    }
  };

  return (
    <>
      <div className={classes} data-depth={props.depth}>
        <Container>
          <ul className="InlineMenuLevel__menu">
            {props.items.map((item: ShopProductCategory) => {
              const isActive =
                menuCtx.desktop.activeLevels[props.depth + 1] === item.id;

              const itemClasses = cs("InlineMenuLevel__menu__item", {
                "InlineMenuLevel__menu__item--active": isActive,
              });

              return (
                <li key={`${item.id}-menu-listing`} className={itemClasses}>
                  <ThemeLink
                    to={
                      props.link
                        ? `${props.link}/${item.slug}`
                        : `/shop/${item.slug}`
                    }
                    onMouseOver={(e) => {
                      handleClick(e, item, isActive);
                    }}
                  >
                    {item.name}
                  </ThemeLink>
                </li>
              );
            })}

            {props.link && (
              <li className="InlineMenuLevel__menu__item">
                <ThemeLink
                  to={props.link}
                  onClick={() => {
                    menuCtx.desktop.closeMenu();
                  }}
                >
                  {t(fields.viewAll)}
                </ThemeLink>
              </li>
            )}
          </ul>
        </Container>
      </div>
    </>
  );
};
