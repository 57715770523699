import gql from 'graphql-tag';
import { VARIABLE_PRODUCT_FIELDS } from '../archive';

export const UPDATE_ITEM_QUANTITIES = gql`
  mutation($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      cart {
        isEmpty
        subtotal
        total
        contents(first: 1000) {
          itemCount
        }
      }
      items {
        key
        quantity
        total
        tax
        variation {
          node {
            id
            sku
            onSale
            salePrice
            regularPrice
            image {
              mediaItemUrl
              srcSet(size: LARGE)
            }
            stockQuantity
            attributes {
              nodes {
                id
                name
                value
              }
            }
          }
        }
        product {
          node {
            id
            slug
            databaseId
            name
            onSale
            type
            image {
              id
              mediaItemUrl
              srcSet(size: LARGE)
            }
            productBrands {
              nodes {
                id
                name
                slug
              }
            }
            ... on SimpleProduct {
              name
              salePrice
              regularPrice
              stockQuantity
            }
            ... on VariableProduct {
              name
              salePrice
              regularPrice
              stockQuantity
            }
          }
        }
      }
    }
  }
`;

export const ADD_ITEM_TO_CART = gql`
  mutation addProduct(
    $clientMutationId: String!
    $productId: Int!
    $quantity: Int
    $variationId: Int
  ) {
    addToCart(
      input: {
        clientMutationId: $clientMutationId
        productId: $productId
        quantity: $quantity
        variationId: $variationId
      }
    ) {
      cart {
        isEmpty
        subtotal
        total
        discountTotal
        shippingTotal
        chosenShippingMethod
        appliedCoupons {
          nodes {
            id
            code
            amount
            discountType
          }
        }
        contents(first: 1000) {
          itemCount
          nodes {
            key
            quantity
            variation {
              node {
                id
                sku
                onSale
                salePrice
                regularPrice
                stockQuantity
                image {
                  mediaItemUrl
                  srcSet(size: LARGE)
                }
                attributes {
                  nodes {
                    id
                    name
                    value
                  }
                }
              }
            }
            product {
              node {
                id
                slug
                databaseId
                name
                onSale
                type
                image {
                  id
                  mediaItemUrl
                  srcSet(size: LARGE)
                }
                productBrands {
                  nodes {
                    id
                    name
                    slug
                  }
                }

                ... on SimpleProduct {
                  id
                  name
                  salePrice
                  regularPrice
                  stockQuantity
                  stockStatus
                  localAttributes {
                    nodes {
                      name
                      options
                      id
                    }
                  }
                }
                ... on VariableProduct {
                  ...VariableProductFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${VARIABLE_PRODUCT_FIELDS}
`;

export const EMPTY_CART = gql`
  mutation EmptyCart {
    emptyCart(input: { clientMutationId: "EmptyCart" }) {
      clientMutationId
    }
  }
`;

export interface UpdateShippingMethodVariables {
  shippingMethods: string[];
}

export interface UpdateShippingMethodResponse {
  updateShippingMethod: {
    cart: {
      total: string;
      shippingTotal: string;
      chosenShippingMethod: string;
    };
  };
}

export const updateShippingMethod = gql`
  mutation updateShippingMethod($shippingMethods: [String]) {
    updateShippingMethod(
      input: {
        clientMutationId: "update-shipping-method-mutation"
        shippingMethods: $shippingMethods
      }
    ) {
      cart {
        total
        shippingTotal
        chosenShippingMethod
      }
    }
  }
`;
