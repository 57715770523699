import React from "react";
import cs from "classnames";

import { useMenuContextNew } from "../../../lib/context/MenuContextNew/MenuContextNew";

import { Container } from "../../../components/Container/Container";
import DesktopMenuLoaders from "../../../components/shared/DesktopMenu/DesktopMenuLoaders";
import { ApolloErrorGuard } from "../../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../../components/shared/HandleLoadingState/HandleLoadingState";

import { MainMenu } from "../MainMenu/MainMenu";

import "./DesktopMenu.scss";

export const DesktopMenu = () => {
  const menuCtxNew = useMenuContextNew();

  const classes = cs("DesktopMenu");

  React.useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (menuCtxNew.desktop.visibleDesktopMenu) {
      document.body.style.overflowY = "scroll";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    };
  }, [menuCtxNew.desktop.visibleDesktopMenu]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes}>
      <ApolloErrorGuard error={menuCtxNew.error} errorComponent={<span />}>
        <HandleLoadingState
          loading={menuCtxNew.loading}
          loadingPlaceholder={
            <div style={{ backgroundColor: "#fff" }}>
              <Container>
                <DesktopMenuLoaders />
              </Container>
            </div>
          }
        >
          <MainMenu />
        </HandleLoadingState>
      </ApolloErrorGuard>
    </div>
  );
};
