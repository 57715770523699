import React, { useContext } from "react";
import { ApolloError } from "apollo-boost";
import { ShopProductCategory } from "../../../queries/shop";

export interface MenuContextNewType {
  desktop: {
    menuRef: React.MutableRefObject<any>;
    activeLevels: any;
    // visibleMenu: boolean;
    closeMenu: () => void;
    toggleMenu: () => void;
    updateActiveLevels: (level: number, id: string) => void;
    visibleDesktopMenu: boolean;
  };
  mobile: {
    activeLevel: string;
    visibleMenu: boolean;
    openMenu: () => void;
    closeMenu: () => void;
    toggleMenu: () => void;
    setActiveLevel: (id: string) => void;
  };
  loading: boolean;
  error: ApolloError | undefined;
  menu?: ShopProductCategory[];
  // TO DO: Remove any type for info bar
  infoBar: any;
}

const menuContextNewValues: MenuContextNewType = {
  desktop: {
    menuRef: { current: null },
    activeLevels: {},
    // visibleMenu: false,
    closeMenu: () => {},
    toggleMenu: () => {},
    updateActiveLevels: () => {},
    visibleDesktopMenu: false,
  },
  mobile: {
    activeLevel: "",
    visibleMenu: false,
    openMenu: () => {},
    closeMenu: () => {},
    toggleMenu: () => {},
    setActiveLevel: () => {},
  },
  loading: true,
  menu: undefined,
  error: undefined,
  infoBar: undefined,
};

export const MenuContextNew = React.createContext<MenuContextNewType>(
  menuContextNewValues
);

export const useMenuContextNew = () => useContext(MenuContextNew);
