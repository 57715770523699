import React, { useState } from "react";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";
import { GET_FOOTER } from "../../queries/generalQueries";
import { ReactComponent as Facebook } from "../../assets/icons/footer-fb.svg";
import { ReactComponent as Instagram } from "../../assets/icons/footer-insta.svg";
import { ReactComponent as Twitter } from "../../assets/icons/footer-twitter.svg";
import { ReactComponent as Pinterest } from "../../assets/icons/footer-pinterest.svg";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";
import { FeedbackModal } from "../../components/Feedback/FeedbackModal";
import { Container } from "../../components/Container/Container";
import { CartTotal } from "../../pages/Cart/CartTotal";

import "./Footer.scss";

export interface Feedback {
  feedback: string;
  message: string;
  review: number;
}

export const Footer = () => {
  const { data, error } = useQuery(GET_FOOTER);
  const windowWidth = useWindowWidth();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const isMobile = windowWidth <= 1024;
  const footerData = data?.generalOptions?.generalOptions?.footer ?? {};

  if (error) {
    return null;
  }

  return (
    <>
      {history.location.pathname === "/my-cart" ? (
        <CartTotal />
      ) : history.location.pathname.includes("/my-profile/orders/") &&
        history.location.pathname.length > 20 ? null : (
        <div className="Footer">
          <div className="Footer__copyright">
            <Container>
              <div className="Footer__wrapper">
                <div className="Footer__logo_and_icons__wrapper">
                  <div className="Footer__logo">
                    <img
                      style={
                        isMobile
                          ? { width: 168, height: 33 }
                          : { width: 222, height: 45 }
                      }
                      src={require("../../assets/images/profiti-shop.png")}
                      alt="logo"
                    />
                  </div>
                  {isMobile && (
                    <div className="Footer__icons">
                      <a
                        target="_blank"
                        rel="noopener noreferref"
                        href={footerData?.facebook}
                        className="Footer__icons_icon"
                      >
                        <Facebook
                          style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferref"
                        href={footerData?.instagram}
                        className="Footer__icons_icon"
                      >
                        <Instagram
                          style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferref"
                        href={footerData?.linkedin}
                        className="Footer__icons_icon"
                      >
                        <Twitter
                          style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferref"
                        href={footerData?.pinterest}
                        className="Footer__icons_icon"
                      >
                        <Pinterest style={{ width: 14.2, height: 14.2 }} />
                      </a>
                    </div>
                  )}
                </div>
                <div className="Footer__tabs">
                  <a style={{ marginRight: 20 }}>Kushtet e Përdorimit</a>
                  <a style={{ marginRight: 20 }}>Politika e privatësisë</a>
                  <a onClick={() => setOpen(true)} style={{ marginRight: 20 }}>
                    Komente
                  </a>
                  <a href="/help">Akademi</a>
                </div>
                {!isMobile && (
                  <div className="Footer__icons">
                    <a
                      target="_blank"
                      rel="noopener noreferref"
                      href={footerData?.facebook}
                      className="Footer__icons_icon"
                    >
                      <Facebook
                        style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferref"
                      href={footerData?.instagram}
                      className="Footer__icons_icon"
                    >
                      <Instagram
                        style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferref"
                      href={footerData?.linkedin}
                      className="Footer__icons_icon"
                    >
                      <Twitter
                        style={{ width: 14.2, height: 14.2, marginRight: 19 }}
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferref"
                      href={footerData?.pinterest}
                      className="Footer__icons_icon"
                    >
                      <Pinterest style={{ width: 14.2, height: 14.2 }} />
                    </a>
                  </div>
                )}
              </div>
            </Container>
          </div>
          <FeedbackModal open={open} toggle={() => setOpen(false)} />
        </div>
      )}
    </>
  );
};
