import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { Container as ReactContainer } from "reactstrap";

import { errors } from "../../lib/translation/strings";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import { Alert } from "../../components/Notifications/Alert";
import { RelatedProducts } from "../../components/RelatedProducts/RelatedProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { ProductSingleContextProvider } from "../../lib/context/ProductSingleContext/ProductSingleContextProvider";
import { ProductSingleCarousel } from "../../components/ProductSingleCarousel/ProductSingleCarousel";
import { SingleProductContent } from "../../components/ProductSingleContent/ProductSingleContent";
import { SingleProductDetails } from "../../components/SingleProductDetails/SingleProductDetails";
import { SingleProductButtons } from "../../components/SingleProductButtons/SingleProductButtons";
import { AmazonLoader } from "./AmazonLoader";
import { Container } from "../../components/Container/Container";
import { ProductSingleVariations } from "../../components/ProductSingleVariations/ProductSingleVariations";
import { ProductSingleVariationsNew } from "../../components/ProductSingleVariationsNew/ProductSingleVariationsNew";
import { ProductSimpleAttributesNew } from "../../components/ProductSimpleAttributesNew/ProductSimpleAttributesNew";

import "./SingleProduct.scss";

const ProductSingleInner = () => {
  const { t } = useTranslation();
  const { loading, error, product } = useProductSingleContext();

  // const increaseCountOfProduct = (databaseId: number) => {
  //   if (!databaseId) {
  //     return;
  //   }

  //   const currentProduct = localStorage.getItem("PROFITISHOP_USER_FAV_PRODUCT");

  //   const currentProductJSON = currentProduct
  //     ? JSON.parse(currentProduct)
  //     : null;

  //   const currentProductJSONFilteredIndex = currentProductJSON?.findIndex(
  //     (item: { productId: number; count: number }) => {
  //       return item.productId === databaseId;
  //     }
  //   );

  //   if (!currentProductJSON) {
  //     const newProduct = {
  //       productId: databaseId,
  //       count: 1,
  //     };

  //     // Add the new product to the array and update the array in local storage
  //     localStorage.setItem(
  //       "PROFITISHOP_USER_FAV_PRODUCT",
  //       JSON.stringify([newProduct])
  //     );
  //     return;
  //   }

  //   if (currentProductJSONFilteredIndex !== -1) {
  //     // Product already exists in the array
  //     const currentProductJSONFiltered =
  //       currentProductJSON[currentProductJSONFilteredIndex];
  //     currentProductJSONFiltered.count += 1;

  //     // Update the array in local storage
  //     localStorage.setItem(
  //       "PROFITISHOP_USER_FAV_PRODUCT",
  //       JSON.stringify(currentProductJSON)
  //     );
  //   } else {
  //     // Product does not exist in the array
  //     const newProduct = {
  //       productId: databaseId,
  //       count: 1,
  //     };

  //     // Add the new product to the array and update the array in local storage
  //     localStorage.setItem(
  //       "PROFITISHOP_USER_FAV_PRODUCT",
  //       JSON.stringify([...currentProductJSON, newProduct])
  //     );
  //   }
  // };

  // useEffect(() => {
  //   increaseCountOfProduct(product?.databaseId);
  // }, [product]);

  return (
    <>
      <div className={cs("SingleProduct")}>
        <Container>
          <HandleLoadingState
            loading={loading}
            loadingPlaceholder={
              <ReactContainer>
                <AmazonLoader />
              </ReactContainer>
            }
          >
            <ApolloErrorGuard error={error}>
              {!product ? (
                <Alert type="warning" message={t(errors.noProductFound)} />
              ) : (
                <div className="SingleProduct__wrapper">
                  <ProductSingleCarousel />
                  <div className="SingleProduct__content">
                    <SingleProductContent />
                    <ProductSingleVariations />
                    {product.type === "VARIABLE" && (
                      <ProductSingleVariationsNew />
                    )}
                    {product.type === "SIMPLE" && (
                      <ProductSimpleAttributesNew />
                    )}
                    <SingleProductDetails />
                    <SingleProductButtons />
                  </div>
                </div>
              )}
              <RelatedProducts
                excludedID={product?.databaseId}
                category={product?.productCategories.nodes[0]?.slug || ""}
              />
            </ApolloErrorGuard>
          </HandleLoadingState>
        </Container>
      </div>
    </>
  );
};

export const ProductSingle = () => {
  return (
    <ProductSingleContextProvider>
      <ProductSingleInner />
    </ProductSingleContextProvider>
  );
};
