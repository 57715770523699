export const formInputs = {
  name: "Emri",
  city: "Qyteti",
  email: "Email",
  confirmEmail: "Konfirmo emailin",
  state: "Shteti",
  address: "Adresa",
  message: "Mesazhi",
  lastName: "Mbiemri",
  password: "Fjalëkalimi",
  yourComment: "Komenti juaj",
  username: "Emri i përdoruesit",
  newPassword: "Fjalëkalimi i ri",
  telephoneNumber: "Numri i telefonit",
  confirmPassword: "Konfirmo fjalëkalimin",
  commentPlaceholder: "Na shkruani këtu për më shumë informata",
};

export const formValidation = {
  cv: "Ju lutemi vendosni cv-n",
  city: "Qyteti është i nevojshëm",
  country: "Shteti është i nevojshëm",
  confirmEmail: "Rishkruaj emailin",
  address: "Adresa është e nevojshme",
  firstName: "Emri është i nevojshëm",
  lastName: "Mbiemri është i nevojshëm",
  email: "Ju lutemi shënoni emailin tuaj",
  differentEmail: "Emaili nuk përputhet",
  message: "Ju lutemi shkruani mesazhin tuaj",
  validEmail: "Ju lutemi shënoni një email valide",
  phone: "Numri i telefonit është i nevojshëm",
  password: "Ju lutemi shënoni fjalëkalimin tuaj",
  passwordConfirm: "Ju lutemi shënoni fjalëkalimin konfirmues tuaj",
  passwordNotMatch: "Fjalëkalimi nuk është i njejtë",
  paymentMethod: "Ju lutemi zgjidhni mënyrën e pagesës",
  selectVariation: "Ju lutemi zgjidhni një nga opsionet",
  required: "E nevojshme",
  minPasswordLength: "Fjalëkalimi duhet të përmbajë më së paku 5 karaktere",
  termsAndConditions:
    "Ju lutemi konfirmoni se a pajtoheni me termet dhe kushtet",
};

export const formMessages = {
  profileUpdated: "Të dhënat tuaja janë përditësuar",
  profileNotUpdated:
    "Përditësimi i të dhënave nuk është kompletuar... Ju lutemi provoni përseri",
  thanksForComment: "Faleminderit per komentin tuaj",
};

export const actions = {
  clearFilters: "Largo filterat",
  login: "Kyçu",
  save: "Ruaj",
  show: "Shfaq",
  send: "Dërgo",
  logOut: "Dil",
  home: "Ballina",
  contact: "Kontakti",
  aboutUs: "Për ne",
  jobs: "Pune",
  features: "Vecorite",
  feedback: "Komente",
  letterA: "Shkronjes a",
  letterZH: "Shkronjes z",
  highestPrice: "Cmimit te larte",
  lowestPrice: "Cmimit te ulet",
  apply: "Apliko",
  remove: "Largo",
  reviews: "Reagime",
  search: "Kërko",
  addComment: "Shkruaj nje koment",
  clean: "Pastro",
  filter: "Filtro",
  approve: "Aprovim",
  register: "Regjistrohu",
  orderBy: "Rendit sipas",
  seeMore: "Shiko më shumë",
  loggingIn: "Duke u kyçur",
  saving: "Duke u ruajtuar",
  loading: "Duke u ngarkuar",
  addToBag: "Shto në shportë",
  addProducts: "Shto produkte",
  processing: "Duke u procesuar",
  createAccount: "Krijo llogari",
  backToHome: "Kthehu në ballinë",
  findProduct: "Kërko produktin",
  subscribe: "Abonohu për të rejat",
  goBackToStores: "Kthehu te butikat",
  goBackToBrands: "Kthehu te brendet",
  removingCoupon: "Duke larguar kuponin",
  privacyPolicy: "Politikat e privatsise",
  addToWishlist: "Shto ne listen e deshirave",
  retypePassword: "Rivendos fjalëkalimin",
  selectYourCity: "Zgjidh qytetin tënd",
  continueWithPurchase: "Vazhdo me blerjen",
  seeAllResults: "Shiko të gjitha rezultatet",
  updatingShippingMethod: "Duke përditësuar mënyrën e dërgesës",
};

export const fields = {
  selectOneOfTheOptions: "Selekto njërën nga opsionet",
  price: "Çmimi",
  city: "Qyteti",
  total: "Totali",
  cart: "Shporta",
  info: "Informacione",
  order: "Porosia",
  stores: "Butikat",
  reagime: "Reagime",
  yourRating: "Vlersimi juaj",
  dailyProduct: "Produkti ditor",
  address: "Adresa",
  others: "Te tjera",
  status: "Statusi",
  brands: "Brendet",
  events: "Eventet",
  feedback: "Na dergoni komente",
  experience: "Eksperienca juaj",
  market: "Marketi",
  country: "Shteti",
  quantity: "Sasia",
  barcode: "Barkodi",
  offers: "Oferta",
  myList: "Lista ime",
  myOrder: "Porosia juaj",
  billing: "Faturimi",
  seeMore: "Më shumë",
  product: "Produkti",
  shipping: "Dërgesa",
  article: "Artikull",
  articles: "Artikuj",
  subtotal: "Subtotali",
  contact: "Kontakti",
  viewAll: "Të gjitha",
  privacy: "Privatësia",
  subTotal: "Nën total",
  zipCode: "Kodi postar",
  cuponCode: "Kodi i kuponit",
  transport: "Transporti",
  categories: "Kategoritë",
  description: "Përshkrimi",
  subcategories: "Nënkategoritë e produkteve",
  filterByPrice: "Filtroni sipas cmimit",
  myAccount: "Llogaria ime",
  seeCart: "Shiko shportën",
  faq: "Pyetjet e shpeshta",
  jobVacancy: "Konkurs pune",
  myOrders: "Porositë e mia",
  verification: "Verifikimi",
  yourAccount: "Llogaria ime",
  orderDate: "Data e porosisë",
  myProducts: "Produktet e mia",
  includeTaxes: "Përfshirë tvsh",
  relatedProducts: "Të ngjashme",
  shippingCost: "Çmimi i dërgesës",
  productType: "Llojet e produkteve",
  paymentMethod: "Mënyra e pagesës",
  usedCoupons: "Kuponat e përdorur",
  orderDetails: "Detajet e porosisë",
  termsOfPurchase: "Kushtet e blerjes",
  bagSummary: "Përmbledhja e shportës",
  billingAddress: "Adresa e faturimit",
  shippingAddress: "Adresa e dërgesës",
  orderSummary: "Përmbledhja e porosisë",
  shippingMethod: "Mënyra e transportit",
  billingAndShipping: "Faturimi dhe dërgimi",
  personalInformation: "Të dhënat personale",
  searchResultsFor: "Eezultatet e kërkimit për",
  copyrights: "Rë gjitha të drejtat e autorizuara",
  categorizeByAlphabet: "Kategorizimi sipas alfabetit",
  male: "Meshkuj",
  female: "Femra",
  children: "Fëmijë",
  socialMedia: "Rrjetet sociale",
  all: "Të gjitha",
};

export const errors = {
  noStores: "Nuk ka butika",
  noBrands: "Nuk ka brende",
  noOptions: "Nuk ka opsione",
  noProducts: "Nuk ka produkte",
  requiredCoupon: "Kuponi është i domosdoshëm",
  noLogin: "Ju duhët të kyçeni!",
  noProductsFound: "Asnjë rezultat nuk është gjetur!",
  noStoresFound: "Për momentin nuk gjendet ky butik",
  noProductFound: "Produkti i kërkuar nuk është gjetur",
  noProductsDescription: "Për momentin nuk ka produkte",
  noOrdersFound: "Ju momentalisht nuk keni ndonjë porosi",
  noBrandsDescription: "Për momentin nuk gjendet ky brend",
  wrongEmailOrPassword: "Emaili ose fjalëkalimi janë gabim",
  productNotInStock: "Produkti i zgjedhur nuk është në stok",
  variationOutOfStock: "Opsioni i zgjedhur nuk është në stok",
  nothingFound: "Nuk është gjetur asnjë artikull për këtë kategori",
  maximumAmount: "Keni arritur maksimumin e stokut të këtij produkti",
  noStoreProducts: "Në Butikun e kerkuar nuk ka produkte te listuara",
  noBrandsProducts: "Në Brendin e kerkuar nuk ka produkte te listuara",
  wentWrong: "Diqka gabim",
  orderNotFound:
    "Porosia që po e kërkoni nuk u gjetë vizitoni listen e porosive",
  cannotAddAmountOfStockToCart: "errorsCannotAddAmountOfStockToCart",
  noVariations: "Nuk ka ne stok",
};

export const messages = {
  free: "Falas",
  allRight: "Në rregull",
  agreeWith: "Pranoj",
  pleaseWait: "Prit",
  allStores: "Të gjitha dyqanet",
  allBrands: "Të gjitha brendet",
  buyByStore: "Blej sipas dyqanit",
  buyByBrand: "Blej sipas brendit",
  purchaseTerms: "Kushtet e blerjes",
  returnProduct: "Kushtet e kthimit",
  allProducts: "Të gjitha produktet",
  searchProducts: "Kërko produktin",
  emptyCart: "Shporta e zbrazet",
  emptyList: "Lista e zbrazet",
  contactFooter: "Mund te na kontaktoni",
  buyInMarket: "Blej në L'kurë",
  pageNotFound: "Kjo faqe nuk ekziston",
  buyByCategory: "Blej sipas kategorisë",
  transportInfo: "Për qdo porosi",
  transportInfoUpTo: "Për qdo porosi mbi",
  singleProductAdded: "Produkti u shtua",
  forgotPassword: "Kam harruar fjalëkalimin",
  outOfStock: "Jashtë stokut",
  orderOnline: "Porosit online",
  payAfterOrder: "Paguaj pas pranimit te mallit",
  granteedQuality: "Kualitet i garantuar",
  transportKosova: "Transport ne te gjith kosoven",
  toTheDoor: "Deri der te shtepise",
  highQuality: "Prodhim vendor i kualitetit te larte",
  copyRight: "E drejta e autorit",
  rightReserved: "Te gjitha drejtat",
  limitedProducts: "Sasia e limituar",
  noFaq: "Momentalisht nuk ka ndonjë pyetje",
  shippingInformation: "Të dhënat e dërgesës",
  toAnotherAddress: "Adresë tjetër",
  freeShipping: "Transporti falas",
  termsOfShipping: "Kushtet e transportit",
  noComment: "Nuk ka komente",
  contactSuccess: "Ju faleminderit për mesazhin",
  registerSuccess: "Jeni regjistruar me sukses",
  soldOut: "E shitur",
  productAdded: "Keni shtuar një produkt në shportë",
  noProductOnTheList: "Ju momentalisht nuk keni ndonjë produkt në listë",
  noProductsOnWishlist: "Ju momentalisht nuk keni ndonjë produkt në listë",
  resetPasswordSuccess:
    "Keni ndryshuar fjalëkalimin me sukses, ju lutemi kyçuni këtu",
  orderConfirmed:
    "Porosia juaj u verifikua dhe së shpejti do të informoheni përmes emailit",
  resetPasswordDescription:
    "Vendoseni fjalëkalimin e ri dhe pastaj mund të kyçeni me kredenciale të reja",
  resetPasswordInstruction:
    "Në emailin që keni dhënë këtu do të pranoni linkun ku  duhet t'i ndiqni hapat për rivendosje të fjalëkalimit",
  createAccountMessage:
    "Në mënyrë që t'ju lehtësojmë procesin e blerjes, duhet të hapni llogari. Hapja e llogarisë nënkupton dhënie të informatave personale.",
  resetPasswordMessage:
    "Ju kemi dërguar një email për t'ju udhëtuar se si ta rivendosni fjalëkalimin e ri. Nëse rivendosni fjalëkalimin e ri, atëherë kyçuni në butonin më poshtë",
  atSize: "Tek madhesia",
  atColor: "Tek ngjyra",
};
