import { REFRESH_JWT_AUTH_TOKEN } from "../queries/mutations/refreshJwtAuthToken";

import { isTokenExpired } from "../lib/helpers/isTokenExpired";
import {
  getLocalValues,
  setLocalValue,
} from "../lib/helpers/localStorageHelpers";

export const handleWooSession = (token: string | null) => {
  const { wooSession } = getLocalValues();

  if (!token || wooSession === token) {
    return;
  }

  setLocalValue("wooSession", token);
};

interface RequestHeadersType {
  authorization?: string;
  "woocommerce-session"?: string;
}

export const attachRequestHeaders = () => {
  let requestHeaders: RequestHeadersType = {};
  const { authToken, wooSession } = getLocalValues();

  if (authToken) {
    requestHeaders["authorization"] = `Bearer ${authToken}`;
  }

  if (wooSession && !isTokenExpired(wooSession)) {
    requestHeaders["woocommerce-session"] = `Session ${wooSession}`;
  }

  return requestHeaders;
};

export const getJWTRefreshToken = (): string => {
  const { refreshToken } = getLocalValues();

  return refreshToken;
};

// TODO: Refactor the use of getNewTokenQuery variable, to moved inside the function or a file
let getNewTokenQuery: Promise<any>;
let isTokenNewQueryResolving: Boolean = false;

export const getNewToken = async () => {
  if (getNewTokenQuery && isTokenNewQueryResolving) {
    return getNewTokenQuery;
  }

  isTokenNewQueryResolving = true;
  try {
    const refreshToken = await getJWTRefreshToken();
    const res = await fetch(process.env.REACT_APP_GRAPHQL_API as RequestInfo, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        variables: {
          refreshToken: refreshToken,
        },
        query: REFRESH_JWT_AUTH_TOKEN.loc?.source.body,
      }),
    });
    const resNew = await res.json();
    isTokenNewQueryResolving = false;

    return resNew.data.refreshJwtAuthToken.authToken;
  } catch (error) {
    console.error(error);
  }
  return getNewTokenQuery;
};
