import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import { ThemeLink } from '../shared/ThemeLink/ThemeLink';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { ReactComponent as Delete } from '../../assets/icons/delete-cart.svg';
import { ProductAttributeProps } from '../../types/productTypes';
import { useWindowWidth } from '../../lib/hooks/useWindowWidth';

import { CartNode } from '../../queries/cartQueries';
import { Quantity } from '../shared/QuantityInput/Quantity';

import { Image } from '../shared/Image/Image';

import './CartItem.scss';

interface Props {
  item: CartNode;
}

const getStockQuantity = (item: CartNode) => {
  const product = item.product;

  if (product.node?.type === 'SIMPLE') {
    return product?.node?.stockQuantity || undefined;
  }

  if (product.node?.type === 'VARIABLE') {
    return (
      item?.variation?.node?.stockQuantity ||
      product.node?.stockQuantity ||
      undefined
    );
  }
};

export const CartItem = (props: Props) => {
  const { item } = props;
  const [loading, setLoading] = useState('');
  const [loadingDelete, setLoadingDelete] = useState('');
  const cartCtx = useCartContext();
  const stockQuantity = getStockQuantity(item);
  const windowWidth = useWindowWidth();

  const isMobile = windowWidth <= 1024;

  const handleQuantityUpdate = async (value: number) => {
    if (!value || value < 0) {
      return;
    }
    try {
      setLoading(item?.product?.node?.id);
      await cartCtx.updateProductQuantity(item.key, value);
    } catch (e) {
    } finally {
      setLoading('');
    }
  };

  const handleDelete = async (key: string, value: number) => {
    try {
      setLoadingDelete(item?.product?.node?.id);
      await cartCtx.updateProductQuantity(key, value);
    } catch (e) {
    } finally {
      setLoadingDelete('');
    }
  };

  return (
    <div className="CartItem">
      <div className="CartItem__wrapper">
        <div className="CartItem__wrapper__inner">
          <div className="CartItem__image">
            {item.product.node?.image && (
              <ThemeLink to={`/product/${item.product.slug}`}>
                <Image
                  dynamicRef={true}
                  src={item?.product?.node?.image?.mediaItemUrl}
                  srcSet={item?.product?.node?.image.sourceUrl}
                  alt={item.product.node?.name}
                />
              </ThemeLink>
            )}
          </div>
          <div className="CartItem__content">
            <div className="CartItem__content_txt">
              <ThemeLink to={`/product/${item.product.node?.slug}`}>
                <h3 className="CartItem__content__title">
                  {item.product.node?.name}
                </h3>
              </ThemeLink>
              {/* <span>{stockQuantity} në stok</span> */}
            </div>
            {!isMobile && (
              <div className="CartItem__attributes">
                {loading === item?.product?.node?.id ? (
                  <Spinner size="lg" color="#000" />
                ) : (
                  <div className="CartItem__attributes__quantity">
                    <Quantity
                      disabled={cartCtx.isUpdatingProduct}
                      loading={loading === item?.product?.node?.id}
                      disableDecrement={item?.quantity === 1}
                      disableIncrement={item?.quantity === stockQuantity}
                      maxQuantity={stockQuantity}
                      quantity={item.quantity}
                      onChange={(value: number) => handleQuantityUpdate(value)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isMobile && (
          <div className="CartItem__attributes">
            <span>Sasia</span>
            {loading === item?.product?.node?.id ? (
              <Spinner size="lg" color="#000" />
            ) : (
              <div className="CartItem__attributes__quantity">
                <Quantity
                  disabled={cartCtx.isUpdatingProduct}
                  loading={loading === item?.product?.node?.id}
                  disableDecrement={item?.quantity === 1}
                  disableIncrement={item?.quantity === stockQuantity}
                  maxQuantity={stockQuantity}
                  quantity={item.quantity}
                  onChange={(value: number) => handleQuantityUpdate(value)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="CartItem__delete">
        {cartCtx.isUpdatingProduct &&
        loadingDelete === item?.product?.node?.id ? (
          <Spinner size="lg" color="#000" />
        ) : (
          <div
            className="CartItem__delete__icon"
            onClick={() => handleDelete(item.key, 0)}
          >
            <Delete />
          </div>
        )}
      </div>
    </div>
  );
};

interface CartItemAttributesProps {
  name: string;
  attributes: ProductAttributeProps | undefined;
}

export const CartItemAttributes = (props: CartItemAttributesProps) => {
  const filteredAttributes = props.attributes?.nodes.filter(
    (item) => item.name === 'pa_ngjyra' || item.name === 'pa_madhesia'
  );

  return (
    <div>
      {filteredAttributes?.map((item) => {
        const isColor = item.name === 'pa_ngjyra';

        if (!item.value) {
          return;
        }

        return (
          <li key={`${props.name}-variation-${item.name}`}>
            <span
              className="CartItem__attributes__value"
              style={isColor ? { backgroundColor: `#${item.value}` } : {}}
            >
              {isColor ? '' : item.value}
            </span>
          </li>
        );
      })}
    </div>
  );
};
