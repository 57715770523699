import React, { ReactNode } from "react";
import cs from "classnames";
import { ShopProductCategory } from "../../../queries/shop";
import "./ShopMobileFilters.scss";
import { AttributeFilter } from "../../../types/generalTypes";
import {
  MobileFiltersStep,
  useMobileFiltersContext,
} from "../../../lib/context/MobileFiltersContext/MobileFiltersContext";
import { ChooseCategory } from "./ui/ChooseCategory";
import { ChooseSubCategory } from "./ui/ChooseSubCategory";
import { ChooseSubCategoryOrAttribute } from "./ui/ChooseSubCategoryOrAttribute";
import { ChooseAttribute } from "./ui/ChooseAttribute";

export interface ShopMobileFiltersItem {
  id: string;
  slug: string;
  label: string | ReactNode;
  type?: "category" | "attribute";
  children: ShopMobileFiltersItem[] | null;
  data?: ShopProductCategory | AttributeFilter;
}

export const ShopMobileFilters = () => {
  const mobileFiltersCtx = useMobileFiltersContext();

  const renderBody = () => {
    switch (mobileFiltersCtx.step) {
      case MobileFiltersStep.CHOOSE_CATEGORY: {
        return <ChooseCategory />;
      }

      case MobileFiltersStep.CHOOSE_SUBCATEGORY_OR_ATTRIBUTE:
        return <ChooseSubCategoryOrAttribute />;

      case MobileFiltersStep.CHOOSE_SUBCATEGORY: {
        return <ChooseSubCategory />;
      }

      case MobileFiltersStep.CHOOSE_ATTRIBUTE: {
        return <ChooseAttribute />;
      }

      default:
        return null;
    }
  };

  return (
    <div
      className={cs(
        "ShopMobileFilters",
        mobileFiltersCtx.isOpened && "ShopMobileFilters--opened"
      )}
    >
      {renderBody()}
    </div>
  );
};
