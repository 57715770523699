import gql from "graphql-tag";
import { PageInfo, ProductTaxonomyFilterInput } from "../types/generalTypes";
import { ProductProps } from "../types/productTypes";

export interface Brand {
  id: string;
  databaseId: number;
  slug: string;
  name: string;
  brandData: {
    logo: {
      sourceUrl?: string;
    } | null;
  };
}

export interface GetBrandsVariables {
  limit: number;
  after?: string;
  search?: string;
  firstLetter?: string;
}

export interface GetBrandsResponse {
  productBrands: {
    nodes: Brand[];
    pageInfo?: PageInfo;
  };
}

export const getBrands = gql`
  query getBrands(
    $limit: Int
    $after: String
    $search: String
    $firstLetter: String
  ) {
    productBrands(
      first: $limit
      after: $after
      where: { search: $search, firstLetter: $firstLetter, hideEmpty: true }
    ) {
      nodes {
        id
        databaseId
        name
        brandData {
          logo {
            id
            sourceUrl
          }
        }
        slug
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export interface GetBrandsLettersResponse {
  productBrands: {
    brandsLetters: {
      letters: string[] | null;
    };
  };
}

export const getBrandsLetters = gql`
  query getBrandsLetters {
    productBrands {
      brandsLetters {
        letters
      }
    }
  }
`;

export interface GetBrandProductsVariables {
  limit: number;
  slug: string;
  category?: string;
  after?: string;
  minPrice?: number;
  maxPrice?: number;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
  day?: number;
  month?: number;
  year?: number;
}

export interface GetBrandProductsResponse {
  products: {
    nodes: ProductProps[];
    pageInfo?: PageInfo;
  };
}

export const getBrandProducts = gql`
  query getBrandProducts(
    $slug: [String]
    $limit: Int
    $after: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    $minPrice: Float
    $maxPrice: Float
    $taxonomyFilter: [ProductTaxonomyFilterInput]
    $day: Int
    $month: Int
    $year: Int
  ) {
    products(
      first: $limit
      after: $after
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        taxonomyFilter: {
          and: { taxonomy: PRODUCTBRAND, terms: $slug }
          or: $taxonomyFilter
        }
        minPrice: $minPrice
        maxPrice: $maxPrice
        dateQuery: { after: { day: $day, month: $month, year: $year } }
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        productBrands {
          nodes {
            id
            name
            slug
          }
        }
        ... on VariableProduct {
          id
          name
        }
        ... on SimpleProduct {
          id
          name
          onSale
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: _630X500)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export interface GetBrandBySlugVariables {
  slug: string;
}

export interface GetBrandBySlugResponse {
  productBrands: {
    nodes: Brand[];
  };
}

export const getBrandBySlug = gql`
  query getBrandBySlug($slug: [String]) {
    productBrands(where: { slug: $slug }) {
      nodes {
        name
        brandData {
          logo {
            id
            sourceUrl
          }
        }
      }
    }
  }
`;

export const getHomeBrands = gql`
  query getHomeBrands {
    generalOptions {
      generalOptions {
        randomBrands {
          randomBrands {
            name
            slug
            brandData {
              logo {
                id
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
      }
    }
  }
`;
