import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CheckoutFormik } from '../../../lib/formik/useCheckoutFormik';
import { fields, formInputs } from '../../../lib/translation/strings';
import { Button } from '../../shared/Button/Button';

interface Props {
  formik: CheckoutFormik;
  onChange?: () => void;
}

export const ShippingFields = (props: Props) => {
  const { t } = useTranslation();
  const formik = props.formik;

  const handleSubmit = async () => {
    await formik.validateForm();
    if (
      !formik.isValid &&
      (!formik.errors.transferDate || !formik.errors.transportType)
    ) {
      return;
    }

    props.onChange && props.onChange();
  };

  return (
    <>
      <Row>
        <Col xs="12" sm="12">
          <FormGroup className="form-group">
            <Label for="billingFirstName">{t(formInputs.name)}</Label>
            <Input
              type="text"
              id="billingFirstName"
              name="billing.firstName"
              value={formik.values.billing.firstName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.billing?.firstName &&
                formik.touched.billing?.firstName
              }
            />
            <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="billingLastName">{t(formInputs.lastName)}</Label>
            <Input
              type="text"
              id="billingLastName"
              name="billing.lastName"
              value={formik.values.billing.lastName}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.billing?.lastName &&
                formik.touched.billing?.lastName
              }
            />
            <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
          </FormGroup>

          <FormGroup className="form-group">
            <Label for="billingCity">{t(fields.city)}</Label>
            <Input
              type="text"
              id="billingCity"
              name="billing.city"
              value={formik.values.billing.city}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.billing?.city && formik.touched.billing?.city
              }
            />
            <FormFeedback>{formik.errors.billing?.city}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="billingAddress">{t(formInputs.address)}</Label>
            <Input
              type="text"
              id="billingAddress"
              name="billing.address1"
              value={formik.values.billing.address1}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.billing?.address1 &&
                formik.touched.billing?.address1
              }
            />
            <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
          </FormGroup>
          <FormGroup className="form-group">
            <Label for="billingPhone">{t(formInputs.telephoneNumber)}</Label>
            <Input
              type="text"
              id="billingPhone"
              name="billing.phone"
              value={formik.values.billing.phone}
              onChange={formik.handleChange}
              className="form-control"
              invalid={
                !!formik.errors.billing?.phone && formik.touched.billing?.phone
              }
            />
            <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
          </FormGroup>
        </Col>
        <div className="FeedbackModal__btn">
          <Button
            onClick={handleSubmit}
            color="secondary"
            type="submit"
            disabled={false}
            className="w-100-mobile FeedbackModal__button_grey"
          >
            Ruaj ndryshimet
          </Button>
        </div>
      </Row>
    </>
  );
};
