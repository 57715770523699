import React from "react";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useCartContext } from "../../lib/context/CartContext/CartContext";
import { actions, fields, messages } from "../../lib/translation/strings";
import { CartList } from "./CartList";
import { EmptyPage } from "../../components/shared/EmptyMessage/EmptyPage";
import { HeadingTitle } from "../../components/shared/HeadingTitle/HeadingTitle";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";

import "./Cart.scss";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { Container } from "../../components/Container/Container";

export const Cart = () => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  const path = [{ name: "Shporta", slug: "" }];

  return (
    <div className="Cart">
      <HandleLoadingState
        loading={cartCtx.isLoading}
        loadingPlaceholder={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="mt-heading"
          >
            <Spinner size="lg" />
          </div>
        }
      >
        <Container className="p-0-mobile">
          <BreadCrumb path={path} shopType="my-cart" />
          <div className="Cart__wrapper mt-heading">
            {cartCtx.error ? null : cartCtx.isEmpty ? (
              <EmptyPage
                title={t(fields.cart)}
                buttonText={t(actions.addProducts)}
                description={t(messages.noProductOnTheList)}
              />
            ) : (
              <>
                <HeadingTitle title={t(fields.cart)} />
                <CartList products={cartCtx.items} />
              </>
            )}
          </div>
        </Container>
      </HandleLoadingState>
    </div>
  );
};
