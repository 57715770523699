import React from "react";
import { InnerHeader } from "./InnerHeader/InnerHeader";
import { Search } from "./Search/Search";
import { Container } from "../../components/Container/Container";
import { DesktopHeader } from "./DesktopHeader/DesktopHeader";

import "./Header.scss";

export const Header = () => {
  return (
    <div className="Header">
      <Container>
        <div className="Header__wrapper">
          <InnerHeader />
        </div>
        <Search className="Header-search-mobile" />
      </Container>
      {/* <Menu /> */}
      <DesktopHeader />
    </div>
  );
};
