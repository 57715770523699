import { RouteProps } from "react-router-dom";

import { Login } from "../pages/Login/Login";
import { Register } from "../pages/Register/Register";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { Profile } from "../pages/Profile/Profile";
import { ShopByCategory } from "../pages/Shop/ShopByCategory";
import { Cart } from "../pages/Cart/Cart";
import { ProductSingle } from "../pages/SingleProduct/SingleProduct";
import { Wishlist } from "../pages/Wishlist/Wishlist";
import { Home } from "../pages/Home/Home";
import { CheckoutConfirmation } from "../pages/Checkout/CheckoutConfirmation";
import { SearchPage } from "../pages/SearchPage/SearchPage";
import { ShopByBrand } from "../pages/Shop/ShopByBrand";
import { Brands } from "../pages/Brands/Brands";
import { ContentPage } from "../components/shared/ContentPage/ContentPage";
import { HowTo } from "../pages/HowTo/HowTo";
import { HowToSingle } from "../pages/HowToSingle/HowToSingle";
import { Verify } from "../pages/Verify/Verify";

export enum RouteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

interface AppRoute extends RouteProps {
  type?: RouteType;
}

export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "login",
    component: Login,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "register",
    component: Register,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "forgot-password",
    component: ForgotPassword,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "verify",
    component: Verify,
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: "reset-password",
    component: ResetPassword,
  },

  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: "my-profile",
    component: Profile,
  },
  // {
  //   type: RouteType.PRIVATE,
  //   path: "jobs",
  //   component: Jobs,
  // },

  // Normal Routes
  {
    exact: true,
    path: "/",
    component: Home,
    type: RouteType.PRIVATE,
  },
  {
    exact: true,
    path: "my-cart",
    component: Cart,
    type: RouteType.PRIVATE,
  },
  {
    exact: true,
    path: "brands",
    component: Brands,
    type: RouteType.PRIVATE,
  },
  {
    exact: true,
    path: "help/:courseCategoryId?",
    component: HowTo,
    type: RouteType.PRIVATE,
  },
  {
    exact: true,
    path: "single-help/:helpId",
    component: HowToSingle,
    type: RouteType.PRIVATE,
  },
  {
    path: "checkout/:orderId",
    component: CheckoutConfirmation,
    type: RouteType.PRIVATE,
  },
  {
    path:
      "brands/:brandSlug/:parentCategory?/:childCategory?/:childChildCategory?/:childChildChildCategory?",
    component: ShopByBrand,
    type: RouteType.PRIVATE,
  },
  {
    path:
      "shop/:parentCategory?/:childCategory?/:childChildCategory?/:childChildChildCategory?",
    component: ShopByCategory,
    type: RouteType.PRIVATE,
  },
  {
    path: "product/:slug",
    component: ProductSingle,
    type: RouteType.PRIVATE,
  },
  {
    path: "page/:id",
    component: ContentPage,
  },
  {
    path: "my-list",
    component: Wishlist,
    type: RouteType.PRIVATE,
  },
  {
    path: "search",
    component: SearchPage,
    type: RouteType.PRIVATE,
  },
];
