import React from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";

import "./ProductSimpleAttributesNew.scss";

export const ProductSimpleAttributesNew = () => {
  const { t } = useTranslation();
  const { product } = useProductSingleContext();

  const attributes = product?.attributes?.nodes.filter((item: any) => {
    if (item.name === "Madhesia") {
      return item.options[0] !== "0";
    }

    return item.name !== "Gjinia";
  });

  return (
    <div className="ProductSimpleAttributesNew">
      {attributes?.map((attr: any) => {
        const hasOptions = attr.options;

        if (!hasOptions) return null;

        return (
          <div
            key={`${attr.name}-single-variations`}
            className={cs(
              "SingleProduct__content__variations",
              "justify-content-between"
            )}
          >
            <div className="d-flex align-items-center">
              <span className="label">{t(attr.name)}</span>
              <span>{attr?.customAttributesOptions[0]?.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
