export interface EventProps {
  id: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  excerpt: string;
  slug: string;
  title: string;
}

export interface EventQueryProps {
  posts: {
    nodes: Array<EventProps>;
    pageInfo: {
      endCursor: string;
      hasNextPage: string;
    };
  };
}

export interface PageInfo {
  endCursor?: string;
  startCursor?: string;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
}

export enum ProductTaxonomyEnum {
  PABLUETOOTH = "PABLUETOOTH",
  PAMADHESIA = "PAMADHESIA",
  PAMOSHA = "PAMOSHA",
  PANGJYRA = "PANGJYRA",
  PAGJINIA = "PAGJINIA",
  PAFURNIZUESIRRYME = "PAFURNIZUESIRRYME",
  PAGARANCION = "PAGARANCION",
  PAHDDSSD = "PAHDDSSD",
  PAKARTELAGRAFIKE = "PAKARTELAGRAFIKE",
  PAKOHAEVONESAVE = "PAKOHAEVONESAVE",
  PALIDHESE = "PALIDHESE",
  PAPROCESOR = "PAPROCESOR",
  PARAMMEMORY = "PARAMMEMORY",
  PASERIA = "PASERIA",
  PASHKALLAERIFRESKIMIT = "PASHKALLAERIFRESKIMIT",
  PASISTEMIOPERATIV = "PASISTEMIOPERATIV",
  PATASTIERAMEKANIKE = "PATASTIERAMEKANIKE",
  PRODUCTBRAND = "PRODUCTBRAND",
  PRODUCTCATEGORY = "PRODUCTCATEGORY",
  PRODUCTTAG = "PRODUCTTAG",
  PRODUCTTYPE = "PRODUCTTYPE",
  SHIPPINGCLASS = "SHIPPINGCLASS",
  VENDOR = "VENDOR",
  VISIBLEPRODUCT = "VISIBLEPRODUCT",
  PAIR = "PAIR",
  PARESOLUTIO = "PARESOLUTION",
  PALEN = "PALEN",
  PAALTROPALANTE = "PAALTROPALANTE",
  PAZOOM = "PAZOOM",
  PAPOE = "PAPOE",
  PAFUQIA = "PAFUQIA",
  PAGJATESIA = "PAGJATESIA",
  PAKATEGORIA = "PAKATEGORIA",
  PASASIA = "PASASIA",
  PAVAZHDUSE = "PAVAZHDUSE",
  PADENDESIA = "PADENDESIA",
  PARG = "PARG",
  PAEKRANI = "PAEKRANI",
  PANUMRIIKONEKTORVE_LAN = "PANUMRIIKONEKTORVELAN",
  PALLOJIIKABLLOSE = "PALLOJIIKABLLOSE",
  PAKAPACITETIIDISKUT = "PAKAPACITETIIDISKUT",
  PAFUQIAEFURNIZUESIT_TE_ENERGJISE = "PAFUQIAEFURNIZUESITTEENERGJISE",
  PANUMRIIKANELEVE_CHANNEL = "PANUMRIIKANELEVECHANNEL",
  PALLOJIIKONEKTOREVE = "PALLOJIIKONEKTOREVE",
  PATIPIIKONEKTOREVE = "PATIPIIKONEKTOREVE",
  PASUPPORT = "PASUPPORT",
}

export enum TaxonomyOperatorEnum {
  AND = "AND",
  EXISTS = "EXISTS",
  IN = "IN",
  NOT_EXISTS = "NOT_EXISTS",
  NOT_IN = "NOT_IN",
}

export interface ProductTaxonomyFilterInput {
  taxonomy: ProductTaxonomyEnum;
  terms: string | string[];
  operator?: TaxonomyOperatorEnum;
}

export interface Coupon {
  id: string;
  code: string;
  amount: number;
  discountType: string;
}

export interface AttributeTerms {
  count: number;
  id: string;
  name: string;
  slug: string;
}

export interface AttributeFilter {
  id: string;
  name: string;
  slug: string;
  label: string;
  terms: AttributeTerms[];
}

export interface ChosenAttributesInput extends ProductTaxonomyFilterInput {}
