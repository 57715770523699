import React, { useContext } from "react";

export type MenuSidebarName = "cart" | "wishlist" | "search";

export interface MenuSidebarType {
  cart?: boolean;
  wishtlist?: boolean;
  filters?: boolean;
  search?: boolean;
  toggleSidebar: (name: MenuSidebarName) => void;
  openSidebar: (name: MenuSidebarName) => void;
  closeSidebar: (name: MenuSidebarName) => void;
}

const defaultMenuSidebar: MenuSidebarType = {
  cart: false,
  wishtlist: false,
  search: false,
  filters: false,
  toggleSidebar: (name: MenuSidebarName) => {},
  openSidebar: (name: MenuSidebarName) => {},
  closeSidebar: (name: MenuSidebarName) => {},
};

export const MenuSidebar = React.createContext<MenuSidebarType>(
  defaultMenuSidebar
);

export function useMenuSidebar() {
  return useContext(MenuSidebar);
}
