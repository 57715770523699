import React from "react";
import { ProfileContent } from "./ProfileContent";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { Container } from "../../components/Container/Container";

import "./Profile.scss";

export const Profile = () => {
  const path = [{ name: "Porositë", slug: "" }];

  return (
    <div className="Profile mt-heading">
      <Container className="p-0-mobile">
        <BreadCrumb path={path} shopType="my-profile" />
        {/* <ProfileSidebar /> */}
        <ProfileContent />
      </Container>
    </div>
  );
};
