import { useFormik, FormikValues, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RegisterFieldsStepThree } from "../../pages/Register/Register";

interface UseRegisterFormOptions {
  initialValues?: RegisterFieldsStepThree;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<RegisterFieldsStepThree>
  ) => Promise<any>;
}

export const useRegisterThirdStepFormik = (options: UseRegisterFormOptions) => {
  const RegisterSchema = Yup.object().shape({
    registerIndustry: Yup.string().required("Industria është e nevojshme"),
    registerInterested: Yup.string().required(
      "Interesimi juaj është i nevojshëm"
    ),
  });

  return useFormik({
    initialValues: {
      registerIndustry: "",
      registerInterested: [""],
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers);
    },
  });
};

export type RegisterThirdStepFormik = ReturnType<
  typeof useRegisterThirdStepFormik
>;
