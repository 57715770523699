import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Form, FormGroup, FormFeedback, Label } from "reactstrap";
import { actions, formInputs } from "../../lib/translation/strings";
import cs from "classnames";

import { LoginFormik } from "../../lib/formik/useLoginFormik";
import { ThemeLink } from "../../components/shared/ThemeLink/ThemeLink";
import { Button } from "../../components/shared/Button/Button";

import "./Login.scss";
import { InputType } from "reactstrap/es/Input";
import { Image } from "../../components/shared/Image/Image";

interface Props {
  isLoading: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  const { t } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");

  const { isLoading, formik } = props;

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <Form style={{ marginTop: 30 }} onSubmit={formik.handleSubmit}>
      <FormGroup>
        <Label for="email">Email adresa</Label>
        <Input
          type="email"
          name="email"
          className="white"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={t(formInputs.email)}
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <FormFeedback>{formik.errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="password">Fjalëkalimi</Label>
        <div
          className={cs(
            "d-flex customInputWrapper",
            !!formik.errors.password &&
              formik.touched.password &&
              "customInputWrapper--error"
          )}
        >
          <Input
            name="password"
            type={passwordType as InputType}
            className="white customInput"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder={t(formInputs.password)}
            invalid={!!formik.errors.password && formik.touched.password}
          />
          <div role="button" onClick={togglePassword}>
            {passwordType === "password" ? (
              <Image
                style={{ width: 20, height: 20 }}
                src={require("../../assets/icons/view.png")}
                alt="show"
              />
            ) : (
              <Image
                style={{ width: 20, height: 20 }}
                src={require("../../assets/icons/hide.png")}
                alt="hide"
              />
            )}
          </div>
        </div>

        <FormFeedback
          className={cs(
            !!formik.errors.password && formik.touched.password && "d-block"
          )}
        >
          {formik.errors.password}
        </FormFeedback>
      </FormGroup>
      <div className="d-flex flex-column align-items-center Login__wrapper__keeper">
        <span className="align-self-end">
          <ThemeLink to="/forgot-password" className="Login__wrapper-text">
            Keni harruar fjalëkalimin?
          </ThemeLink>
        </span>

        <Button
          color="primary"
          type="submit"
          className="Login__submit w-100-mobile"
        >
          {isLoading ? t(actions.loggingIn) + "..." : "Vazhdo"}
        </Button>
        {/* 
        <div className="Login__links">
          <ThemeLink to="/register" className="Login__wrapper-text">
            Krijoni llogarinë!
          </ThemeLink>
        </div> */}
      </div>
    </Form>
  );
};
