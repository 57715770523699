import React, { useContext } from "react";
import { ApolloError } from "apollo-boost";

import { SelectedAttributes } from "./ProductSingleContextProvider";

export interface ProductSingleContextType {
  loading: boolean;
  error: ApolloError | undefined;
  product: any;
  variation: any;
  attributes: any;
  quantity: number;
  inStock: boolean;
  productSpecifications: any;
  staticAttributes: any;
  setVariation: (value: any) => void;
  emptySelectedAttributes: () => void;
  setQuantity: (value: number) => void;
  setInStock: (value: boolean) => void;
  selectedAttributes: SelectedAttributes[];
  updateSelectedAttributes: (key: string, value: string) => void;
  getDisabledOptions: (attrName: string, currentValues?: string[]) => string[];
}

const ProductSingleContextValues: ProductSingleContextType = {
  quantity: 1,
  loading: false,
  inStock: false,
  attributes: [],
  error: undefined,
  productSpecifications: {},
  product: undefined,
  variation: undefined,
  staticAttributes: undefined,
  setInStock: () => {},
  setQuantity: () => {},
  selectedAttributes: [],
  setVariation: () => {},
  getDisabledOptions: () => [],
  emptySelectedAttributes: () => {},
  updateSelectedAttributes: () => {},
};

export const ProductSingleContext = React.createContext<ProductSingleContextType>(
  ProductSingleContextValues
);

export const useProductSingleContext = () => useContext(ProductSingleContext);
