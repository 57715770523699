import React from "react";
import { useQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../../lib/hooks/useRouter";
import {
  getOrderById,
  GetOrderByIdResponse,
  GetOrderByIdVariables,
} from "../../../queries/ordersQueries";
import { EmptyPage } from "../../shared/EmptyMessage/EmptyPage";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { errors, fields } from "../../../lib/translation/strings";
import { ReactComponent as Approved } from "../../../assets/icons/approved.svg";
import { OrderSingleApproved } from "./OrderSingleApproved";
import { OrderSingleOnHold } from "./OrderSingleOnHold";
import { Spinner } from "reactstrap";
import { OrderSingleCanceled } from "./OrderSingleCanceled";
import { OrderSingleCompleted } from "./OrderSingleCompleted";

import "./ProfileOrders.scss";

export const ProfileOrdersSingle = () => {
  const router: any = useRouter();
  const { t } = useTranslation();
  const orderDetails = useQuery<GetOrderByIdResponse, GetOrderByIdVariables>(
    getOrderById,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: router.match.params?.slug,
      },
    }
  );

  const orderData = orderDetails.data?.order;
  const orderList = orderData?.lineItems?.nodes ?? [];

  const renderSingleOrder = () => {
    switch (orderData?.status) {
      case "APPROVED":
        return (
          <OrderSingleApproved orderList={orderList} orderData={orderData} />
        );

      case "ON_HOLD":
        return (
          <OrderSingleOnHold orderList={orderList} orderData={orderData} />
        );

      case "CANCELLED":
        return (
          <OrderSingleCanceled orderList={orderList} orderData={orderData} />
        );

      case "COMPLETED":
        return (
          <OrderSingleCompleted orderList={orderList} orderData={orderData} />
        );

      default:
        return null;
    }
  };

  return (
    <HandleLoadingState
      loading={orderDetails.loading}
      loadingPlaceholder={
        <div className="ProfileOrdersSingle" style={{ paddingTop: "55px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 70,
            }}
          >
            <Spinner size="lg" />
          </div>
        </div>
      }
    >
      {!orderData ? (
        <EmptyPage
          title={t(fields.order)}
          buttonText={t(fields.myOrders)}
          redirectLink="my-profile/orders"
          description={t(errors.orderNotFound)}
        />
      ) : (
        <div className="ProfileOrdersSingle">
          {orderDetails?.data?.order?.status === "APPROVED" ? (
            <div className="ProfileOrdersSingle__approved">
              <Approved />
              <p>
                Kërkesa është aprovuar nga admini. Ju mund të vazhdoni me
                porosinë tuaj
              </p>
            </div>
          ) : orderDetails?.data?.order?.status === "CANCELLED" ? (
            <div className="ProfileOrdersSingle__approved">
              {/* <Approved /> */}
              <p>
                Kërkesa është anuluar, sepse nuk keni arritur ta kompletoni
                porosinë gjatë kohës së caktuar!
              </p>
            </div>
          ) : null}
          {renderSingleOrder()}
        </div>
      )}
    </HandleLoadingState>
  );
};
