import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useProductSingleContext } from "../../lib/context/ProductSingleContext/ProductSingleContext";
import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";
import { Icon } from "../shared/Icon/Icon";
import cs from "classnames";
// import { useProductSingleHelpers } from "../lib/useProductSingleHelpers";

// import { ReactComponent as MetroTruck } from "../../../assets/icons/metro-truck.svg";
// import { ReactComponent as FeatherInfo } from "../../../assets/icons/feather-info.svg";

import "./SingleProductDetails.scss";

export const SingleProductDetails = () => {
  const [open, setOpen] = useState(false);
  const [openSpecs, setOpenSpecs] = useState(false);
  // const { t, i18n } = useTranslation();
  const { product, productSpecifications } = useProductSingleContext();

  const { renderProductFieldsByLanguage } = useProductSingleHelpers();
  // const { data } = useQuery(GET_PRODUCT_RETURN_CONDITIONS);

  // const {
  //   product: { sku }
  // } = useProductSingleContext();
  // const { getProductDetails } = useProductSingleHelpers();
  // const details = getProductDetails(productOptions);
  //
  // const getDetailsContent = (item: string[]) => {
  //   return item[1];
  // };

  // const productReturnConditions = data?.generalOptions?.generalOptions ?? {};

  const productSpecifiations =
    productSpecifications?.specifications &&
    productSpecifications?.specifications?.filter(
      (item: {
        title?: string;
        specifics?: {
          title?: string;
          value?: string;
        }[];
      }) => !!item.title
    );

  return (
    <div className="SingleProductDetails">
      <div className="ProductSingleContent__description">
        <div
          onClick={() => setOpen(!open)}
          data-opened={open}
          className="SingleProductDetails__terms--title"
        >
          <h2>Përshkrimi</h2>
          <Icon icon="arrow-top" />
        </div>
        <CSSTransition
          in={open}
          timeout={200}
          classNames="animation"
          unmountOnExit
        >
          <div className="SingleProductDetails__terms_content">
            <div
              dangerouslySetInnerHTML={{
                __html: renderProductFieldsByLanguage(
                  product,
                  "descriptionEn",
                  "description"
                ).toString(),
              }}
            />
          </div>
        </CSSTransition>
      </div>
      {productSpecifiations && (
        <div
          style={{ marginTop: 10 }}
          className="ProductSingleContent__description"
        >
          <div
            onClick={() => setOpenSpecs(!openSpecs)}
            data-opened={openSpecs}
            className="SingleProductDetails__terms--title"
          >
            <h2>{productSpecifiations[0]?.title}</h2>
            <Icon icon="arrow-top" />
          </div>
          <CSSTransition
            in={openSpecs}
            timeout={200}
            classNames="animation"
            unmountOnExit
          >
            <div className="SingleProductDetails__terms_content">
              {productSpecifiations?.map(
                (
                  item: {
                    title?: string;
                    specifics?: {
                      title?: string;
                      value?: string;
                    }[];
                  },
                  index: number
                ) => {
                  return (
                    <div key={index}>
                      <div className="mt-2">
                        {item.specifics?.map(
                          (item: { title?: string; value?: string }) => {
                            return (
                              <div
                                key={`${item?.title}-single-variations`}
                                className={cs(
                                  "SingleProduct__content__variations",
                                  "justify-content-between"
                                )}
                              >
                                <div className="d-flex align-items-center">
                                  <span className="label ProductSimpleAttributes__features__attr-name">
                                    {item?.title}
                                  </span>
                                  <span>{item?.value}</span>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </CSSTransition>
        </div>
      )}
      {/* <div className="SingleProductDetails__terms">
        <div
          onClick={() => setTerms(!terms)}
          data-opened={terms}
          className="SingleProductDetails__terms--title"
        >
          <h2>{t(messages.returnProduct)}</h2>
          <Icon icon="arrow-right" />
        </div>
        <CSSTransition
          in={terms}
          timeout={200}
          classNames="animation"
          unmountOnExit
        >
          <div className="SingleProductDetails__terms_content">
            <p>
              {i18n?.language === "en"
                ? removeHTMLTags(productReturnConditions?.returnConditionsEn)
                : removeHTMLTags(productReturnConditions?.returnConditions)}
            </p>
          </div>
        </CSSTransition>
      </div> */}
      {/* <ul className="SingleProductDetails__list">
        {sku && (
          <li>
            <p>
              <span style={{ width: 100 }} className="mr-5 d-inline-block">
                {t(fields.barcode)}:
              </span>
              {sku}
            </p>
          </li>
        )}

        {details.map((item, index) => {
          const content = getDetailsContent(item);

          return (
            <li key={`${item[0]}-product-details`}>
              <p>
                <span>{t(`fields${_.startCase(item[0])}`)}:</span>
                {content}
                {index !== details.length - 1 && ","}
              </p>
            </li>
          );
        })}
      </ul> */}
    </div>
  );
};
