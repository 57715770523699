import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/app";
import "@firebase/messaging";
import { CookiesContext, CookiesContextType } from "./CookiesContext";
import { getMessaging, getToken } from "@firebase/messaging";
interface CookiesContextProviderProps {
  children: React.ReactNode | null;
}

const firebaseConfig = {
  apiKey: "AIzaSyCMNkhQepFYmTrKqkqfL-S_1wqtjOSAAVg",
  authDomain: "profiti-6ff7c.firebaseapp.com",
  projectId: "profiti-6ff7c",
  storageBucket: "profiti-6ff7c.appspot.com",
  messagingSenderId: "355381833059",
  appId: "1:355381833059:web:0eb347bc33e9ae53967756",
  measurementId: "G-RGLF17E64X",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const CookiesContextProvider = (props: CookiesContextProviderProps) => {
  const [uuid, setUuid] = useState("");
  const [notifications, setNotifications] = useState({
    fcmToken: "",
    permission: "",
  });

  useEffect(() => {
    // Load the UUID from cookies when the component mounts
    const uuidFromCookie = Cookies.get("uuid");
    if (uuidFromCookie) {
      setUuid(uuidFromCookie);
    } else {
      // If no UUID is found in cookies, generate a new one and save it in cookies
      const newUuid = uuidv4();
      Cookies.set("uuid", newUuid, { expires: 9999 });
      setUuid(newUuid);
    }
  }, []);

  useEffect(() => {
    handleNotificationPermission();
  }, []);

  const handleNotificationPermission = async () => {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      setNotifications({
        ...notifications,
        permission,
      });
      return;
    }

    try {
      const messaging = getMessaging();
      // Add the public key generated from the console here.

      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BA9dD52ArLIxB-HR8uCP0Fo97kzARbICGn0wIIn9j9n88fGoTp-WssJkT2OWGHV9XDMRKGQkGCpRlzfLN4qOXZM",
      });

      setNotifications({ permission: permission, fcmToken: fcmToken });
    } catch (error) {}
  };

  // Check if cookies are enabled
  const cookiesEnabled = navigator.cookieEnabled;

  if (!cookiesEnabled) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <p>Please enable cookies to use this app.</p>
      </div>
    );
  }

  if (notifications.permission === "denied") {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <p>Please enable notifications to use this app.</p>
      </div>
    );
  }

  const context: CookiesContextType = {
    uuid,
    cookiesEnabled,
    fcmToken: notifications.fcmToken,
  };

  return (
    <CookiesContext.Provider value={context}>
      {props.children}
    </CookiesContext.Provider>
  );
};
