import React from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../shared/Image/Image";
import { CartNode } from "../../../queries/cartQueries";
import { ThemeLink } from "../../shared/ThemeLink/ThemeLink";
import { fields } from "../../../lib/translation/strings";
import { OrderItem, SingleOrder } from "../../../queries/ordersQueries";

import "./OrderSingleOnHold.scss";

const getStockQuantity = (item: CartNode) => {
  const product = item.product;

  if (product.type === "SIMPLE") {
    return product?.stockQuantity || undefined;
  }

  if (product.type === "VARIABLE") {
    return item?.variation?.stockQuantity || product.stockQuantity || undefined;
  }
};

interface Props {
  orderList: OrderItem[];
  orderData: SingleOrder;
}

export const OrderSingleOnHold = (props: Props) => {
  const { orderList, orderData } = props;

  const { t } = useTranslation();

  return (
    <div className="OrderSingleOnHold">
      <div className="ProfileOrdersSingle__content">
        <div className="OrderSingleOnHold__title">
          <span className="ProfileOrdersSingle__order-nr">
            <strong>{t(fields.order)}: </strong>#{orderData?.orderNumber}
          </span>
          <span className="ProfileOrdersSingle__order-nr OrderSingleOnHold__quantity_title">
            Sasia
          </span>
        </div>
        <div className="ProfileOrdersSingle__left-side">
          {orderList?.map((item: any, index: number) => {
            const stockQuantity = getStockQuantity(item);

            return (
              <div key={index} className="CartItem">
                <div className="CartItem__wrapper">
                  <div className="CartItem__image">
                    {item.product.image && (
                      <ThemeLink to={`/product/${item.product.slug}`}>
                        <Image
                          dynamicRef={true}
                          src={item?.product?.image?.sourceUrl}
                          srcSet={item?.product?.image.sourceUrl}
                          alt={item.product.name}
                        />
                      </ThemeLink>
                    )}
                  </div>
                  <div className="CartItem__content">
                    <div className="CartItem__content_txt">
                      <ThemeLink to={`/product/${item.product.slug}`}>
                        <h3 className="CartItem__content__title">
                          {item.product.name}
                        </h3>
                      </ThemeLink>
                      {/* <span>{stockQuantity} në stok</span> */}
                    </div>
                    <div className="CartItem__attributes">
                      <div className="CartItem__attributes__quantity">
                        <span className="CartItem__attributes__title OrderSingleOnHold__quantity ">
                          {item?.quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
