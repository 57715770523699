import React, { useState } from "react";
import { Routes } from "./routes/Routes";
import { onMessageListener } from "./firebase";
import { useScrollToTop } from "./lib/hooks/useScrollToTop";
import { SearchSidebar } from "./layout/SearchSidebar/SearchSidebar";
import { NotificationContextProvider } from "./lib/context/NotificationContext/NotificationContextProvider";

import { WishlistSidebar } from "./layout/WishlistSidebar/WishlistSidebar";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { MenuContextProvider } from "./lib/context/MenuContext/MenuContextProvider";

import "./App.scss";

export const App = () => {
  useScrollToTop();

  return (
    <div className="App" id="App">
      <MenuContextProvider>
        <NotificationContextProvider>
          <Routes />
          <WishlistSidebar />
          <SearchSidebar />
          <ScrollToTop />
        </NotificationContextProvider>
      </MenuContextProvider>
    </div>
  );
};
