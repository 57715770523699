import React, { useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";
import { Icon } from "../shared/Icon/Icon";
import { ProfileAccountForm } from "../ProfilePages/ProfileAccount/ProfileAccountForm";
import { useMutation } from "react-apollo";
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_PASSWORD_MUTATION,
} from "../../queries/mutations/userMutations";
import { useProfileAccountFormik } from "../../lib/formik/profile/useProfileAccountFormik";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { formMessages } from "../../lib/translation/strings";
import cs from "classnames";
import { ProfileAddressForm } from "../ProfilePages/ProfileAddress/ProfileAddressForm";
import { useHistory } from "react-router-dom";
import { useAddressQuery } from "../../lib/hooks/useAddressQuery";
import { FormikValues } from "formik";
import { ProfilePasswordForm } from "../ProfilePages/ProfilePassword/ProfilePasswordForm";
import { useProfilePasswordFormik } from "../../lib/formik/profile/useProfilePasswordFormik";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right-gray.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Address } from "../../assets/icons/address.svg";
import { ReactComponent as Private } from "../../assets/icons/private.svg";

import "./ProfileHeader.scss";

export const ProfileHeader = () => {
  const authCtx = useAuthContext();
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [active, setActive] = useState("profile");
  const notificationCtx = useNotification();
  const { data, update } = useAddressQuery();
  const navigation = useHistory();

  const handleSubmit = (values: FormikValues) => {
    update.mutation(values);
  };
  const [updateProfileMutation, { loading }] = useMutation(UPDATE_PROFILE);
  const ref = useRef(null);

  React.useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (open) {
      document.body.style.overflowY = "scroll";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.body.style.position = "static";
    };
  }, [open]); // eslint-disable-line

  useOutsideClickDetection(ref, () => setOpen(false));

  const formik = useProfileAccountFormik({
    onSubmit: async (values) => {
      if (!formik.dirty || !authCtx.user) {
        return;
      }

      try {
        await updateProfileMutation({
          variables: {
            id: authCtx.user?.id,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        });

        notificationCtx.success(formMessages.profileUpdated, true);

        authCtx.updateUser({
          ...authCtx.user,
          firstName: values.firstName,
          lastName: values.lastName,
        });
      } catch (e) {
        console.error(e);
        notificationCtx.danger(formMessages.profileNotUpdated, true);
      }
    },
  });

  const [updateProfilePassword, { loading: passwordLoading }] = useMutation(
    UPDATE_PROFILE_PASSWORD_MUTATION
  );

  const passwordFormik = useProfilePasswordFormik({
    onSubmit: async (values) => {
      if (!formik.dirty) {
        return;
      }

      try {
        await updateProfilePassword({
          variables: {
            id: authCtx.user?.id,
            password: values.profilePassword,
          },
        });

        notificationCtx.success(formMessages.profileUpdated, true);

        formik.resetForm();
      } catch (error) {
        console.error(error);
        notificationCtx.danger(formMessages.profileNotUpdated, true);
      }
    },
  });

  const renderRightSide = () => {
    switch (active) {
      case "profile":
        return (
          <div className="Profile__right">
            <h1>Llogaria ime</h1>
            <ProfileAccountForm
              formik={formik}
              loading={loading}
              disable={!formik.dirty}
            />
          </div>
        );

      case "address":
        return (
          <div className="Profile__right">
            <h1>Adresa</h1>
            <ProfileAddressForm
              customer={data?.customer}
              loading={update.loading}
              onSubmit={(values) => handleSubmit(values)}
            />
          </div>
        );

      case "private":
        return (
          <div className="Profile__right">
            <h1>Privatësia</h1>
            <ProfilePasswordForm
              loading={passwordLoading}
              formik={passwordFormik}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="ProfileHeader">
      {open && <div className="Overlay" />}
      <div
        ref={ref}
        className="UNavigation__item UNavigation__item--login ProfileHeader "
      >
        <span
          onClick={() => setOpen(!open)}
          className="UNavigation__item--wishlist-icon"
        >
          <Icon icon="user-header" />
        </span>

        {open && (
          <div className="ProfileHeader__dropdown">
            <h1>Profili</h1>
            <div className="ProfileHeader__dropdown_items">
              <div
                onClick={() => {
                  setOpen(false);
                  setToggle(true);
                }}
                className="ProfileHeader__dropdown_item"
              >
                <div className="ProfileHeader__dropdown_item_desc">
                  <span>Llogaria ime</span>
                </div>
                <div className="ProfileHeader__dropdown_item_img">
                  <ArrowRight fill="#828282" />
                </div>
              </div>
              <div
                onClick={() => navigation.push("/my-profile/orders")}
                className="ProfileHeader__dropdown_item"
              >
                <div className="ProfileHeader__dropdown_item_desc">
                  <span>Porositë</span>
                </div>
                <div className="ProfileHeader__dropdown_item_img">
                  <ArrowRight fill="#828282" />
                </div>
              </div>
              <div
                onClick={() => authCtx.logout()}
                className="ProfileHeader__dropdown_item"
              >
                <div className="ProfileHeader__dropdown_item_desc">
                  <Logout style={{ marginRight: 8 }} />
                  <span className="differentcolor">Çkyçu</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        style={{ maxWidth: 700 }}
        isOpen={toggle}
        toggle={() => setToggle(!toggle)}
      >
        <ModalBody style={{ padding: 30 }}>
          <div className="Profile">
            <div className="Profile__left">
              <div className="Profile__left_items">
                <div
                  onClick={() => setActive("profile")}
                  className={cs(
                    "Profile__left_item",
                    active === "profile" && "Profile__left_item_active"
                  )}
                >
                  <Icon
                    style={{
                      width: 16,
                      height: 15,
                      marginRight: 10,
                    }}
                    icon="user-header"
                  />
                  <span>Llogaria ime</span>
                </div>
                <div
                  onClick={() => setActive("address")}
                  className={cs(
                    "Profile__left_item",
                    active === "address" && "Profile__left_item_active"
                  )}
                >
                  <Address
                    fill={active === "address" ? "#012db6" : ""}
                    style={{ marginRight: 10 }}
                  />
                  <span>Adresa</span>
                </div>
                <div
                  onClick={() => setActive("private")}
                  className={cs(
                    "Profile__left_item",
                    active === "private" && "Profile__left_item_active"
                  )}
                >
                  <Private
                    fill={active === "private" ? "#012db6" : ""}
                    style={{ marginRight: 10 }}
                  />
                  <span>Privatësia</span>
                </div>
              </div>
              <div className="Profile__left_footer">
                <div
                  onClick={() => authCtx.logout()}
                  className="ProfileHeader__dropdown_item"
                >
                  <div className="ProfileHeader__dropdown_item_desc">
                    <Logout style={{ marginRight: 8 }} />
                    <span className="differentcolor">Çkyçu</span>
                  </div>
                </div>
              </div>
            </div>
            {renderRightSide()}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
