import React from "react";
import { Row, Col, FormGroup, Form, FormFeedback, Label } from "reactstrap";
import { Select } from "../../components/GeneralFilters/SortSelect/Select";
import { RegisterThirdStepFormik } from "../../lib/formik/useRegisterThirdStepFormik";
import cs from "classnames";

import "./Register.scss";

interface Props {
  loading: boolean;
  formik: RegisterThirdStepFormik;
}

const INDUSTRY_OPTIONS = [
  {
    id: 1,
    value: "npiepts",
    label: "Ndërmarrje për instalimin e pajisjeve të sigurisë",
  },
  {
    id: 2,
    value: "npieshb",
    label: "Ndërmarrje për instalimin e smart home & building",
  },
  {
    id: 3,
    value: "npir",
    label: "Ndërmarrje për instalimin e rrymës",
  },
  {
    id: 4,
    value: "npopes",
    label: "Ndërmarrje për ofrimin e produkteve elektronike dhe servise",
  },
  {
    id: 5,
    value: "npspi",
    label: "Ndërmarrje për shërbimin në projektet institucionale",
  },
  {
    id: 6,
    value: "niss",
    label: "Ndërmarrje për instalimin e sistemeve solare",
  },
  {
    id: 7,
    value: "npiprn",
    label: "Ndërmarrje për instalimin e pajisjeve të rrjetit(network)",
  },
  {
    id: 8,
    value: "nposppp",
    label: "Ndërmarrje për ofrimin e sigurisë në prona private dhe publike",
  },
  {
    id: 9,
    value: "npisav",
    label: "Ndërmarrje për instalimin e sistemeve audio dhe vizuale",
  },
  {
    id: 10,
    value: "npsmt",
    label: "Ndërmarrje për shërbime të mirëmbajtjes teknike",
  },
  {
    id: 11,
    value: "npmeske",
    label: "Ndërmarrje për menaxhimin e energjisë dhe sistemeve të kursimit të energjisë",
  },
];

const INTERESTED_OPTIONS = [
  {
    id: 1,
    value: "sc",
    label: "Security Camera",
  },
  {
    id: 2,
    value: "ba",
    label: "Burglary Alarms",
  },
  {
    id: 3,
    value: "fa",
    label: "Fire Alarms",
  },
  {
    id: 4,
    value: "av",
    label: "Access & Videophony",
  },
  {
    id: 5,
    value: "dec",
    label: "Door Entry & Calling",
  },
  {
    id: 6,
    value: "sh",
    label: "Smart Home",
  },
  {
    id: 7,
    value: "ssh",
    label: "Smart Hotel",
  },
  {
    id: 8,
    value: "ns",
    label: "Network System",
  },
  {
    id: 9,
    value: "as",
    label: "Audio System",
  },
  {
    id: 10,
    value: "se",
    label: "Solar Energy",
  },
  {
    id: 11,
    value: "pb",
    label: "Parking & Barriers",
  },
  {
    id: 12,
    value: "ea",
    label: "Electronic & Accessory",
  },
];

export const RegisterFormStepThree = (props: Props) => {
  const { formik } = props;

  const optionsIndustry = INDUSTRY_OPTIONS.map((industry) => ({
    value: industry.value,
    label: industry.label,
  }));

  const optionsInterested = INTERESTED_OPTIONS.map((interested) => ({
    value: interested.value,
    label: interested.label,
  }));

  function handleSelectionChanged(id: string) {
    const currentValue = formik.values.registerInterested || [];
    const valueSet = new Set(currentValue);

    if (valueSet.has(id)) {
      valueSet.delete(id);
    } else {
      valueSet.add(id);
    }

    const updatedValue = Array.from(valueSet);
    formik.setFieldValue("registerInterested", updatedValue);
  }

  return (
    <div className="RegisterFormStepThree">
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs="12" sm="12">
            <FormGroup>
              <Label for="registerIndustry">Cila është industria juaj</Label>
              <Select
                value={formik.values.registerIndustry || ""}
                name="registerIndustry"
                className={
                  !!formik.errors.registerIndustry &&
                  formik.touched.registerIndustry
                    ? "is-invalid"
                    : ""
                }
                options={[
                  {
                    value: "",
                    label: "Industria",
                    isPlaceholder: true,
                  },
                  ...optionsIndustry,
                ]}
                onChange={(value) =>
                  formik.setFieldValue("registerIndustry", value)
                }
              />
              <FormFeedback>{formik.errors.registerIndustry}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs="12" sm="12">
            <FormGroup>
              <Label for="registerInterested">Cila është interesimi juaj</Label>
              <div className="RegisterFormStepThree__chips">
                {optionsInterested.map((c) => (
                  <Chip
                    name="registerInterested"
                    key={c.value}
                    onClick={() => handleSelectionChanged(c.value)}
                    variant={
                      new Set(formik.values.registerInterested).has(c.value)
                        ? "filled"
                        : "outlined"
                    }
                  >
                    {c.label}
                  </Chip>
                ))}
              </div>
              <FormFeedback>{formik.errors.registerInterested}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

function Chip({ name, onClick, variant, children }: any) {
  return (
    <div className={cs("Chip", variant)} onClick={onClick}>
      {children}
    </div>
  );
}
